import { ThemeConfig } from 'antd'
import { useThemeColors } from './colors'
import { useEffect } from 'react'
import { useUserTheme } from 'state/user/hooks'

export const useAntDesignTheme = (): ThemeConfig => {
  const theme = useUserTheme()

  const [text, bgPrimary, border, textDisabled, bgSecondary, bgPrimary2, textHover] = useThemeColors([
    'Text/Lables/Label-text-default',
    'Surface/Surface-primary',
    'Borders/Border-secondary',
    'Text/Lables/Label-text-disabled',
    'Surface/Surface-secondary',
    'Surface/Surface-primary 2',
    'Text/Lables/Label-text-hover',
  ])

  // set icon color
  const antSelectArrow = '.ant-select-arrow'
  const antSelectSelector = '.ant-select-selector'
  const selectedPage = '.ant-pagination .ant-pagination-item-active'
  useEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = `
      ${antSelectArrow} {
        color: ${textDisabled} !important;
        opacity: 1 !important;
      }
      ${antSelectSelector}:hover {
        border-color: ${border} !important;
        background-color: ${bgPrimary2} !important;
      }
      ${antSelectSelector}:focus-within {
        border-color: ${border} !important;
      }
      ${selectedPage} {
        border-color: ${border} !important;
      }
    `
    document.head.appendChild(style)
    return () => {
      document.head.removeChild(style)
    }
  }, [theme])

  return {
    components: {
      Pagination: {
        itemActiveBg: bgPrimary,
      },
    },
    token: {
      colorPrimary: text,
      colorBorder: border,
      colorText: textHover,
      colorTextDisabled: textDisabled,
      colorBgContainer: bgPrimary,
      colorBgTextHover: bgPrimary,
      colorBgTextActive: bgSecondary,
      colorTextPlaceholder: textDisabled,
      controlOutlineWidth: 0,
      colorBgElevated: bgPrimary2,
      controlItemBgActive: bgSecondary,
      controlItemBgHover: bgPrimary,
      colorPrimaryBorder: 'red',
    },
  }
}
