import { useUserTheme } from "state/user/hooks"
import { colorPaths } from "./colorPaths"
import { colorPathsDark } from "./colorPathsDark"
import { colorPathsLight } from "./colorPathsLight"

/**
 * A wrapper function that ensures the color path is correct
 * @param path The color Path directly taken from Figma (e.g., "Button/Button Primary/Button-primary-default")
 * @returns The path if it is correct, otherwise it returns "#FFFFFF"
 */
export const validateColor = (
  path: keyof colorPaths,
): string => {
  try {
    return path
  } catch (error) {
    console.log("color error on path:", path)
    return "#FFFFFF"
  }
}

// to use exclusively when not using a ChakraUI component
// e.g., for the TradingView Chart colors
/**
  * A hook that directly returns the HEX/RGB color depending on the user's theme
 * @param path The color Path directly taken from Figma (e.g., "Button/Button Primary/Button-primary-default")
 * @returns The HEX/RGB color depending on the user's theme
 */
export const useThemeColors = (paths: (keyof colorPaths)[]): string[] => {
  const theme = useUserTheme()

  switch (theme) {
    case 0:
      return paths.map((path) => colorPathsDark[path])
    case 1:
      return paths.map((path) => colorPathsLight[path])
    default:
      return paths.map((path) => colorPathsDark[path])
  }
}
