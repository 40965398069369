import { SerializedBigNumber, SupportedAssets } from "types/1delta"

/** No specific configuration */
export const LENDER_MODE_NO_MODE = 0

/**
 * Allowance data for tokenized collateral and debt assets
 */
export interface LenderAllowanceData {
  allowanceDepositDirect?: SerializedBigNumber
  allowanceWithdrawal?: SerializedBigNumber
  allowanceBorrowingVariable?: SerializedBigNumber
  allowanceBorrowingStable?: SerializedBigNumber
}

export interface LenderYields {
  // borrow interest rates
  variableBorrowRate: number
  stableBorrowRate: number
  // deposit interest rate
  depositRate: number
  // staking
  stakingYield: number

  // rewards
  collateralRewards: number
  borrowRewards: number
  stableBorrowRewards: number
}

export interface LenderTotalAmounts {
  // debt totals
  totalDebt: number
  totalDebtStable: number
  // deposit total
  totalDeposits: number
  // liquidity
  totalLiquidity: number

  /** USD values */

  // debt totals
  totalDebtUSD: number
  totalDebtStableUSD: number
  // deposit total
  totalDepositsUSD: number
  // liquidity
  totalLiquidityUSD: number
}

export interface LenderConfigMap {
  [modeId: number]: LenderConfigData
}

/**
 * Interface shared by all lenders
 */
export interface LenderPublicBase extends LenderYields, LenderTotalAmounts {

  // borrow enabled flag
  borrowingEnabled: boolean

  config: LenderConfigMap
}

/**
 * The Mode-specific configuration for a lender asset
 */
export interface LenderConfigData {
  modeId: number;
  borrowCollateralFactor: number;
  collateralFactor: number;
  borrowFactor: number
}

export interface LenderUserBalances {
  // token amounts
  deposits: number
  debt: number
  debtStable: number
  // dollar amounts
  depositsUSD: number
  debtUSD: number
  debtStableUSD: number
}

export interface LenderUserBase extends LenderUserBalances {
  collateralActive: boolean
}


export interface LenderBaseState {
  id: SupportedAssets
  user: LenderUserBase
  public: LenderPublicBase
}

/** DUMMY / EMPTY ASSET DATA */

export const LENDER_PUBLIC_BASE_DUMMY: LenderPublicBase = {
  totalDebt: 0,
  totalDebtStable: 0,
  totalDeposits: 0,
  totalLiquidity: 0,
  totalDebtUSD: 0,
  totalDebtStableUSD: 0,
  totalDepositsUSD: 0,
  totalLiquidityUSD: 0,
  config: {
    0: {
      modeId: 0,
      borrowCollateralFactor: 0,
      collateralFactor: 0,
      borrowFactor: 1,
    }
  },
  variableBorrowRate: 0,
  stableBorrowRate: 0,
  stakingYield: 0,
  depositRate: 0,
  collateralRewards: 0,
  borrowRewards: 0,
  stableBorrowRewards: 0,
  borrowingEnabled: false
}

export const LENDER_USER_BASE_DUMMY = {
  deposits: 0,
  debt: 0,
  debtStable: 0,
  depositsUSD: 0,
  debtUSD: 0,
  debtStableUSD: 0,
  collateralActive: false,
}

export const LENDER_ALLOWANCE_DATA = {
  allowanceDepositDirect: '0',
  allowanceWithdrawal: '0',
  allowanceBorrowingVariable: '0',
  allowanceBorrowingStable: '0',
}

export enum Lender {
  AAVE_V3 = 'Aave V3',
  AAVE_V2 = 'Aave V2',
  YLDR = 'YLDR',
  AURELIUS = 'Aurelius',
  LENDLE = 'Lendle',
  COMPOUND_V2 = 'Compound V2',
  OVIX = 'VIX',
  VENUS = 'Venus',
  COMPOUND_V3 = 'Compound V3',
  INIT = 'INIT Capital',
  MERIDIAN = 'Meridian'
}

export const AAVE_PROTOCOLS = [Lender.AAVE_V3, Lender.LENDLE, Lender.AURELIUS, Lender.AAVE_V2, Lender.MERIDIAN]

export const AAVE_V2_PROTOCOLS = [Lender.LENDLE, Lender.AURELIUS, Lender.AAVE_V2, Lender.MERIDIAN]

export const AAVE_V3_PROTOCOLS = [Lender.AAVE_V3, Lender.YLDR]

export const SAME_ASSET_SUPPORTED = [Lender.AAVE_V3, Lender.LENDLE, Lender.INIT, Lender.MERIDIAN]

export const COMPOUND_V2_FORKS = [Lender.OVIX, Lender.VENUS, Lender.COMPOUND_V2]

export const LENDERS_WITH_REWARDS = [Lender.COMPOUND_V3, Lender.LENDLE, Lender.MERIDIAN]
