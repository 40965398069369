import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary, FlatAddressDictionary } from './addresses'

export const oVixAddresses: AddressDictionary = {
  Oracle: {
    [SupportedChainId.POLYGON]: '0x421FF03Fe1085bce50ec5Bf06c5907119d87672F',
  },
  Comptroller: {
    [SupportedChainId.POLYGON]: '0xf29d0ae1A29C453df338C5eEE4f010CFe08bb3FF',
  },
  Unitroller: {
    [SupportedChainId.POLYGON]: '0x8849f1a0cB6b5D6076aB150546EddEe193754F1C',
  },
  InterestRate: {
    [SupportedChainId.POLYGON]: '0x15c7DAaD15E3EE00C30C16D6294ea3528641165a',
  },
  oMaticLogic: {
    [SupportedChainId.POLYGON]: '0x188D24cfEB2837c11Fd22F1462C6E0174cD910Bc',
  },
  oMatic: {
    [SupportedChainId.POLYGON]: '0xE554E874c9c60E45F1Debd479389C76230ae25A8',
  },
  oTokenLogic: {
    [SupportedChainId.POLYGON]: '0xb329FC9379dBf71BC58178383BA494D10D4E296F',
  },
  oWTBC: {
    [SupportedChainId.POLYGON]: '0x3B9128Ddd834cE06A60B0eC31CCfB11582d8ee18',
  },
  oDAI: {
    [SupportedChainId.POLYGON]: '0x2175110F2936bf630a278660E9B6E4EFa358490A',
  },
  oWETH: {
    [SupportedChainId.POLYGON]: '0xb2D9646A1394bf784E376612136B3686e74A325F',
  },
  oUSDC: {
    [SupportedChainId.POLYGON]: '0xEBb865Bf286e6eA8aBf5ac97e1b56A76530F3fBe',
  },
  oUSDT: {
    [SupportedChainId.POLYGON]: '0x1372c34acC14F1E8644C72Dad82E3a21C211729f',
  },
  maximillion: {
    [SupportedChainId.POLYGON]: '0x',
  },
  CompoundLens: {
    [SupportedChainId.POLYGON]: '0x38294502a05D10cf9266964443212e8D535bd3E1',
  },
  oMAI: {
    [SupportedChainId.POLYGON]: '0xC57E5e261d49Af3026446de3eC381172f17bB799',
  },
  oMATICX: {
    [SupportedChainId.POLYGON]: '0xAAcc5108419Ae55Bc3588E759E28016d06ce5F40',
  },
}

export const addresses0VixOTokens: AddressDictionary = {
  [SupportedAssets.DAI]: {
    [SupportedChainId.POLYGON]: '0x2175110F2936bf630a278660E9B6E4EFa358490A',
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.POLYGON]: '0xEBb865Bf286e6eA8aBf5ac97e1b56A76530F3fBe',
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.POLYGON]: '0x1372c34acC14F1E8644C72Dad82E3a21C211729f',
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.POLYGON]: '0x3B9128Ddd834cE06A60B0eC31CCfB11582d8ee18',
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON]: '0xb2D9646A1394bf784E376612136B3686e74A325F',
  },
  [SupportedAssets.MATIC]: {
    [SupportedChainId.POLYGON]: '0xE554E874c9c60E45F1Debd479389C76230ae25A8',
  },
  [SupportedAssets.MATICX]: {
    [SupportedChainId.POLYGON]: '0xAAcc5108419Ae55Bc3588E759E28016d06ce5F40',
  },
  [SupportedAssets.MAI]: {
    [SupportedChainId.POLYGON]: '0xC57E5e261d49Af3026446de3eC381172f17bB799',
  },
  [SupportedAssets.JEUR]: {
    [SupportedChainId.POLYGON]: '0x29b0F07d5A61595685a17D5F9F86313742Ebd6Bc'
  },
  [SupportedAssets.STMATIC]: {
    [SupportedChainId.POLYGON]: '0xDc3C5E5c01817872599e5915999c0dE70722D07f'
  },
  // [SupportedAssets.VGHST]: {
  //   [SupportedChainId.POLYGON]: '0xE053A4014b50666ED388ab8CbB18D5834de0aB12'
  // },
  // [SupportedAssets.GDAI]: {
  //   [SupportedChainId.POLYGON]: '0x6F063Fe661d922e4fd77227f8579Cb84f9f41F0B'
  // },
  [SupportedAssets.WSTETH]: {
    [SupportedChainId.POLYGON]: '0xf06edA703C62b9889C75DccDe927b93bde1Ae654'
  }
}

export const getOVixOTokens = (chainId: number, assets: SupportedAssets[]): FlatAddressDictionary => {
  return Object.assign(
    {},
    ...assets.map((a) => {
      return { [a]: addresses0VixOTokens[a][chainId] }
    })
  )
}

export const addresses0VixTestnetTokens: AddressDictionary = {
  [SupportedAssets.DAI]: {
  },
  [SupportedAssets.USDCE]: {
  },
  [SupportedAssets.USDT]: {
  },
  [SupportedAssets.WBTC]: {
  },
  [SupportedAssets.WETH]: {
  },
}
