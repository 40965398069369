import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Call, fallbackOnResult, multicallViem } from 'utils/multicall'
import ERC20 from 'abis/erc20.json'
import { MULTICALL_ADDRESS } from 'constants/addresses'
import { getMulticallV3Address } from 'hooks/1delta/addresses'
import { getAllLenderTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { Result } from 'ethers/lib/utils'
import { getFallbackViemProvider } from 'hooks/providers/evm/useViemClient'
import { SupportedChainId } from 'constants/chains'

export interface TimestampResponse {
  timestamp: string,
  chainId: number
}

export interface TimstampQueryParams {
  chainId: number
}

const MultiABI = [
  {
    "inputs": [],
    "name": "getCurrentBlockTimestamp",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "addr",
        "type": "address"
      }
    ],
    "name": "getEthBalance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "balance",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getBlockNumber",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "blockNumber",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
]


export type AllowanceEntry = { [target: string]: { allowance: string, owner: string } }

export interface SubscribedData {
  allowanceData?: AllowanceEntry
  balance: string
}

export interface GasData {
  maxFeePerGas?: string | number,
  maxPriorityFeePerGas?: string | number,
}

export interface TimestampAndBalanceResponse {
  timestamp: string
  nativeBalance: string | undefined
  balances: { [asset: string]: string }
  subscribedData: { [asset: string]: SubscribedData }
  blockNumber: number,
  gasData: GasData,
  chainId: number
}

export interface TimestampAndBalanceQueryParams {
  chainId: number
  account?: string;
  assetsToQuery?: string[]
  approvalTargets?: string[]
}

export const fetchBlockDataAndBalances: AsyncThunk<TimestampAndBalanceResponse, TimestampAndBalanceQueryParams, any> =
  createAsyncThunk<TimestampAndBalanceResponse, TimestampAndBalanceQueryParams>(
    'globalNetwork/fetchBlockDataAndBalances',

    async ({ chainId, account, assetsToQuery, approvalTargets }) => {
      if (account) {
        const callsBase: Call[] = [{
          address: MULTICALL_ADDRESS[chainId],
          name: 'getCurrentBlockTimestamp',
          params: [],
        }, {
          address: MULTICALL_ADDRESS[chainId],
          name: 'getEthBalance',
          params: [account],
        },
        {
          address: getMulticallV3Address(chainId),
          name: 'getBlockNumber',
          params: [],
        }
        ]

        const tokenAddresses = getAllLenderTokenAddresses(chainId)

        const names = Object.keys(tokenAddresses)
        const callsSupportedAssetBalances: Call[] = names.map((tk) => {
          return {
            address: tokenAddresses[tk],
            name: 'balanceOf',
            params: [account],
          }

        })

        let callsAdditionalBalances: Call[] = []
        let callsAdditionalAllowances: Call[] = []
        if (assetsToQuery) {
          assetsToQuery = assetsToQuery.map(a => a.toLowerCase())
          callsAdditionalBalances = assetsToQuery.map((address) => {
            return {
              address,
              name: 'balanceOf',
              params: [account],
            }

          })
          if (approvalTargets && approvalTargets.length > 0) {
            callsAdditionalAllowances = approvalTargets.map(
              target => assetsToQuery!.map((address) => {
                return {
                  address,
                  name: 'allowance',
                  params: [account, target],
                }

              })
            ).reduce((a, b) => [...a, ...b])
          }
        }
        const calls = [ // always get these
          ...callsSupportedAssetBalances, ...callsBase,
          // these depend on the data subscription
          ...callsAdditionalBalances, ...callsAdditionalAllowances
        ]
        let multicallResult: any[];
        try {
          multicallResult = await multicallViem(
            chainId,
            [...MultiABI, ...ERC20],
            calls
          )
        } catch (e) {
          console.log("failed multicall for subscribed data", e)
          multicallResult = []
        }
        let gasData: any | undefined
        try {
          gasData = await (getFallbackViemProvider({ chainId, id: 0 }) as any).estimateFeesPerGas()
        } catch (e: any) {
          gasData = undefined
        }

        const balanceLength = callsSupportedAssetBalances.length
        const baseLength = callsBase.length
        const balances = multicallResult.slice(0, balanceLength)
        const base = multicallResult.slice(balanceLength, balanceLength + baseLength)

        const result: any = Object.assign(
          {},
          ...balances.map((entry: any, index) => {
            return {
              [names[index]]: fallbackOnResult(entry.toString(), '0'),
            }
          })
        )

        let subscribedData: { [address: string]: SubscribedData } = {}
        let allowanceMap: { [a: string]: AllowanceEntry } = {}
        if (assetsToQuery) {
          const additionalBalancesLength = callsAdditionalBalances.length
          const additionalBalances = multicallResult.slice(balanceLength + baseLength, balanceLength + baseLength + additionalBalancesLength)
          let additionalAllowances: Result[]
          if (approvalTargets) {
            additionalAllowances = multicallResult.slice(balanceLength + baseLength + additionalBalancesLength, multicallResult.length)
            const keys = approvalTargets.map(t => t.toLowerCase())
            const assetCount = assetsToQuery.length
            allowanceMap = Object.assign({}, ...assetsToQuery.map((a, assetIndex) => {
              return {
                [a]: Object.assign({}, ...keys.map((k, keyIndex) => {
                  return {
                    [k]: {
                      allowance: fallbackOnResult(additionalAllowances[assetIndex + keyIndex * assetCount]?.toString(), '0'),
                      owner: account
                    }
                  }
                })
                )
              }
            }
            ))
          }
          subscribedData = Object.assign(
            {},
            ...additionalBalances.map((entry: any, index) => {
              const asset = assetsToQuery![index]
              return {
                [asset]: {
                  balance: fallbackOnResult(entry.toString(), '0'),
                  allowanceData: approvalTargets ? allowanceMap[asset] : {}
                },
              }
            })
          )
        }
        const pardsedGasFata = gasData ? {
          maxFeePerGas: upscaleGas(gasData?.maxFeePerGas, chainId),
          maxPriorityFeePerGas: upscaleGas(gasData?.maxPriorityFeePerGas, chainId)
        } : {}

        return {
          timestamp: fallbackOnResult(base[0]?.toString()),
          nativeBalance: fallbackOnResult(base[1]?.toString()),
          blockNumber: Number(fallbackOnResult(base[2]?.toString())),
          balances: result,
          gasData: pardsedGasFata,
          subscribedData,
          chainId
        }
      }

      const calls: Call[] = [{
        address: MULTICALL_ADDRESS[chainId],
        name: 'getCurrentBlockTimestamp',
        params: [],
      },
      {
        address: getMulticallV3Address(chainId),
        name: 'getBlockNumber',
        params: [],
      },
      ]

      const multicallResult = await multicallViem(chainId, MultiABI, calls, 1)
      return {
        timestamp: fallbackOnResult(multicallResult[0].toString()),
        blockNumber: Number(fallbackOnResult(multicallResult[1].toString())),
        nativeBalance: undefined,
        balances: {},
        gasData: {},
        subscribedData: {},
        chainId
      }
    }
  )


function upscaleGas(am: bigint | string | undefined, chainId: number) {
  if (!am) return undefined
  if (chainId === SupportedChainId.TAIKO) // +10%
    return (BigInt(am) * 11n / 10n).toString()
  if (chainId === SupportedChainId.MANTLE)
    return BigInt(am).toString() // +5%
  else return (BigInt(am) * 105n / 100n).toString()
}