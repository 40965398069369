import { Box, HStack, Image, Link, Spinner, Text } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { IconButtonWithTooltip } from 'components/Button/Action/IconButtonWithTooltip'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { CustomTooltip } from 'components/Tooltip'
import { TooltipContent } from 'components/Tooltip/TooltipContent'
import { useBlockNumber } from 'state/globalNetwork/hooks'
import { useEffect, useState } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import { default as greenCircle } from 'assets/images/green-circle.svg'
import { default as telegramIcon } from 'assets/icons/telegram.svg'
import { default as discordIcon } from 'assets/icons/discord.svg'
import { default as githubIcon } from 'assets/icons/github.svg'
import { default as twitterIcon } from 'assets/icons/twitter.svg'

export const Footer: React.FC = () => {
  const { chainId } = useActiveWeb3React()
  const blockNumber = useBlockNumber()
  const [isMounting, setIsMounting] = useState(false)
  //TODO - chainlink gas oracle is really slow. Can we get a better data source?
  useEffect(
    () => {
      if (!blockNumber) {
        return
      }
      setIsMounting(true)
      const mountingTimer = setTimeout(() => setIsMounting(false), 1000)

      // this will clear Timeout when component unmount like in willComponentUnmount
      return () => {
        clearTimeout(mountingTimer)
      }
    },
    [blockNumber] //useEffect will run only one time
    //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
  )

  // socials
  const tgLink = 'https://t.me/OneDeltaDAO'
  const discordLink = 'https://discord.com/invite/8MJPy7hact'
  const githubLink = 'https://github.com/1delta-DAO'
  const twitterLink = 'https://x.com/1deltaDAO'

  return (
    <Box px="1.5rem" w="100%" pt="3rem" background={validateColor('Surface/Page-background')}>
      <HStack
        w="100%"
        py="1rem"
        color={validateColor('Text/Lables/Label-text-disabled')}
        style={getTypography('Typography/Small/Normal/Small 1')}
        borderTop={'1px solid'}
        borderColor={validateColor('Borders/Border-secondary')}
      >
        <HStack gap="0.25rem" w="50%">
          <Image src={greenCircle} alt="1delta" w="0.569rem" h="0.569rem" />
          <Text lineHeight={'0.9 !important'}>Latest Block:</Text>
          {blockNumber === 0 && (
            <Spinner size="xs" color={validateColor('Text/Lables/Label-text-default')} thickness="1px" ml="2px" />
          )}
          {blockNumber !== 0 && (
            <Link
              href={
                chainId && blockNumber ? getExplorerLink(chainId, blockNumber.toString(), ExplorerDataType.BLOCK) : ''
              }
              target="_blank"
              transition="all 0.25s ease"
              opacity={isMounting ? 0.5 : 1}
              style={getTypography('Typography/Small/Normal/Small 2')}
              _hover={{ opacity: 1 }}
              css={`
                a:hover {
                  text-decoration: none;
                }
              `}
            >
              <CustomTooltip
                placement="top-end"
                innerBoxProps={{
                  border: 'none',
                  cursor: 'pointer !important',
                }}
                tooltipContent={
                  <TooltipContent
                    body={<>The most recent block number on this network. Prices update on every block.</>}
                  />
                }
              >
                {blockNumber}
              </CustomTooltip>
            </Link>
          )}
        </HStack>
        {/* <HStack w="100%" gap="1.5rem" justifyContent={'center'}>
          <Text>Terms</Text>
          <Text>Privacy</Text>
          <Text>Docs</Text>
          <Text>FAQs</Text>
        </HStack> */}
        <HStack gap="1rem" w="50%" justifyContent={'flex-end'}>
          <IconButtonWithTooltip
            src={telegramIcon}
            imageProps={{ w: '2rem', h: '2rem' }}
            onClick={() => window.open(tgLink, '_blank')}
          />
          <IconButtonWithTooltip
            src={discordIcon}
            imageProps={{ w: '1.5rem', h: '1.5rem' }}
            onClick={() => window.open(discordLink, '_blank')}
          />
          <IconButtonWithTooltip
            src={githubIcon}
            imageProps={{ w: '1.75rem', h: '1.75rem' }}
            onClick={() => window.open(githubLink, '_blank')}
          />
          <IconButtonWithTooltip
            src={twitterIcon}
            imageProps={{ w: '1.25rem', h: '1.25rem' }}
            onClick={() => window.open(twitterLink, '_blank')}
          />
        </HStack>
      </HStack>
    </Box>
  )
}
