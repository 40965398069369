import { Lender } from "types/lenderData/base";
import { LoadingState } from "./reducer";
import { createAction } from '@reduxjs/toolkit'

export const replaceLoadingState = createAction<{
  loadingPositions: LoadingState['loadingPositions']
}>('loading/replaceLoadingState')

export const addLoadingPositionInState = createAction<{
  loadingPosition: LoadingState['loadingPositions'][0]
}>('loading/addLoadingPosition')

export const removeAllLoadingPositions = createAction('loading/removeAllLoadingPositions')
