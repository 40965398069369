import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AAVE_SUBGRAPH_URL, AAVE_V3_REDEEMS_ROUTER, AAVE_V3_TRADES, AaveTxEntry, AaveV3Trades, parseAaveV3TradeEntry } from './queries'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { Lender } from 'types/lenderData/base'
import { getLenderAssets } from 'constants/getAssets'
import { AggregatedDataDict, PnLData, aggreagateTxns, calculateFullAaveTypePnLFromTxns, mergeDicts, produceOriginalBalance } from '../utils'
import { AaveTypeTx, TxMap } from '../utils/types'
import _ from "lodash"

interface ApiResponseData {
  pnl: PnLData;
  refBalance: number;
  success: boolean;
  chainId: number;
}

interface TxQueryData {
  chainId: number;
  account?: string | undefined
  histPrices: { [a: string]: number; }
  balancesLong: AggregatedDataDict
  balancesShort: AggregatedDataDict
  refTime: number
}

const EMPTY_DATA = { pnl: { pnlsLong: {}, pnlsShort: {} }, success: false, chainId: 0, refBalance: 0 }

export const fetchAaveV3UserHistory: AsyncThunk<ApiResponseData, TxQueryData, any> = createAsyncThunk<
  ApiResponseData,
  TxQueryData
>('oracles/fetchAaveV3UserHistory', async ({ chainId, histPrices, account, balancesLong, balancesShort, refTime }) => {
  if (!chainId || !account) return EMPTY_DATA
  const subgraphUrl = AAVE_SUBGRAPH_URL[chainId]
  if (subgraphUrl) return EMPTY_DATA
  const {
    successFetchHistory,
    hashesToLookup,
    txDictShorts,
    txDictLongs,
    aggregatedShortDict,
  } = await fetchTransactionHistory({
    chainId,
    account,
    first: 250,
    skip: 0,
    subgraphUrl,
    refTime
  })

  if (!successFetchHistory) return EMPTY_DATA

  const {
    successFetchWithdrawals,
    aggregatedLongDict,
    allLongTxs,
  } = await fetchWithdrawals({
    chainId,
    longTxs: txDictLongs,
    first: 500,
    skip: 0,
    subgraphUrl,
    hashes: hashesToLookup
  })


  if (!successFetchWithdrawals) return EMPTY_DATA

  const startingLong = produceOriginalBalance(balancesLong, aggregatedLongDict, histPrices)
  const startingShort = produceOriginalBalance(balancesShort, aggregatedShortDict, histPrices)

  const { pnl: pnlsLong } = calculateFullAaveTypePnLFromTxns(startingLong, balancesLong, allLongTxs, true)
  const { pnl: pnlsShort } = calculateFullAaveTypePnLFromTxns(startingShort, balancesShort, txDictShorts, false)

  return {
    pnl: { pnlsLong, pnlsShort },
    chainId,
    success: true,
    refBalance: 0
  }
})

interface TransactionHistoryParams {
  chainId: number
  refTime: number
  account: string;
  subgraphUrl: string;
  first: number;
  skip: number;
}

/**
 * Calculate the net flow of a series of transactions
 * @param data array of transactions
 * @returns the flow in currency and usd
 */
const aggregateTx = (data: AaveTypeTx[]) => {
  return {
    amount: _.sum(data.map(a => a.amount)),
    amountUSD: _.sum(data.map(a => a.amountUSD)),
  }
}

const fetchTransactionHistory = async ({
  chainId,
  refTime,
  account,
  subgraphUrl,
  first,
  skip,
}: TransactionHistoryParams) => {
  const query = AAVE_V3_TRADES(account.toLowerCase(), refTime);

  const requestBody = {
    query,
    variables: { first, skip },
  };
  try {
    const response = await fetch(subgraphUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw new Error(`Network error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    const reserve: AaveV3Trades = data.data

    let hashesToLookup: string[] = []
    let txDictShorts: TxMap = {}
    let txDictLongs: TxMap = {}
    for (let borrowTx of reserve.borrows) {
      const parsedBorrow = parseAaveV3TradeEntry(borrowTx, chainId, true)
      if (!txDictShorts[parsedBorrow.asset]) txDictShorts[parsedBorrow.asset] = []
      txDictShorts[parsedBorrow.asset].push(parsedBorrow)
    }
    for (let repayTx of reserve.repays) {
      const parsedRepays = parseAaveV3TradeEntry(repayTx, chainId, false)
      if (!hashesToLookup.includes(parsedRepays.hash)) hashesToLookup.push(parsedRepays.hash)
      if (!txDictShorts[parsedRepays.asset]) txDictShorts[parsedRepays.asset] = []
      txDictShorts[parsedRepays.asset].push(parsedRepays)
    }
    for (let supplyTx of reserve.supplies) {
      const parsedSupplies = parseAaveV3TradeEntry(supplyTx, chainId, true)
      if (!hashesToLookup.includes(parsedSupplies.hash)) hashesToLookup.push(parsedSupplies.hash)
      if (!txDictLongs[parsedSupplies.asset]) txDictLongs[parsedSupplies.asset] = []
      txDictLongs[parsedSupplies.asset].push(parsedSupplies)
    }
    for (let redeemTx of reserve.redeemUnderlyings) {
      const parsedRedeems = parseAaveV3TradeEntry(redeemTx, chainId, false)
      if (!hashesToLookup.includes(parsedRedeems.hash)) hashesToLookup.push(parsedRedeems.hash)
      if (!txDictLongs[parsedRedeems.asset]) txDictLongs[parsedRedeems.asset] = []
      txDictLongs[parsedRedeems.asset].push(parsedRedeems)
    }

    return {
      hashesToLookup,
      txDictShorts,
      txDictLongs,
      aggregatedShortDict: aggreagateTxns(txDictShorts),
      successFetchHistory: true
    }
  } catch (error) {
    console.error('Error fetching transaction history:', error);
    return {
      hashesToLookup: [],
      txDictShorts: {},
      txDictLongs: {},
      aggregatedShortDict: {},
      successFetchHistory: false
    }
  }
};

interface WithdrawalFetchParams {
  chainId: number
  hashes: string[];
  subgraphUrl: string;
  first: number;
  skip: number;
  longTxs: TxMap;
}

const fetchWithdrawals = async ({
  chainId,
  hashes,
  subgraphUrl,
  longTxs,
  first,
  skip,
}: WithdrawalFetchParams) => {
  const query = AAVE_V3_REDEEMS_ROUTER(ONE_DELTA_COMPOSER[chainId], hashes);
  const requestBody = {
    query,
    variables: { first, skip },
  };
  try {
    const response = await fetch(subgraphUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw new Error(`Network error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    const redeems: AaveTxEntry[] = data.data.redeemUnderlyings

    let txDict: TxMap = {}

    for (let redeemTx of redeems) {
      const parsedRedeems = parseAaveV3TradeEntry(redeemTx, chainId, false)
      if (!txDict[parsedRedeems.asset]) txDict[parsedRedeems.asset] = []
      txDict[parsedRedeems.asset].push(parsedRedeems)
    }
    const aggregatedLongDict: AggregatedDataDict = {}
    const allKeys = getLenderAssets(chainId, Lender.AAVE_V3)
    for (let key of allKeys) {
      if (txDict[key]?.length > 0 || longTxs[key]?.length > 0) {
        const aggregated = aggregateTx(_.concat(txDict[key] ?? [], longTxs[key] ?? []))
        aggregatedLongDict[key] = aggregated
      }
    }
    return {
      aggregatedLongDict,
      allLongTxs: mergeDicts(longTxs, txDict),
      successFetchWithdrawals: true
    };
  } catch (error) {
    console.error('Error fetching transaction history:', error);
    return {
      aggregatedLongDict: {},
      allLongTxs: {},
      successFetchWithdrawals: true
    };
  }
};


