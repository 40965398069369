import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { chainlinkOracles, getChainLinkKeys } from 'hooks/1delta/addresses'
import { Call, multicallViem } from 'utils/multicall'
import CHAINLINK_AGGREGATOR_ABI from 'abis/chainlink/ChainLinkAggregator.json'
import { SerializedBigNumber } from 'types/1delta'
import { ChainlinkAggregatorIndexes } from 'state/lenders/compound-v3/fetchPublicData'


export interface RawOracleResponse {
  price: SerializedBigNumber,
  time: number
}

export interface ChainLinkRawResponse extends RawOracleResponse {
  roundId: number
}

interface ChainLinkAggregatedResponse {
  data: {
    [key: string]: ChainLinkRawResponse
  }
  chainId: number
}

interface ChainLinkQueryParams {
  chainId: number
}

export const fetchChainLinkData: AsyncThunk<ChainLinkAggregatedResponse, ChainLinkQueryParams, any> = createAsyncThunk<
  ChainLinkAggregatedResponse,
  ChainLinkQueryParams
>('oracles/fetchChainLinkData', async ({ chainId }) => {
  const keys = getChainLinkKeys(chainId)
  const addresses = keys.map((k) => chainlinkOracles[k][chainId])
  const calls: Call[] = addresses.map((tk) => {
    return {
      address: tk,
      name: 'latestRoundData',
      params: [],
    }
  })
  const multicallResult = await multicallViem(chainId, CHAINLINK_AGGREGATOR_ABI, calls, 1)
  const result = Object.assign(
    {},
    ...multicallResult.map((entry, index) => {
      return {
        [keys[index]]: {
          roundId: Number(entry?.[ChainlinkAggregatorIndexes.roundId]?.toString()) ?? 0,
          price: entry?.[ChainlinkAggregatorIndexes.answer]?.toString() ?? '0',
          time: Number(entry?.[ChainlinkAggregatorIndexes.updatedAt]?.toString() ?? '0'),
        },
      }
    })
  )

  return { data: result, chainId }
})
