import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'

export const addressesAureliusCore: AddressDictionary = {
  AaveOracle: {
    [SupportedChainId.MANTLE]: '0x'
  },
  PoolProxy: {
    [SupportedChainId.MANTLE]: '0x'
  },
  PoolDataProvider: {
    [SupportedChainId.MANTLE]: '0x'
  },
  IncentivesController: {
    [SupportedChainId.MANTLE]: '0x'
  },
  MultiFeeDistribution: {
    [SupportedChainId.MANTLE]: '0x'
  },
}

export const addressesAureliusLTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.USDE]: {
    [SupportedChainId.MANTLE]: '0x'
  },
}

export const addressesAureliusVTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.USDE]: {
    [SupportedChainId.MANTLE]: '0x'
  },
}

export const addressesAureliusSTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: '0x'
  },
  [SupportedAssets.USDE]: {
    [SupportedChainId.MANTLE]: '0x'
  },
}

