// eslint-disable-next-line no-restricted-imports

import { ChangeEvent, RefObject, useCallback, useRef, useState } from 'react'
import { isAddress } from 'utils'
import { HStack, Input, VStack } from '@chakra-ui/react'
import { useIsImpersonated, useSetImpersonatedAccount } from 'state/globalNetwork/hooks'
import { SecondarySmallButton } from 'components/Button/Secondary'
import { validateColor } from 'theme/colors'

export const Impersonator = () => {
  const { setAccount, disableAccount } = useSetImpersonatedAccount()
  const { impersontedAccount, useImpersonatedAccount } = useIsImpersonated()

  const [searchQuery, setSearchQuery] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>()

  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
  }, [])

  const validInput = isAddress(searchQuery)
  const impersonationActive = Boolean(useImpersonatedAccount && impersontedAccount)

  const showEnable = validInput && Boolean(impersontedAccount?.toLowerCase() !== validInput?.toLowerCase())

  return (
    <VStack>
      <Input
        type="text"
        id="impersonate-address-input"
        placeholder={
          impersonationActive
            ? `Impersonating: ${impersontedAccount?.slice(0, 5)}...${impersontedAccount?.slice(-5)}`
            : 'Paste address to impersonate'
        }
        autoComplete="off"
        value={searchQuery}
        color={validateColor('Text/Lables/Label-text-default')}
        _placeholder={{
          color: validateColor('Text/Subheadings & Sub Titles/Sub-headings'),
        }}
        ref={inputRef as RefObject<HTMLInputElement>}
        onChange={handleInput}
      />
      <HStack>
        <SecondarySmallButton
          onClick={() => setAccount(searchQuery)}
          disabled={!showEnable && Boolean(!validInput || impersonationActive)}
        >
          {showEnable ? 'Activate' : impersonationActive ? 'Active' : validInput ? 'Set account' : 'Invalid'}
        </SecondarySmallButton>
        <SecondarySmallButton onClick={disableAccount} disabled={!useImpersonatedAccount}>
          {useImpersonatedAccount ? 'Disable' : 'Inactive'}
        </SecondarySmallButton>
      </HStack>
    </VStack>
  )
}
