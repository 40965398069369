import { CurrencyAmount, NativeCurrency, Token } from '@1delta/base-sdk'
import { useNativeBalance } from 'state/globalNetwork/hooks'
import useNativeCurrency from './useNativeCurrency'

export function useCurrencyBalanceString(): string {
  return useGetNativeBalance()?.toSignificant(3) ?? ''
}

export function useGetNativeBalance(): CurrencyAmount<NativeCurrency | Token> | undefined {
  const rawNativeBalance = useNativeBalance()
  const eth = useNativeCurrency()
  return rawNativeBalance === undefined ? undefined : CurrencyAmount.fromRawAmount(eth, rawNativeBalance)
}