import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import type { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers'
import { Token } from '@1delta/base-sdk'
import { ChainTokenMap } from 'lib/hooks/useTokenList/utils'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import { DeltaTokenList, TypedTokenList } from 'state/lists/reducer'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4, isMobile = false): string {
  const parsed = isAddress(address)
  if (!parsed) {
    // throw Error(`Invalid 'address' parameter '${address}'.`)
    console.log(`Invalid 'address' parameter '${address}'.`)
    return ""
  }
  if (isMobile) {
    return `${parsed.substring(0, 5)}...`
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// account is not optional
export function getSigner(provider: JsonRpcProvider, account: string): JsonRpcSigner {
  return provider.getSigner(account).connectUnchecked()
}

// account is optional
function getProviderOrSigner(provider: JsonRpcProvider, account?: string): JsonRpcProvider | JsonRpcSigner {
  return account ? getSigner(provider, account) : provider
}

// account is optional
export function getContract(address: string, ABI: any, provider: JsonRpcProvider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(chainTokenMap: TypedTokenList, token?: Token | WrappedTokenInfo): boolean {
  return Boolean(token?.isToken && chainTokenMap?.[token.address])
}
