import { HStack, Link, Spinner, Text, VStack } from '@chakra-ui/react'
import { Connector } from '@web3-react/types'
import { PrimaryMediumButton } from 'components/Button/Primary'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'

export default function PendingView({
  connector,
  error = false,
  tryActivation,
  openOptions,
}: {
  connector: Connector
  error?: boolean
  tryActivation: (connector: Connector) => void
  openOptions: () => void
}) {
  return (
    <VStack alignItems="center" justifyContent="center">
      {error ? (
        <VStack
          alignItems="center"
          justifyContent="flex-start"
          gap="1.5rem"
          color={validateColor('Text/Lables/Label-text-default')}
        >
          <Text color={validateColor('Text/Lables/Lable-error')}>Error connecting</Text>
          <Text fontSize={14} textAlign="center">
            The connection attempt failed. Please click try again and follow the steps to connect in your wallet.
          </Text>
          <PrimaryMediumButton
            padding="12px"
            onClick={() => {
              tryActivation(connector)
            }}
          >
            Try Again
          </PrimaryMediumButton>
          <HStack width="fit-content" padding="0">
            <Link fontSize={12} onClick={openOptions}>
              Back to wallet selection
            </Link>
          </HStack>
        </VStack>
      ) : (
        <>
          <VStack gap="1.5rem" mt="4rem">
            <Spinner color={validateColor('Text/Lables/Label-text-active')} size="xl" thickness="4px" />
            <Text
              color={validateColor('Text/Lables/Label-text-active')}
              style={getTypography('Typography/Body-Labels/Medium/Body-Label-Medium')}
            >
              Connecting...
            </Text>
          </VStack>
        </>
      )}
    </VStack>
  )
}
