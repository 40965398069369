import { useWeb3React } from "@web3-react/core"
import { useAppSelector } from "state/hooks"

/**
 * Get web3 details, return impersonated account for account
 */
export const useWeb3ReactWrapped = () => {
  const { impersontedAccount, useImpersonatedAccount } = useAppSelector((state) => state.globalNetwork)
  const { account, ...rest } = useWeb3React()
  if (useImpersonatedAccount) return { account: impersontedAccount, ...rest }
  return { account, ...rest }
}