import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { getLendlePoolDataProviderContract } from 'hooks/1delta/use1DeltaContract'
import { SerializedBigNumber } from 'types/1delta'
import { Call, multicallViem } from 'utils/multicall'
import AAVE_POOL_AND_DATA_PROVIDER_ABI from 'abis/aave/AAVEPoolAndDataProvider.json'
import INCENTIVES_CONTROLLER_ABI from 'abis/lendle/IncentivesController.json'
import MULTI_FEE_DISTRIBUTION_ABI from 'abis/lendle/MultiFeeDistribution.json'
import STABLE_DEBT_TOKEN from 'abis/aave/StableDebtToken.json'
import { TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { SupportedChainId } from 'constants/chains'
import { getLendleTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { addressesLendleCore, addressesLendleLTokens, addressesLendleSTokens, addressesLendleVTokens } from 'hooks/1delta/addressesLendle'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { Lender } from 'types/lenderData/base'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { getLenderAssets } from 'constants/getAssets'
import { AaveV2TypeGetUserReserveData } from '../aave-v2/fetchUserData'

const fallbackDebtToken = '0x52A1CeB68Ee6b7B5D13E0376A1E0E4423A8cE26e'

interface LendleUserReserveResponse {
  chainId: number
  account: string
  tokensData: {
    [tokenSymbol: string]: {
      // token amounts
      deposits: number
      debt: number
      debtStable: number
      // usd amounts
      depositsUSD: number
      debtUSD: number
      debtStableUSD: number

      collateralActive: boolean
      claimableRewards: number
    }
  },
  lendRewards: number
  allowances: { [asset: string]: AllowanceData }
}

interface AllowanceData {
  allowanceDepositDirect: SerializedBigNumber
  allowanceWithdrawal: SerializedBigNumber
  allowanceBorrowingVariable: SerializedBigNumber
  allowanceBorrowingStable: SerializedBigNumber
}

interface LendleUserReservesQueryParams {
  chainId: number
  account: string
  prices: { [asset: string]: number }
}

export const fetchLendleUserData: AsyncThunk<
  LendleUserReserveResponse,
  LendleUserReservesQueryParams,
  any
> = createAsyncThunk<LendleUserReserveResponse, LendleUserReservesQueryParams>(
  'lendle/fetchLendleUserData',

  async ({ chainId, account, prices }) => {
    // catch invalid inputs
    if (!account || chainId !== SupportedChainId.MANTLE) {
      return { chainId, tokensData: {}, lendRewards: 0, allowances: {}, account: '' }
    }

    const assetsToQuery = getLenderAssets(chainId, Lender.LENDLE)
    const providerContract = getLendlePoolDataProviderContract(chainId)
    const lendleTokens = getLendleTokenAddresses(chainId)
    const assets = assetsToQuery.map((a) => lendleTokens[a])
    const names = assetsToQuery
    const calls: Call[] = assets.map((tk) => {
      return {
        address: providerContract.address,
        name: 'getUserReserveData',
        params: [tk, account],
      }
    })

    const lTokens = getAddressesForChainIdFromAssetDict(addressesLendleLTokens, chainId, Lender.LENDLE)
    const vTokens = getAddressesForChainIdFromAssetDict(addressesLendleVTokens, chainId, Lender.LENDLE)
    const sTokens = getAddressesForChainIdFromAssetDict(
      addressesLendleSTokens,
      chainId,
      Lender.LENDLE
    )

    let lAndVTokensAddresses: string[] = []

    // rewards are accrued for both l and v tokens
    names.forEach((name) => {
      lAndVTokensAddresses.push(lTokens[name], vTokens[name])
    })

    // vestable rewards for each l and v token
    const callClaimableRewards = {
      address: addressesLendleCore.IncentivesController[chainId],
      name: 'claimableReward',
      params: [account, lAndVTokensAddresses],
    }

    // total vested LEND rewards
    const callEarnedBalances = {
      address: addressesLendleCore.MultiFeeDistribution[chainId],
      name: 'earnedBalances',
      params: [account],
    }

    // allowances


    const broker = ONE_DELTA_COMPOSER[chainId]
    const aavePool = addressesLendleCore.PoolProxy[chainId]

    const callsDirect: Call[] = names.map((tk) => {
      return {
        address: lendleTokens[tk],
        name: 'allowance',
        params: [account, aavePool],
      }
    })
    const callsWithdraw: Call[] = names.map((tk) => {
      return {
        address: lTokens[tk],
        name: 'allowance',
        params: [account, broker],
      }
    })
    const callsBorrowVariable: Call[] = names.map((tk) => {
      return {
        address: vTokens[tk] ?? fallbackDebtToken,
        name: 'borrowAllowance',
        params: [account, broker],
      }
    })
    const callsBorrowStable: Call[] = names.map((tk) => {
      return {
        address: sTokens[tk] ?? fallbackDebtToken,
        name: 'borrowAllowance',
        params: [account, broker],
      }
    })

    let ABI = [
      ...AAVE_POOL_AND_DATA_PROVIDER_ABI,
      ...INCENTIVES_CONTROLLER_ABI, ...MULTI_FEE_DISTRIBUTION_ABI,
      ...STABLE_DEBT_TOKEN
    ]
    ABI = ABI.filter((v, i, a) => a.findIndex(t => (t.name === v.name && t.type === v.type)) === i)

    let multicallResult: any[] = []
    try {
      multicallResult = await multicallViem(
        chainId,
        ABI,
        [
          ...calls,
          callClaimableRewards, callEarnedBalances,
          ...callsDirect, ...callsWithdraw, ...callsBorrowVariable, ...callsBorrowStable
        ],
        0, // primary
      )
    } catch (e: any) {
      console.log("Error fetchung user data:", e)
      return { chainId, tokensData: {}, lendRewards: 0, allowances: {}, account: '' }
    }

    const callsLength = calls.length - 1

    const claimableRewards = multicallResult[callsLength + 1]
    const earnedBalances = multicallResult[callsLength + 2]
    // map claimable rewards to tokens
    // foreach asset, we sum the l and v token rewards
    const mappedClaimableRewardsToTokens = names.map((name, index) => {
      return {
        [name]:
          parseRawAmount(claimableRewards[index * 2].toString(), 18) +
          parseRawAmount(claimableRewards[index * 2 + 1].toString(), 18)
      }
    })

    const tokensData: any = Object.assign(
      {},
      ...multicallResult.slice(0, callsLength + 1).map((entry: any, index) => {
        const asset = names[index]
        const decimals = TOKEN_META[asset]?.decimals ?? 18
        const price = prices[asset]
        const deposits = parseRawAmount(entry?.[AaveV2TypeGetUserReserveData.currentATokenBalance].toString(), decimals)
        const debtStable = parseRawAmount(entry?.[AaveV2TypeGetUserReserveData.currentStableDebt].toString(), decimals)
        const debt = parseRawAmount(entry?.[AaveV2TypeGetUserReserveData.currentVariableDebt].toString(), decimals)
        return {
          [asset]: {
            // raw amounts
            deposits,
            debtStable,
            debt,
            // USD amounts
            depositsUSD: deposits * price,
            debtStableUSD: debtStable * price,
            debtUSD: debt * price,
            collateralActive: Boolean(entry?.[AaveV2TypeGetUserReserveData.usageAsCollateralEnabled]),
            claimableRewards: mappedClaimableRewardsToTokens[index][asset],
          },
        }
      })
    )

    const lendRewards = parseRawAmount(earnedBalances[0].toString(), 18)

    // allowances
    let start = calls.length + 2
    const tokensCount = names.length
    const directResults = multicallResult.slice(start, start + tokensCount)
    start += tokensCount
    const collateralResults = multicallResult.slice(start, start + tokensCount)
    start += tokensCount
    const delegationVariableResults = multicallResult.slice(start, start + tokensCount)
    start += tokensCount
    const delegationStableResults = multicallResult.slice(start, start + tokensCount)
    // create data
    const allowances: any = Object.assign(
      {},
      ...names.map((name, index) => {
        return {
          [name]: {
            allowanceDepositDirect: directResults[index]?.toString(),
            allowanceWithdrawal: collateralResults[index]?.toString(),
            allowanceBorrowingVariable: delegationVariableResults[index]?.toString(),
            allowanceBorrowingStable: delegationStableResults[index]?.toString(),
          }
        }
      })
    )

    return {
      chainId,
      account,
      tokensData,
      lendRewards,
      allowances
    }
  }
)