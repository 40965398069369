import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { SupportedChainId } from 'constants/chains'
import { ethers } from 'ethers'
import { getOVixOTokens } from 'hooks/1delta/addresses0Vix'
import { getCompoundCTokens } from 'hooks/1delta/addressesCompound'
import { getCompoundComptrollerContract, getCompoundLensContract } from 'hooks/1delta/use1DeltaContract'
import { SupportedAssets } from 'types/1delta'
import { Call, multicallViem } from 'utils/multicall'
import COMPOUND_LENS_ABI from 'abis/compound-v2/CompoundLens.json'
import OVIX_LENS_ABI from 'abis/compound-v2/OVixLens.json'
import { C_TOKEN_DECIMALS, TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'

interface ReplyEntry {
  // base
  deposits: number
  debt: number
  collateralActive: boolean
  // cToken
  cTokenBalance: number
  cTokenBalanceRaw: string
  // allowance
  tokenAllowance: number
}

interface ResponseData {
  [tokenSymbol: string]: {
    [accountAddress: string]: ReplyEntry
  }
}

interface Summary {
  [accountAddress: string]: {
    markets: string[]
    liquidity: number
    shortfall: number
  }
}

export interface CompoundPublicResponse {
  chainId: number
  data?: ResponseData
  summary?: Summary
}

export interface CompoundAccountQueryParams {
  chainId?: number
  accounts: {
    [index: number]: string
  }
  assetIds: SupportedAssets[]
}

export const fetchVenusUserData: AsyncThunk<CompoundPublicResponse, CompoundAccountQueryParams, any> =
  createAsyncThunk<CompoundPublicResponse, CompoundAccountQueryParams>(
    'venus/fetchVenusUserData',

    async ({ chainId, accounts, assetIds }) => {
      if (!accounts || Object.values(accounts).length === 0 || !chainId) return { chainId: 0 }

      const isEthereum = chainId === SupportedChainId.BSC
      if (!isEthereum) return { chainId }

      const accountsArray = Object.values(accounts)
      const cTokens = isEthereum ? getCompoundCTokens(chainId, assetIds) : getOVixOTokens(chainId, assetIds)
      const lensContract = getCompoundLensContract(chainId)
      const comptroller = getCompoundComptrollerContract(chainId)
      const calls: Call[] = []
      for (let i = 0; i < assetIds.length; i++) {
        const cToken = cTokens[assetIds[i]]
        for (let k = 0; k < accountsArray.length; k++) {
          calls.push({
            address: lensContract.address,
            name: 'cTokenBalances',
            params: [cToken, accountsArray[k]],
          })
        }
      }

      const callsSummary: Call[] = []
      for (let k = 0; k < accountsArray.length; k++) {
        callsSummary.push({
          address: lensContract.address,
          name: 'getAccountLimits',
          params: [comptroller.address, accountsArray[k]],
        })
      }

      let multicallResult: ethers.utils.Result[]
      try {
        multicallResult = await multicallViem(chainId,
          isEthereum ? COMPOUND_LENS_ABI : OVIX_LENS_ABI, [
          ...calls,
        ],
          1 // secondary
        )
      } catch (err) {
        multicallResult = []
        console.log('Error fetching data', err)
      }

      const balsData = multicallResult
      const finalData: ResponseData = {}
      let currentIndex = 0
      for (let i = 0; i < assetIds.length; i++) {
        const asset = assetIds[i]
        const decs = TOKEN_META[asset].decimals
        finalData[asset] = {}
        for (let k = 0; k < accountsArray.length; k++) {
          const cTokenBalanceRaw = balsData[currentIndex].balanceOf.toString()
          finalData[asset][accountsArray[k]] = {
            cTokenBalance: parseRawAmount(cTokenBalanceRaw, C_TOKEN_DECIMALS),
            cTokenBalanceRaw,
            debt: parseRawAmount(balsData[currentIndex].borrowBalanceCurrent.toString(), decs),
            deposits: parseRawAmount(balsData[currentIndex].balanceOfUnderlying.toString(), decs),
            tokenAllowance: parseRawAmount(balsData[currentIndex].tokenAllowance.toString(), decs),
            collateralActive: true,
          }
          currentIndex += 1
        }
      }

      return { data: finalData, chainId }
    }
  )
