import { mergeAndOrderTxArray } from "../lendle/queries";
import { AaveTypeTx, AggregatedTxDict, ExtendedAaveTypeTx, TxMap } from "./types";
import _ from "lodash"

export interface AssetPnL {
  realized: number;
  floating: number;
}

export interface AssetPnLBaseline {
  realized: number;
  floating: {
    amount: number;
    averagePrice: number
  };
}

export interface PnLData {
  pnlsLong: {
    [asset: string]: AssetPnLBaseline;
  };
  pnlsShort: {
    [asset: string]: AssetPnLBaseline;
  };
}

interface AggregatedData {
  amount: number
  amountUSD: number
}

export type AggregatedDataDict = { [asset: string]: AggregatedData }

const USD_AMOUNT_THRESHOLD = 0.01

/**
 * Merges two dictionaries that map a key to an array
 * @param dict0 first dictionary
 * @param dict1 second dictionary
 * @returns merged dictionary
 */
export function mergeDicts<T>(dict0: { [k: string]: T[] }, dict1: { [k: string]: T[] }) {
  const newDict: { [k: string]: T[] } = {}
  const keys = uniqueKeys(dict0, dict1)
  for (const key of keys) {
    newDict[key] = _.concat(dict0[key] ?? [], dict1[key] ?? [])
  }
  return newDict
}

export function dictToArray<T>(dict: { [k: string]: T[] }): T[] {
  let arr: T[] = []
  for (const d in dict) arr = _.concat(arr, dict[d])
  return arr
}

/**
 * Get unique keys from a total of two dictionaries
 * @param dict0 first dictionary
 * @param dict1 secnd dictionary
 * @returns array of unique keys
 */
const uniqueKeys = (dict0: { [k: string]: any }, dict1: { [k: string]: any }) => {
  return _.uniq(_.concat(Object.keys(dict0), Object.keys(dict1)))
}

/**
 * Calculate the past balance based on the current balance and transactions
 * Agnostic towards longs / shorts -> negative tx amounts account for a reduction, 
 * positive ones for an increase
 * @param balanceDict current long balance
 * @param aggregatedTx long transactions
 * @param txShort short transactions
 * @param histPrices past prices
 * @returns past balance mapping
 */
export const produceOriginalBalance = (
  balanceDict: AggregatedDataDict,
  aggregatedTx: AggregatedDataDict,
  histPrices: { [asset: string]: number },
): AggregatedDataDict => {
  const longKeys = uniqueKeys(balanceDict, aggregatedTx)

  const originalBalance = Object.assign({}, ...longKeys.map(k => { return { [k]: balanceDict[k] ?? { amount: 0, amountUSD: 0 } } }))
  for (const longKey of longKeys) {
    const adjsutment = aggregatedTx[longKey]?.amount ?? 0
    const amount = Math.abs(originalBalance[longKey]?.amount - adjsutment)
    let price = histPrices[longKey]
    if (isNaN(price)) price = 0 // set undefined prices to 0
    const amountUSD = (price * amount) ?? Math.abs(originalBalance[longKey].amountUSD - aggregatedTx[longKey]?.amountUSD ?? 0)
    originalBalance[longKey] = {
      amount,
      amountUSD,
    }
    if (originalBalance[longKey].amountUSD <= USD_AMOUNT_THRESHOLD) originalBalance[longKey] = { amount: 0, amountUSD: 0 }  // adjust for interest / dust
  }
  return originalBalance
}


/**
 * Calculates the pnl given all parameters
 * @param startingUserBalances user's start balances 
 * @param histShort user's start balances 
 * @param currentUserBalance user's end/current balances 
 * @returns pnl mapping and balance history
 */
export const calculateFullAaveTypePnLFromTxns = (
  startingUserBalances: AggregatedDataDict,
  currentUserBalance: AggregatedDataDict,
  txDict: { [asset: string]: AaveTypeTx[] },
  isLong = true
): {
  pnl: { [asset: string]: AssetPnLBaseline };
  txDictEnriched: { [asset: string]: ExtendedAaveTypeTx[] }
} => {
  const assets = uniqueKeys(startingUserBalances, txDict)
  const pnlsDict: { [asset: string]: AssetPnLBaseline } = {}
  const multiplier = isLong ? 1 : -1
  let txDictEnriched: { [asset: string]: ExtendedAaveTypeTx[] } = {}
  for (const asset of assets) {
    let closedPnl = 0
    let { amount, amountUSD } = startingUserBalances[asset]
    let currentAveragePrice = amount > 0 ? amountUSD / amount : 0
    let averageWeight = amount
    let ordered: ExtendedAaveTypeTx[] = mergeAndOrderTxArray(txDict[asset])?.sort((a, b) => a.timestamp - b.timestamp)
    if (ordered)
      for (let tx of ordered) {
        if (tx.amount > 0) {
          // update avergage price
          currentAveragePrice = (averageWeight * currentAveragePrice + tx.amountUSD) / (averageWeight + tx.amount)
          // update weight
          averageWeight += tx.amount
          tx.averagePrice = currentAveragePrice
        } else {
          const closePrice = Math.abs(tx.amountUSD / tx.amount)
          const closePnL = multiplier * (-(closePrice - currentAveragePrice) * tx.amount)
          closedPnl += closePnL
          tx.realized = closePnL
          tx.averagePrice = currentAveragePrice
          // update weight, make sure that it is >= 0
          averageWeight = safeCeilZero(averageWeight + tx.amount)
        }
      }

    pnlsDict[asset] = { realized: closedPnl, floating: { averagePrice: currentAveragePrice, amount: multiplier * (currentUserBalance[asset]?.amount ?? 0) } }
    txDictEnriched[asset] = ordered
  }
  return { pnl: pnlsDict, txDictEnriched }
}

/**
 * Calculate the net flow of a series of transactions
 * @param data array of transactions
 * @returns the flow in currency and usd
 */
const aggregateTx = (data: AaveTypeTx[]) => {
  return {
    amount: _.sum(data.map(a => a.amount)),
    amountUSD: _.sum(data.map(a => a.amountUSD)),
  }
}

// aggregate all txns per asset of a TxMap
export const aggreagateTxns = (txDict: TxMap) => {
  const aggregatedDict: AggregatedTxDict = {}
  for (let key in txDict) {
    const aggregated = aggregateTx(txDict[key])
    aggregatedDict[key] = aggregated
  }
  return aggregatedDict
}

export interface BalanceSnapshot {
  amount: number
  amountUSD: number
  timestamp: number
}

/**
 * Calculates the pnl given all parameters
 * @param startingUserBalances user's start balances 
 * @param histShort user's start balances 
 * @param currentUserBalance user's end/current balances 
 * @param refTime a timestamp parameter to fill the initial balance
 * @returns pnl mapping and balance history
 */
export const calculateFullLendleDataFromTxns = (
  startingUserBalances: AggregatedDataDict,
  currentUserBalance: AggregatedDataDict,
  txDict: { [asset: string]: AaveTypeTx[] },
  isLong = true,
  refTime = 0
): {
  pnl: {
    [asset: string]: AssetPnLBaseline;
  }
  balances: {
    [asset: string]: BalanceSnapshot[]
  }
  txDictEnriched: { [asset: string]: ExtendedAaveTypeTx[] },
} => {
  const assets = uniqueKeys(startingUserBalances, txDict)
  const pnlsDict: { [asset: string]: AssetPnLBaseline } = {}
  const multiplier = isLong ? 1 : -1
  let balances: {
    [asset: string]: BalanceSnapshot[]
  } = {}
  let txDictEnriched: { [asset: string]: ExtendedAaveTypeTx[] } = {}
  for (const asset of assets) {
    let closedPnl = 0
    let { amount, amountUSD } = startingUserBalances[asset]
    let currentAveragePrice = amount > 0 ? amountUSD / amount : 0
    let averageWeight = amount
    balances[asset] = [{ amount, amountUSD, timestamp: refTime }]
    let ordered: ExtendedAaveTypeTx[] = mergeAndOrderTxArray(txDict[asset])?.sort((a, b) => a.timestamp - b.timestamp)
    if (ordered)
      for (let tx of ordered) {
        if (tx.amount > 0) {
          // update avergage price
          currentAveragePrice = (averageWeight * currentAveragePrice + tx.amountUSD) / (averageWeight + tx.amount)
          // update weight
          averageWeight += tx.amount
          tx.averagePrice = currentAveragePrice
        } else {
          const closePrice = Math.abs(tx.amountUSD / tx.amount)
          const closePnL = multiplier * (-(closePrice - currentAveragePrice) * tx.amount)
          closedPnl += closePnL
          tx.realized = closePnL
          tx.averagePrice = currentAveragePrice
          // update weight, make sure that it is >= 0
          averageWeight = safeCeilZero(averageWeight + tx.amount)
        }
        balances[asset].push(
          {
            amount: Math.max(averageWeight, 0),
            amountUSD: _.round(Math.max(averageWeight, averageWeight * currentAveragePrice, 0), 2),
            timestamp: tx.timestamp
          }
        )
      }

    pnlsDict[asset] = {
      realized: closedPnl,
      floating: {
        averagePrice: currentAveragePrice,
        amount: multiplier * (currentUserBalance[asset]?.amount ?? 0) // adjust balance for sign
      }
    }
    txDictEnriched[asset] = ordered
  }
  return { pnl: pnlsDict, balances, txDictEnriched }
}

/**
 * Merge txns of a single type / asset
 * @param txns input txns
 * @returns merged txs
 */
export const mergeTxns = (txns: AaveTypeTx[]) => {
  let aggregated: AaveTypeTx[] = []
  const hashes = _.uniq(txns.map(t => t.hash))
  for (let i = 0; i < hashes.length; i++) {
    const hash = hashes[i]
    const filtered = txns.filter(x => x.hash === hash)
    const amount = _.sumBy(filtered, x => x.amount)
    const amountUSD = _.sumBy(filtered, x => x.amountUSD)
    aggregated.push({
      hash,
      type: filtered[0].type,
      asset: filtered[0].asset,
      timestamp: filtered[0].timestamp,
      amount,
      amountUSD,
    })
  }
  return aggregated
}


export const mergeSameTypeTxnDict = (
  txDict: TxMap
) => {
  let aggrDict: TxMap = {}
  for (const asset in txDict) {
    aggrDict[asset] = mergeTxns(txDict[asset])
  }
  return aggrDict
}

const safeCeilZero = (n: number) => Math.max(n, 0)