import { TradeType } from '@1delta/base-sdk'
import { MarginTradeType, PositionSides, QuickActionType } from 'types/1delta'

export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 'Approve',
  SWAP = 'Swap',
  WRAP = 'Wrap',
  DIRECT_INTERACTION = 'Direct',
  MONEY_MARKET = 'Money Market',
  SINGLE_SIDE = 'Manage',
  MARGIN_TRADE = 'Margin',
  VEST_REWARDS = 'Vest Rewards',
  CLAIM_REWARDS = 'Claim Rewards',
  SWITCH_EMODE = 'SwitchEMode',
}

export interface BaseTransactionInfo {
  type: TransactionType
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
}

// direct interaction with lending protocol,i.e. depoist eth

interface BaseCurrency {
  inputCurrencyId: string
}

export interface DirectMoneyMarketTransactionInfo extends BaseTransactionInfo, BaseCurrency {
  type: TransactionType.DIRECT_INTERACTION
  subType: QuickActionType
  amount: string
  protocol: string
  account?: string
}

interface DoubleCurrency extends BaseCurrency {
  outputCurrencyId: string
}

interface BaseSwapTransactionInfo extends BaseTransactionInfo, DoubleCurrency {
  type: TransactionType.SWAP
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
}

interface BaseMoneyMarketTransactionInfo {
  type: TransactionType.MONEY_MARKET
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
  subType: QuickActionType
  protocol: string
  account?: string
}

// money market transactions, swap and deposit, borrow and swap, swap and repay etc.

export interface ExactInputMoneyMarketTransactionInfo extends BaseMoneyMarketTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
}

export interface ExactOutputMoneyMarketTransactionInfo extends BaseMoneyMarketTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}

// single side trade
interface BaseSingleSideTransactionInfo {
  type: TransactionType.SINGLE_SIDE
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
  subType: PositionSides
  protocol: string
  account?: string
}

export interface ExactInputSingleSideTransactionInfo extends BaseSingleSideTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
}

export interface ExactOutputSingleSideTransactionInfo extends BaseSingleSideTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}

export interface SameAssetSingleSideTransactionInfo extends BaseSingleSideTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  outputCurrencyAmountRaw: string
}

// margin trades
interface BaseMarginTradeTransactionInfo {
  type: TransactionType.MARGIN_TRADE
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
  subType: MarginTradeType
  protocol: string
  account?: string
}

export interface ExactInputMarginTradeTransactionInfo extends BaseMarginTradeTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
}

export interface ExactOutputMarginTradeTransactionInfo extends BaseMarginTradeTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}

export interface SameAssetMarginTradeTransactionInfo extends BaseMarginTradeTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  outputCurrencyAmountRaw: string
}

export interface ExactInputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
}

export interface ExactOutputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}

export interface WrapTransactionInfo {
  type: TransactionType.WRAP
  unwrapped: boolean
  currencyAmountRaw: string
  chainId?: number
}

export interface TransactionDetails {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
  info: TransactionInfo
}

export interface QuickActionTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.MONEY_MARKET
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
  inputAmountRaw: string
  outputAmountRaw: string
  txType: QuickActionType
  slippage: string
  lender: string
  account?: string
}

export interface SwapTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SWAP
  tradeType: TradeType
  txType: 'Spot'
  inputCurrencyId: string
  outputCurrencyId: string
  inputAmountRaw: string
  outputAmountRaw: string
  slippage: string
  aggregator: string
  account?: string
}

// margin trades
export interface MarginTradeTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.MARGIN_TRADE
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
  inputAmountRaw: string
  outputAmountRaw: string
  slippage: string
  txType: MarginTradeType
  lender: string
  account?: string
}

export interface VestRewardsTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.VEST_REWARDS
  lender: string
}

export interface ClaimRewardsTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.CLAIM_REWARDS
  lender: string
}

export interface SwitchEModeTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SWITCH_EMODE
  lender: string
  eMode: number
  label: string
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | ExactOutputSwapTransactionInfo
  | ExactInputSwapTransactionInfo
  | WrapTransactionInfo
  | DirectMoneyMarketTransactionInfo
  | ExactInputMoneyMarketTransactionInfo
  | ExactOutputMoneyMarketTransactionInfo
  | ExactInputSingleSideTransactionInfo
  | ExactOutputSingleSideTransactionInfo
  | ExactInputMarginTradeTransactionInfo
  | ExactOutputMarginTradeTransactionInfo
  | SameAssetSingleSideTransactionInfo
  | SameAssetMarginTradeTransactionInfo
  | QuickActionTransactionInfo
  | SwapTransactionInfo
  | WrapTransactionInfo
  | MarginTradeTransactionInfo
  | VestRewardsTransactionInfo
  | ClaimRewardsTransactionInfo
  | SwitchEModeTransactionInfo

export type TransactionInfoV2 =
  | ApproveTransactionInfo
  | QuickActionTransactionInfo
  | SwapTransactionInfo
  | WrapTransactionInfo
  | MarginTradeTransactionInfo
  | VestRewardsTransactionInfo
  | ClaimRewardsTransactionInfo
  | SwitchEModeTransactionInfo

export interface TransactionDetailsV2 {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
  info: TransactionInfoV2
}
