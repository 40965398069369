import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { getInitLensContract } from 'hooks/1delta/use1DeltaContract'
import { TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { addressToAsset } from 'hooks/1delta/addressesTokens'
import { getLenderAssets } from 'constants/getAssets'
import { Lender } from 'types/lenderData/base'
import { SupportedChainId } from 'constants/chains'
import { initBrokerAddresses } from 'hooks/1delta/addresses1Delta'
import INIT_LENS_ABI from 'abis/init/initLens.json'
import { Call, multicallViem } from 'utils/multicall'

enum InitGetUserPositionDataIndexes {
  posMeta = 0,
  collaterals,
  debts,
}

interface InitUserReserveResponse {
  chainId: number
  tokensData: {
    // positionId is the NFT id
    [posId: string]: {
      mode: number;
      isAllowed: boolean;
      isApprovedForAll: boolean;
      positions: {
        [tokenSymbol: string]: {
          deposits: number
          debt: number,
          depositsUSD: number
          debtUSD: number,
          debtStable: 0,
          debtStableUSD: 0,
          collateralActive: boolean
        }
      }
    }
  }
  assets: string[]
  account: string
}

export interface InitUserDataQueryParams {
  chainId: number
  account?: string
  prices: { [asset: string]: number }
}

export const fetchInitUserData: AsyncThunk<
  InitUserReserveResponse,
  InitUserDataQueryParams,
  any
> = createAsyncThunk<InitUserReserveResponse, InitUserDataQueryParams>(
  'lender-init/fetchInitUserData',

  async ({ chainId, account, prices }) => {
    if (chainId !== SupportedChainId.MANTLE || !account) return {
      chainId,
      tokensData: {},
      assets: [],
      account: ''
    }
    const validOwner = account.toLowerCase()
    const lensContract = getInitLensContract(chainId)
    const brorkerAddress = initBrokerAddresses[chainId] ?? ''
    try {
      const call: Call = {
        address: lensContract.address,
        name: 'getUserPositionData',
        params: [account, brorkerAddress],
      }
      const [data] = await multicallViem(
        chainId,
        INIT_LENS_ABI,
        [call]
      )
      // filter for owner
      const indexes = data[InitGetUserPositionDataIndexes.posMeta].map(
        (e, i) => { return { e, i } }
      ).filter(a => a.e.owner.toLowerCase() === validOwner).map(a => a.i)
      const assets = getLenderAssets(chainId, Lender.INIT)
      // map data
      const result: any = Object.assign(
        {},
        ...indexes.map((index) => {
          const entry = data[InitGetUserPositionDataIndexes.posMeta][index]
          const mode = entry.mode
          const id = entry.posId.toString()
          const collatData = Object.assign({}, ...data[InitGetUserPositionDataIndexes.collaterals][index].map(c => {
            const asset = addressToAsset(chainId, c.underlying)
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            return { [asset]: { deposits: parseRawAmount(c.amount.toString(), decimals) } }
          })
          )
          const debtData = Object.assign({}, ...data[InitGetUserPositionDataIndexes.debts][index].map(c => {
            const asset = addressToAsset(chainId, c.underlying)
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            return { [asset]: { debt: parseRawAmount(c.amount.toString(), decimals) } }
          })
          )

          return {
            [id]: {
              mode,
              isApprovedForAll: entry.isApprovedForAll,
              isAllowed: entry.approved.toLowerCase() === brorkerAddress.toLowerCase(),
              positions: Object.assign({}, ...assets.map(a => {
                const price = prices[a] ?? 1
                return {
                  [a]: {
                    deposits: collatData[a]?.deposits ?? 0,
                    debt: debtData[a]?.debt ?? 0,
                    debtStable: 0,
                    debtStableUSD: 0,
                    depositsUSD: price * (collatData[a]?.deposits ?? 0),
                    debtUSD: price * (debtData[a]?.debt ?? 0),
                    collateralActive: true,
                  }
                }
              })
              )
            }
          }
        }
        )
      )

      return {
        chainId,
        tokensData: result,
        assets,
        account
      }
    } catch (e: any) {
      console.log("Error fetching init data", e)
    }
    return {
      chainId,
      tokensData: {},
      assets: [],
      account: ''
    }
  }
)