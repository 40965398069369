export const primitives = {
  "Colors/Greens/100": "#ccf3e8",
  "Colors/Greens/200": "#99e7d2",
  "Colors/Greens/300": "#67dbbb",
  "Colors/Greens/400": "#34cfa5",
  "Colors/Greens/500": "#01c38e",
  "Colors/Greens/600": "#019c72",
  "Colors/Greens/700": "#017555",
  "Colors/Greens/800": "#004e39",
  "Colors/Greens/900": "#00271c",

  "Colors/Blues/50": "#334a5b",
  "Colors/Blues/100": "#1f313f",
  "Colors/Blues/200": "#192834",
  "Colors/Blues/250": "#1A232C",
  "Colors/Blues/300": "#141c24",
  "Colors/Blues/400": "#0f1823",

  "Colors/Whites/0": "#ffffff",
  "Colors/Whites/100": "#f9f9f9",
  "Colors/Whites/200": "#f3f3f3",
  "Colors/Whites/300": "#efefef",
  "Colors/Whites/400": "#eaeaea",
  "Colors/Whites/500": "#e7e7e7",
  "Colors/Whites/600": "#e2e2e2",
  "Colors/Whites/700": "#dedede",
  "Colors/Whites/800": "#dddddd",
  "Colors/Whites/900": "#d9d9d9",

  "Colors/Grayscale/100": "#cfd1d3",
  "Colors/Grayscale/200": "#9fa3a7",
  "Colors/Grayscale/250": "#6C7087",
  "Colors/Grayscale/300": "#6f747b",
  "Colors/Grayscale/400": "#3f464f",
  "Colors/Grayscale/500": "#0f1823",
  "Colors/Grayscale/600": "#0c131c",
  "Colors/Grayscale/700": "#090e15",
  "Colors/Grayscale/800": "#060a0e",
  "Colors/Grayscale/900": "#030507",

  "Colors/Accent 1/100": "#e8ecf1",
  "Colors/Accent 1/200": "#d1d9e2",
  "Colors/Accent 1/300": "#bbc6d4",
  "Colors/Accent 1/400": "#a4b3c5",
  "Colors/Accent 1/500": "#8da0b7",
  "Colors/Accent 1/600": "#718092",
  "Colors/Accent 1/700": "#55606e",
  "Colors/Accent 1/800": "#384049",
  "Colors/Accent 1/900": "#1c2025",

  "Colors/Accent 2/100": "#faeee0",
  "Colors/Accent 2/200": "#f5ddc2",
  "Colors/Accent 2/300": "#f1cca3",
  "Colors/Accent 2/400": "#ecbb85",
  "Colors/Accent 2/500": "#e7aa66",
  "Colors/Accent 2/600": "#b98852",
  "Colors/Accent 2/700": "#8b663d",
  "Colors/Accent 2/800": "#5c4429",
  "Colors/Accent 2/900": "#2e2214",

  "Colors/Accent 3/100": "#f8dfdc",
  "Colors/Accent 3/200": "#f1bfb9",
  "Colors/Accent 3/300": "#eaa095",
  "Colors/Accent 3/400": "#e38072",
  "Colors/Accent 3/500": "#dc604f",
  "Colors/Accent 3/600": "#B04D3F",
  "Colors/Accent 3/700": "#843a2f",
  "Colors/Accent 3/800": "#582620",
  "Colors/Accent 3/900": "#2c1310",
}
