import { createAction } from '@reduxjs/toolkit'
import { Field, MarginTradeType, QuickActionType, SupportedAssets } from 'types/1delta';

export const resetSelectionState = createAction<void>('margin/resetSelectionState')
export const selectMarginCurrency = createAction<{ field: Field; currencyId: string }>('margin/selectMarginCurrency')
export const switchCurrencies = createAction<void>('margin/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('margin/typeInput')
export const setRecipient = createAction<{ recipient: string | null }>('margin/setRecipient')
export const setMarginInteraction = createAction<{ marginTradeType: MarginTradeType }>('margin/setMarginInteraction')
export const setQuickActionType = createAction<{ quickActionType: QuickActionType }>('margin/setQuickActionType')

export const setBaseCurrency = createAction<{ asset: SupportedAssets }>('margin/setBaseCurrency')
export const replaceMarginState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
}>('margin/replaceMarginState')