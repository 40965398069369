import { Currency } from "@1delta/base-sdk"
import { useWeb3ReactWrapped } from "hooks/web3"
import { useEffect, useMemo } from "react"
import { useSetSubscribeConfig, useSubscribeConfig } from "state/globalNetwork/hooks"

export const useSubscribeToCurrencies = (
  chainId: number,
  currencyIn: Currency | undefined,
  currencyOut: Currency | undefined,
  addressToApprove: string | undefined
) => {

  // we add the token selection to the
  const setSubConfig = useSetSubscribeConfig(chainId)
  const subConfig = useSubscribeConfig(chainId)
  useEffect(() => {
    let tokens = [...(subConfig.tokens ?? [])]
    let approvalTargets = (subConfig.approvalTargets ?? []).map(t => t.toLowerCase())
    const inAddress = currencyIn?.wrapped.address.toLowerCase()
    const outAddress = currencyOut?.wrapped.address.toLowerCase()
    const targetAddress = addressToApprove?.toLowerCase()
    const inIsIncluded = inAddress && subConfig.tokens?.includes(inAddress)
    const outIsIncluded = outAddress && subConfig.tokens?.includes(outAddress)
    // add target
    if (targetAddress && !approvalTargets.includes(targetAddress)) approvalTargets.push(targetAddress)
    // add addresses that are not included
    if (inAddress && !inIsIncluded) tokens.push(inAddress!)
    if (outAddress && !outIsIncluded) tokens.push(outAddress!)
    // only in case we push new data, we update the state
    if (
      ((subConfig.tokens ?? []).length < tokens.length) ||
      ((subConfig.approvalTargets ?? []).length < approvalTargets.length)
    )
      setSubConfig({ tokens, approvalTargets })
  }, [chainId, currencyIn?.wrapped.address, currencyOut?.wrapped.address, addressToApprove])
}

/**
 * Subscribes a currency list
 * @param chainId network
 * @param currencies token list to subscribe
 */
export const useSubscribeToBalances = (
  chainId: number,
  currencies: Currency[],
) => {

  const { account } = useWeb3ReactWrapped()
  // we add the token selection to the
  const setSubConfig = useSetSubscribeConfig(chainId)
  const subConfig = useSubscribeConfig(chainId)

  const configTokens = useMemo(() => [...(subConfig?.tokens ?? [])], [subConfig?.tokens])

  const notIncluded: string[] = useMemo(() => !account ? [] : currencies.map(
    a => a.wrapped.address.toLowerCase()
  ).filter(a => a !== undefined && !(subConfig?.tokens ?? []).includes(a)) as any,
    [configTokens, currencies, account]
  )

  useEffect(() => {
    if (account) {
      let tokens = [...(subConfig?.tokens ?? [])]

      if (notIncluded.length === 0) return;

      // add addresses that are not included
      notIncluded.map(a => tokens.push(a))

      // only in case we push new data, we update the state
      if ((subConfig?.tokens ?? []).length < tokens.length)
        setSubConfig({ tokens })
    } else {
      // if not connected, we clear the list
      if ((subConfig?.tokens ?? []).length > 0)
        setSubConfig({ tokens: [], approvalTargets: subConfig?.approvalTargets ?? [] })
    }
  }, [chainId, notIncluded, account])
}