import { VStack, Text } from '@chakra-ui/layout'
import { TextProps } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { formatAbbreviatedNumberNoFallback, formatAbbreviatedPrice } from 'utils/tableUtils/format'

interface BalanceTokenAndUsdProps {
  balance: number
  balanceUsd: number
  tokenSymbol: string
  isMedium?: boolean
  topTextProps?: TextProps
  stackProps?: TextProps
}

export const BalanceTokenAndUsd: React.FC<BalanceTokenAndUsdProps> = ({
  balance,
  balanceUsd,
  tokenSymbol,
  isMedium = false,
  topTextProps,
  stackProps,
}) => {
  return (
    <VStack alignItems={'flex-start'} gap={'0.25rem'} {...stackProps}>
      <Text
        style={getTypography('Typography/Body-Labels/Normal/Body')}
        color={validateColor('Text/Lables/Label-text-default')}
        lineHeight={'1.3 !important'}
        {...topTextProps}
      >
        {formatAbbreviatedPrice(balanceUsd)}
      </Text>
      {!isMedium && (
        <Text
          style={getTypography('Typography/Small/Normal/Small 2')}
          color={validateColor('Text/Lables/Label-text-disabled')}
          lineHeight={'1 !important'}
        >
          {formatAbbreviatedNumberNoFallback(balance)} {tokenSymbol}
        </Text>
      )}
    </VStack>
  )
}
