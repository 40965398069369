import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

// Redirects to swap but only replace the pathname
export function RedirectPathToMarginOnly({ location }: RouteComponentProps) {
  return <Redirect to={{ ...location, pathname: '/margin' }} />
}

// Redirects from the /swap/:outputCurrency path to the /swap?outputCurrency=:outputCurrency format
export function RedirectToMargin(props: RouteComponentProps<{ chain: string, lender: string, operation: string, inputCurrency: string, outputCurrency: string }>) {
  const {
    location,
    location: { search },
    match: {
      params: { chain, lender, operation, inputCurrency, outputCurrency },
    },
  } = props

  return (
    <Redirect
      to={{
        ...location,
        pathname: '/margin',
        search:
          search && search.length > 1
            ? `${search}&chain=${chain}&lender=${lender}&operation=${operation}&inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}`
            : `?chain=${chain}&lender=${lender}&operation=${operation}&inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}`,
      }}
    />
  )
}
