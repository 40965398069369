import { SecondaryMediumButton } from '../Secondary'
import { Avatar, AvatarBadge, MenuButton, MenuButtonProps } from '@chakra-ui/react'
import { useIsMobile } from 'hooks/useIsMobile'
import { validateColor } from 'theme/colors'

interface MenuIconButtonProps extends MenuButtonProps {
  iconSrc: string
  isOpen: boolean
  withBadge?: boolean
  badgeValue?: number
}

export const MenuIconButton: React.FC<MenuIconButtonProps> = ({ iconSrc, isOpen, withBadge, badgeValue, ...props }) => {
  const isMobile = useIsMobile()

  return (
    <SecondaryMediumButton
      as={MenuButton}
      p="0.25rem"
      minW={0}
      border="none"
      w={isMobile ? '30px' : '34px'}
      h={isMobile ? '30px' : '34px'}
      _active={{
        backgroundColor: validateColor('Surface/Surface-tertiary'),
      }}
      css={{
        '> span': {
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      {...props}
    >
      <Avatar
        src={iconSrc}
        w="100%"
        h="100%"
        borderRadius={'0'}
        css={{
          '> img': {
            objectFit: 'fill',
          },
        }}
      >
        {!withBadge && null}
        {withBadge && badgeValue === 0 && null}
        {withBadge && badgeValue && badgeValue > 0 && (
          <AvatarBadge
            border="1px solid"
            borderColor={validateColor('Borders/Border-tertiary')}
            bg={validateColor('Notifications/Background')}
            placement="top-end"
            boxSize={'1rem'}
            height={'0.8rem'}
            borderRadius={'6.25rem'}
            padding="0.125rem 0.0625rem"
            color={validateColor('Notifications/Number')}
            fontSize={isMobile ? '0.5rem' : '0.625rem'}
            fontWeight={700}
          >
            {badgeValue > 99 ? '99+' : badgeValue}
          </AvatarBadge>
        )}
      </Avatar>
    </SecondaryMediumButton>
  )
}
