import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { getCometContract } from 'hooks/1delta/use1DeltaContract'
import { SerializedBigNumber, SupportedAssets } from 'types/1delta'
import { Call, multicallViem } from 'utils/multicall'

import COMET_ABI from 'abis/compound-v3/Comet.json'
import COMET_EXT_ABI from 'abis/compound-v3/CometExt.json'
import COMET_REWARDS_ABI from 'abis/compound-v3/CometRewards.json'

import { POLYGON_CHAINS, TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { compoundV3AssetKey } from './reducer'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { getCompoundV3TokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { addressesCompoundV3Core } from 'hooks/1delta/addressesCompoundV3'

export interface CompoundV3UserResponse {
  tokensData: {
    [tokenSymbol: string]: {
      // tokens
      deposits: number
      debt: number
      // dollars
      depositsUSD: number
      debtUSD: number

      collateralActive: boolean
      // user collateral raw (to withdraw max)
      depositRaw: SerializedBigNumber;
    }
  },
  isAllowed: boolean
  chainId: number
  baseAsset: SupportedAssets
  compRewards: number
  account: string
}

export interface CompoundV3UserQueryParams {
  chainId: number
  account: string | undefined
  baseAsset: SupportedAssets
  prices: { [asset: string]: number }
}

export const fetchCompoundV3UserData: AsyncThunk<CompoundV3UserResponse, CompoundV3UserQueryParams, any> =
  createAsyncThunk<CompoundV3UserResponse, CompoundV3UserQueryParams>(
    'compound-v3/fetchUserData',

    async ({ chainId, account, baseAsset, prices }) => {
      if (!POLYGON_CHAINS.includes(chainId) || !account) return {
        chainId,
        tokensData: {},
        isAllowed: false,
        account: '',
        baseAsset,
        compRewards: 0
      }
      const rawAddressDict = getCompoundV3TokenAddresses(chainId)
      const cometContract = getCometContract(chainId, SupportedAssets.USDCE)
      const names = Object.keys(rawAddressDict)
      const assetsNoBase = names.filter(a => a !== baseAsset)

      const allowedCall = {
        address: cometContract.address,
        name: 'isAllowed',
        params: [account, ONE_DELTA_COMPOSER[chainId]],
      }

      const baseCalls = [
        {
          address: cometContract.address,
          name: 'balanceOf',
          params: [account],
        },
        {
          address: cometContract.address,
          name: 'borrowBalanceOf',
          params: [account],
        }
      ]

      const callsNonBase: Call[] = assetsNoBase.map((tk) => {
        return {
          address: cometContract.address,
          name: 'userCollateral',
          params: [account, rawAddressDict[tk]],
        }
      })

      const callEarnedBalances = {
        address: addressesCompoundV3Core.cometRewards[chainId],
        name: 'getRewardOwed',
        params: [cometContract.address, account],
      }

      let ABI = [...COMET_ABI, ...COMET_EXT_ABI, ...COMET_REWARDS_ABI]
      // remove duplicate definitions
      ABI = ABI.filter((v, i, a) => a.findIndex(t => (t.name === v.name && t.type === v.type)) === i)

      const multicallResult = await multicallViem(chainId,
        ABI,
        [
          allowedCall, ...baseCalls, ...callsNonBase, callEarnedBalances
        ],
        1
      )
      const baseResults = multicallResult.slice(1, 3)
      const baseDecimals = TOKEN_META[baseAsset].decimals ?? 18
      const deposits = parseRawAmount(baseResults[0]?.toString(), baseDecimals)
      const debt = parseRawAmount(baseResults[1]?.toString(), baseDecimals)
      const priceBase = prices[baseAsset]
      const resultBase = {
        [compoundV3AssetKey(baseAsset, baseAsset)]: {
          deposits,
          debt,
          depositsUSD: deposits * priceBase,
          debtUSD: debt * priceBase,
          // populate values for totals
          debtStable: 0,
          debtStableUSD: 0,
          collateralActive: true,
          // user depos raw
          depositRaw: baseResults[0]?.toString(),
        },
      }


      const nonBaseResults = multicallResult.slice(3, callsNonBase.length + 3)

      const result = Object.assign(
        {},
        ...nonBaseResults.map((entry, index) => {
          const asset = assetsNoBase[index]
          const decimals = TOKEN_META[asset]?.decimals ?? 18
          const deposits = parseRawAmount(entry[0]?.toString(), decimals)
          const price = prices[asset]
          return {
            [compoundV3AssetKey(baseAsset, asset)]: {
              deposits,
              depositsUSD: deposits * price,
              debt: 0,
              debtUSD: 0,
              collateralActive: true,
              // user depos raw
              depositRaw: entry[0]?.toString(),
            },
          }
        })
      )

      // the result is an array: [tokenAddress, owedAmount] (tokenAddress should be always COMP)
      // so we take [1] to get the owedAmount
      const earnedBalances = multicallResult[multicallResult.length - 1]
      const compRewards = parseRawAmount(earnedBalances.owed.toString(), 18)

      return {
        tokensData: { ...result, ...resultBase },
        chainId,
        isAllowed: Boolean(multicallResult[0]),
        baseAsset,
        compRewards,
        account
      }
    }
  )
