import { SupportedChainId } from "./chains";


export function getDefaultGasConfig(chainId: number) {
  switch (chainId) {
    case SupportedChainId.TAIKO: {
      return {
        maxFeePerGas: 50000001,
        maxPriorityFeePerGas: 50000001
      }
    }
    case SupportedChainId.MANTLE: {
      return {
        maxFeePerGas: 0.02 * 1e9,
        maxPriorityFeePerGas: 0.02 * 1e9
      }
    }
    default: return {}
  }
}