import { Currency } from "@1delta/base-sdk";
import { TOKEN_SVGS } from "constants/1delta";
import { useMemo } from "react";
import { useAppSelector } from "state/hooks";
import { chainIdToDexscreenerChainId } from "types/1delta";

/** Determinsitic DExscreener url for token on chain */
function dexscreenerLogo(address: string, chainId: number) {
  return `https://dd.dexscreener.com/ds-data/tokens/${chainIdToDexscreenerChainId[chainId]}/${address}.png`
}

/**
 * Fetch the logo URI from the state
 * For native, we use our SVG mapping, for tokens our lists
 * If we do not have it, we fall back to dexscreener's standardized URI urls
 * @param currency asset to fetch uri for
 * @returns uri or undefined
 */
export const useGetTokenURI = (currency?: Currency) => {
  const rawList = useAppSelector((state) => state.lists?.byChain?.[currency?.chainId ?? 0]?.currentList)
  return useMemo(() => {
    if (!currency) return ''
    if (currency.isNative) return TOKEN_SVGS[currency.symbol]
    const address = currency.wrapped.address?.toLowerCase()
    const url = rawList?.list?.[address]?.logoURI
    if (url) return url
    return dexscreenerLogo(address, currency.chainId)
  },
    [currency, rawList])
}

