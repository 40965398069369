import { Box, BoxProps, StackProps, VStack } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'

interface TooltipContentProps {
  title?: React.ReactNode
  titleProps?: BoxProps
  body?: React.ReactNode
  bodyProps?: StackProps
  containerProps?: StackProps
}

/**
 * @param title
 * @param titleProps
 * @param body
 * @param bodyProps
 */
export const TooltipContent: React.FC<TooltipContentProps> = ({
  title,
  titleProps,
  body,
  bodyProps,
  containerProps,
}) => {
  return (
    <VStack gap="0.5em" alignItems={'flex-start'} {...containerProps}>
      {title ? (
        <Box
          style={getTypography('Typography/Body-Labels/Normal/Body')}
          color={validateColor('Text/Headings & Titles/Title-text')}
          lineHeight={'1'}
          {...titleProps}
        >
          {title}
        </Box>
      ) : null}
      {body ? (
        <VStack
          gap="0.25em"
          alignItems={'flex-start'}
          style={getTypography('Typography/Small/Normal/Small 2')}
          color={validateColor('Text/Lables/Label-text-default')}
          w={'100%'}
          {...bodyProps}
        >
          {body}
        </VStack>
      ) : null}
    </VStack>
  )
}
