import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'

export const addressesAaveV2Core: AddressDictionary = {
  AaveOracle: {
    [SupportedChainId.POLYGON]: '0xb023e699F5a33916Ea823A16485e259257cA8Bd1',
  },
  PoolProxy: {
    [SupportedChainId.POLYGON]: '0x8dff5e27ea6b7ac08ebfdf9eb090f32ee9a30fcf', // marked as pool in docs
  },
  PoolDataProvider: {
    [SupportedChainId.POLYGON]: '0x7551b5D2763519d4e37e8B81929D336De671d46d',
  },
}

export const addressesAaveV2ATokens: AddressDictionary = {
  [SupportedAssets.DAI]: {
    [SupportedChainId.POLYGON]: '0x27F8D03b3a2196956ED754baDc28D73be8830A6e'
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.POLYGON]: '0x1a13F4Ca1d028320A707D99520AbFefca3998b7F'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.POLYGON]: '0x60D55F02A771d515e077c9C2403a1ef324885CeC'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.POLYGON]: '0x5c2ed810328349100A66B82b78a1791B101C9D61'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON]: '0x28424507fefb6f7f8E9D3860F56504E4e5f5f390'
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.POLYGON]: '0x8dF3aad3a84da6b69A4DA8aeC3eA40d9091B2Ac4'
  },
  [SupportedAssets.AAVE]: {
    [SupportedChainId.POLYGON]: '0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360'
  },
  [SupportedAssets.GHST]: {
    [SupportedChainId.POLYGON]: '0x080b5BF8f360F624628E0fb961F4e67c9e3c7CF1'
  },
  [SupportedAssets.BAL]: {
    [SupportedChainId.POLYGON]: '0xc4195D4060DaEac44058Ed668AA5EfEc50D77ff6'
  },
  [SupportedAssets.DPI]: {
    [SupportedChainId.POLYGON]: '0x81fB82aAcB4aBE262fc57F06fD4c1d2De347D7B1'
  },
  [SupportedAssets.CRV]: {
    [SupportedChainId.POLYGON]: '0x3Df8f92b7E798820ddcCA2EBEA7BAbda2c90c4aD'
  },
  [SupportedAssets.SUSHI]: {
    [SupportedChainId.POLYGON]: '0x21eC9431B5B55c5339Eb1AE7582763087F98FAc2'
  },
  [SupportedAssets.LINK]: {
    [SupportedChainId.POLYGON]: '0x0Ca2e42e8c21954af73Bc9af1213E4e81D6a669A'
  },
}

export const addressesAaveV2VTokens: AddressDictionary = {
  [SupportedAssets.DAI]: {
    [SupportedChainId.POLYGON]: '0x75c4d1Fb84429023170086f06E682DcbBF537b7d'
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.POLYGON]: '0x248960A9d75EdFa3de94F7193eae3161Eb349a12'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.POLYGON]: '0x8038857FD47108A07d1f6Bf652ef1cBeC279A2f3'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.POLYGON]: '0xF664F50631A6f0D72ecdaa0e49b0c019Fa72a8dC'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON]: '0xeDe17e9d79fc6f9fF9250D9EEfbdB88Cc18038b5'
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.POLYGON]: '0x59e8E9100cbfCBCBAdf86b9279fa61526bBB8765'
  },
  [SupportedAssets.AAVE]: {
    [SupportedChainId.POLYGON]: '0x1c313e9d0d826662F5CE692134D938656F681350'
  },
  [SupportedAssets.GHST]: {
    [SupportedChainId.POLYGON]: '0x36e988a38542C3482013Bb54ee46aC1fb1efedcd'
  },
  [SupportedAssets.BAL]: {
    [SupportedChainId.POLYGON]: '0x773E0e32e7b6a00b7cA9daa85dfba9D61B7f2574'
  },
  [SupportedAssets.DPI]: {
    [SupportedChainId.POLYGON]: '0x43150AA0B7e19293D935A412C8607f9172d3d3f3'
  },
  [SupportedAssets.CRV]: {
    [SupportedChainId.POLYGON]: '0x780BbcBCda2cdb0d2c61fd9BC68c9046B18f3229'
  },
  [SupportedAssets.SUSHI]: {
    [SupportedChainId.POLYGON]: '0x9CB9fEaFA73bF392C905eEbf5669ad3d073c3DFC'
  },
  [SupportedAssets.LINK]: {
    [SupportedChainId.POLYGON]: '0xCC71e4A38c974e19bdBC6C0C19b63b8520b1Bb09'
  },
}

export const addressesAaveV2STokens: AddressDictionary = {
  [SupportedAssets.DAI]: {
    [SupportedChainId.POLYGON]: '0x2238101B7014C279aaF6b408A284E49cDBd5DB55'
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.POLYGON]: '0xdeb05676dB0DB85cecafE8933c903466Bf20C572'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.POLYGON]: '0xe590cfca10e81FeD9B0e4496381f02256f5d2f61'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.POLYGON]: '0x2551B15dB740dB8348bFaDFe06830210eC2c2F13'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON]: '0xc478cBbeB590C76b01ce658f8C4dda04f30e2C6f'
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.POLYGON]: '0xb9A6E29fB540C5F1243ef643EB39b0AcbC2e68E3'
  },
  [SupportedAssets.AAVE]: {
    [SupportedChainId.POLYGON]: '0x17912140e780B29Ba01381F088f21E8d75F954F9'
  },
  [SupportedAssets.GHST]: {
    [SupportedChainId.POLYGON]: '0x6A01Db46Ae51B19A6B85be38f1AA102d8735d05b'
  },
  [SupportedAssets.BAL]: {
    [SupportedChainId.POLYGON]: '0xbC30bbe0472E0E86b6f395f9876B950A13B23923'
  },
  [SupportedAssets.DPI]: {
    [SupportedChainId.POLYGON]: '0xA742710c0244a8Ebcf533368e3f0B956B6E53F7B'
  },
  [SupportedAssets.CRV]: {
    [SupportedChainId.POLYGON]: '0x807c97744e6C9452e7C2914d78f49d171a9974a0'
  },
  [SupportedAssets.SUSHI]: {
    [SupportedChainId.POLYGON]: '0x7Ed588DCb30Ea11A54D8a5E9645960262A97cd54'
  },
  [SupportedAssets.LINK]: {
    [SupportedChainId.POLYGON]: '0x9fb7F546E60DDFaA242CAeF146FA2f4172088117'
  },
}
