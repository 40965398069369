import { AssetFlags, getAssetFlagIcon } from 'constants/1delta'
import { useAssetFlags } from 'hooks/asset/useAssetFlags'
import { SupportedAssets } from 'types/1delta'
import { HStack, Img, Tooltip } from '@chakra-ui/react'

interface AssetStatusIndicatorProps {
  asset: SupportedAssets | string
}

export const AssetStatusIndicator: React.FC<AssetStatusIndicatorProps> = ({ asset }) => {
  const { isFrozen, reachedSupplyCap, reachedBorrowCap } = useAssetFlags(asset)
  return (
    <HStack alignItems="center" gap="0.1rem">
      {isFrozen && (
        <Tooltip label="This asset is frozen.">
          <Img src={getAssetFlagIcon(AssetFlags.FROZEN)} alt="Frozen" w="15px" h="15px" />
        </Tooltip>
      )}
      {reachedSupplyCap && (
        <Tooltip label="This asset has almost reached its supply cap.">
          <Img src={getAssetFlagIcon(AssetFlags.SUPPLYCAP)} alt="Supply cap reached" w="15px" h="15px" />
        </Tooltip>
      )}
      {reachedBorrowCap && (
        <Tooltip label="This asset has almost reached its borrow cap.">
          <Img src={getAssetFlagIcon(AssetFlags.BORROWCAP)} alt="Borrow cap reached" w="15px" h="15px" />
        </Tooltip>
      )}
    </HStack>
  )
}
