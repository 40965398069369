import { Currency, CurrencyAmount, Token } from '@1delta/base-sdk'
import { useMemo } from 'react'

import { useAllTokens } from '../../hooks/Tokens'
import { useChainId, useSubscribedCurrencyBalances } from 'state/globalNetwork/hooks'

export {
  useCurrencyBalanceString,
} from 'lib/hooks/useCurrencyBalance'

// mimics useAllBalances
export function useAllTokenBalances(): [(CurrencyAmount<Currency> | undefined)[], boolean] {
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const chainId = useChainId()
  const { balances, isLoading } = useSubscribedCurrencyBalances(chainId, allTokensArray)
  return [balances ?? [], isLoading]
}
