import { SecondaryMediumButton } from '../Secondary'
import { ButtonProps } from '@chakra-ui/react'
import { useIsMobile } from 'hooks/useIsMobile'
import { useThemeColors, validateColor } from 'theme/colors'
import { Power } from 'react-feather'

export const DisconnectButton: React.FC<ButtonProps> = (props) => {
  const [iconColor] = useThemeColors(['Icons/Icon-default'])

  return (
    <SecondaryMediumButton
      p="0"
      minW={'34px'}
      border="none"
      w={'34px'}
      h={'34px'}
      _active={{
        backgroundColor: validateColor('Surface/Surface-tertiary'),
      }}
      {...props}
    >
      <Power size={20} color={iconColor} />
    </SecondaryMediumButton>
  )
}
