import { ConnectionType } from 'connection/types'
import CoinbaseWalletIcon from 'assets/images/coinbaseWalletIcon.svg'
import WalletConnectIcon from 'assets/images/walletConnectIcon.svg'
import ParticleIcon from 'assets/wallets/particle-icon.png'
import Identicon from '.'
import { Image, ImageProps } from '@chakra-ui/react'
import { getInjection } from 'connection/utils'

export default function StatusIcon({ size = 20 }: { size?: number }) {
  return <Identicon size={size} />
}

export const WalletIcon: React.FC<ImageProps & { connectionType: ConnectionType }> = ({ connectionType, ...props }) => {
  if (connectionType === ConnectionType.INJECTED) {
    const wallet = getInjection()
    return <Image src={wallet.icon} alt="Injected Wallet Icon" {...props} />
  } else if (connectionType === ConnectionType.WALLET_CONNECT) {
    return <Image src={WalletConnectIcon} alt="WalletConnect" {...props} />
  } else if (connectionType === ConnectionType.COINBASE_WALLET) {
    return <Image src={CoinbaseWalletIcon} alt="Coinbase Wallet" {...props} />
  } else if (connectionType === ConnectionType.SOCIAL_LOGIN) {
    return <Image src={ParticleIcon} alt="Particle Wallet" {...props} />
  }
  return null
}
