import { Button, ButtonProps } from '@chakra-ui/button'
import { Image } from '@chakra-ui/image'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { default as sun } from 'assets/icons/sun-light.svg'
import { default as moon } from 'assets/icons/moon-light.svg'
import { Text } from '@chakra-ui/layout'

interface ThemeButtonProps extends ButtonProps {
  isDark: boolean
}

export const ThemeButton: React.FC<ThemeButtonProps> = ({ isDark, ...props }) => {
  return (
    <Button
      {...props}
      p={'0.25rem'}
      gap={'0.125rem'}
      alignItems={'center'}
      borderRadius={'0.25rem'}
      border={'1px solid'}
      borderColor={isDark ? validateColor('Borders/Border-secondary') : 'transparent'}
      bg={isDark ? validateColor('Surface/Surface-primary') : validateColor('Surface/Surface-tertiary')}
      _hover={
        isDark
          ? {
              bg: validateColor('Surface/Surface-primary'),
            }
          : {
              bg: validateColor('Surface/Surface-tertiary'),
            }
      }
      _active={
        isDark
          ? {
              bg: validateColor('Surface/Surface-primary'),
            }
          : {
              bg: validateColor('Surface/Surface-tertiary'),
            }
      }
      color={validateColor('Text/Lables/Label-text-default')}
      height={'auto'}
      style={getTypography('Typography/Small/Normal/Small 2')}
      minW={'3.5rem'}
    >
      {isDark ? (
        <>
          <Text>Dark</Text>
          <Image src={moon} w={'1rem'} h={'1rem'} />
        </>
      ) : (
        <>
          <Image src={sun} w={'1rem'} h={'1rem'} />
          <Text>Light</Text>
        </>
      )}
    </Button>
  )
}
