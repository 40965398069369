import { VStack, Text } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { Card } from './Card'

interface UpdatesCard {
  title: string
  subTitle: string
  content: string
}

export const UpdatesCard: React.FC<UpdatesCard> = ({ title, subTitle, content }) => {
  const buttonBody = (
    <VStack w="100%" alignItems={'flex-start'} color={validateColor('Text/Lables/Label-text-default')}>
      <Text style={getTypography('Typography/Small/Normal/Small 1')}>{title}</Text>
      <Text style={getTypography('Typography/Small/Normal/Small 2')}>{subTitle}</Text>
    </VStack>
  )

  const panelBody = (
    <VStack
      style={getTypography('Typography/Small/Normal/Small 2')}
      gap="0.5rem"
      alignItems={'flex-start'}
      color={validateColor('Text/Lables/Label-text-hover')}
    >
      <Text>{content}</Text>
    </VStack>
  )

  return <Card buttonBody={buttonBody} panelBody={panelBody} />
}
