import { typographyPathsKeys } from './typographyPathsKeys';

export const typographyPaths: typographyPathsKeys = {
  "Typography/Headings/Normal/Heading 1": {
    fontSize: "6.25rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal"
  },
  "Typography/Headings/Normal/Heading 2": {
    fontSize: "5.625rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "6.375rem"
  },
  "Typography/Headings/Normal/Heading 3": {
    fontSize: "5rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "5.875rem"
  },
  "Typography/Headings/Normal/Heading 4": {
    fontSize: "4.375rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "5.375rem"
  },
  "Typography/Headings/Normal/Heading 5": {
    fontSize: "3.75rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "4.875rem"
  },
  "Typography/Headings/Bold/Heading 1": {
    fontSize: "6.25rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal"
  },
  "Typography/Headings/Bold/Heading 2": {
    fontSize: "5.625rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "6.375rem"
  },
  "Typography/Headings/Bold/Heading 3": {
    fontSize: "5rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "5.875rem"
  },
  "Typography/Headings/Bold/Heading 4": {
    fontSize: "4.375rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "5.375rem"
  },
  "Typography/Headings/Bold/Heading 5": {
    fontSize: "3.75rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "4.875rem"
  },
  "Typography/Headings/Semibold": {
    fontSize: "1.875rem",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "2.375rem",
  },
  "Typography/Sub-Headings/Normal/Sub-Heading 1": {
    fontSize: "3.125rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "3.875rem"
  },
  "Typography/Sub-Headings/Normal/Sub-Heading 2": {
    fontSize: "2.5rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "3.375rem"
  },
  "Typography/Sub-Headings/Normal/Sub-Heading 3": {
    fontSize: "1.875rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "2.375rem"
  },
  "Typography/Sub-Headings/Bold/Sub-Heading 1": {
    fontSize: "3.125rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "3.875rem"
  },
  "Typography/Sub-Headings/Bold/Sub-Heading 2": {
    fontSize: "2.5rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "3.375rem"
  },
  "Typography/Sub-Headings/Bold/Sub-Heading 3": {
    fontSize: "1.875rem",
    fontStyle: "normal",
    fontWeight: "700"
  },
  "Typography/Captions/Normal/Caption 1": {
    fontSize: "1.375rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.875rem"
  },
  "Typography/Captions/Normal/Caption 2": {
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: "400"
  },
  "Typography/Captions/Bold/Caption 1": {
    fontSize: "1.375rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "1.875rem"
  },
  "Typography/Captions/Bold/Caption 2": {
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: "700"
  },
  "Typography/Body-Labels/Normal/Body": {
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.375rem"
  },
  "Typography/Body-Labels/Bold/Body-Label": {
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "1.375rem"
  },
  "Typography/Body-Labels/Normal/Sub-Label": {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.375rem"
  },
  "Typography/Body-Labels/Bold/Sub-Label": {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "1.375rem"
  },
  "Typography/Body-Labels/Medium/Body-Label-Medium": {
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "1rem"
  },
  "Typography/Small/Normal/Small 1": {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1rem"
  },
  "Typography/Small/Normal/Small 2": {
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1rem"
  },
  "Typography/Small/Normal/Small 3": {
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: "400",
  },
  "Typography/Small/Bold/Small 1": {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "1.375rem"
  },
  "Typography/Small/Bold/Small 2": {
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "1.375rem"
  },
  "Typography/Small/Bold/Small 3": {
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "1.375rem"
  },
  "Typography/Small/Underline/Small 1 Underline": {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "400",
    textDecorationLine: "underline"
  },
  "Typography/Small/Underline/Small 2 Underline": {
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: "400",
    textDecorationLine: "underline"
  },
  "Typography/Small/Underline/Small 3 Underline": {
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: "400",
    textDecorationLine: "underline"
  }
}
