import { ButtonProps, forwardRef } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { LargeButton, MediumButton, ButtonWithIconProps, SmallButton, Icon } from '../common'
import { useUserTheme } from 'state/user/hooks'
import { chevronDown } from 'constants/1delta'

const SecondaryButtonProps: ButtonProps = {
  backgroundColor: validateColor('Button/Button Secondary/Button-secondary-default'),
  color: validateColor('Button/Button Secondary/Button-secondary-text-default'),
  border: `1px solid`,
  borderColor: validateColor('Borders/Border-secondary'),
  _hover: {
    backgroundColor: validateColor('Button/Button Secondary/Button-secondary-hover'),
  },
  _active: {
    backgroundColor: validateColor('Button/Button Secondary/Button-secondary-active'),
  },
  _disabled: {
    backgroundColor: validateColor('Button/Button Primary/Button-primary-disabled'),
    color: validateColor('Text/Lables/Label-text-disabled'),
    cursor: 'not-allowed',
  },
}

const SecondaryMediumIconRightButtonProps: ButtonProps = {
  ...SecondaryButtonProps,
  _hover: {
    ...SecondaryButtonProps._hover,
    '& > span > span > img': {
      transform: 'rotate(180deg)',
      transition: 'transform 0.2s ease-in-out',
    },
  },
  _active: {
    ...SecondaryButtonProps._active,
    '& > span > span > img': {
      transform: 'rotate(180deg)',
      transition: 'transform 0.2s ease-in-out',
    },
  },
}

export const SecondaryLargeButton: React.FC<ButtonProps> = (props) => {
  return <LargeButton {...SecondaryButtonProps} {...props} />
}

export const SecondaryMediumButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return <MediumButton {...SecondaryButtonProps} {...props} ref={ref} />
})

export const SecondaryMediumIconRightButton = forwardRef<ButtonWithIconProps, 'button'>((props, ref) => {
  const theme = useUserTheme()

  const defaultIcon = chevronDown[theme]
  const defaultAlt = 'Chevron Down'

  return (
    <MediumButton
      {...SecondaryMediumIconRightButtonProps}
      {...props}
      rightIcon={<Icon src={props.iconSrc || defaultIcon} alt={props.alt || defaultAlt} />}
      css={{
        '& > span > span > img': {
          transition: 'transform 0.2s ease-in-out',
        },
        '& span': {
          display: 'flex',
          width: 'auto',
          justifyContent: 'space-between',
          gap: '0.5rem',
        },
        '& span > span': {
          margin: '0',
          width: 'auto',
        },
      }}
      ref={ref}
    />
  )
})

export const SecondarySmallButton: React.FC<ButtonProps> = (props) => {
  return <SmallButton {...SecondaryButtonProps} {...props} />
}
