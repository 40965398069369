import { Currency, Token } from '@1delta/base-sdk'
import { SupportedChainId } from 'constants/chains'
import { useCurrencyFromMap, useTokenFromMapOrNetwork } from 'lib/hooks/useCurrency'
import { useMemo } from 'react'
import { useChainId } from 'state/globalNetwork/hooks'

import { useDeltaTokenList } from '../state/lists/hooks'
import { useUserAddedTokens, useUserAddedTokensOnChain } from '../state/user/hooks'
import { getTokensByAddress } from './1delta/tokens'
import { Lender } from 'types/lenderData/base'

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
function useTokensFromMap(tokenMap: { [address: string]: Token }, includeUserAdded: boolean): { [address: string]: Token } {
  const chainId = useChainId()
  const userAddedTokens = useUserAddedTokens()

  return useMemo(() => {
    if (!chainId) return {}

    // reduce to just tokens
    const mapWithoutUrls = Object.keys(tokenMap ?? {}).reduce<{ [address: string]: Token }>(
      (newMap, address) => {
        newMap[address] = tokenMap[address]
        return newMap
      },
      {}
    )

    if (includeUserAdded) {
      return (
        userAddedTokens
          // reduce into all ALL_TOKENS filtered by the current chain
          .reduce<{ [address: string]: Token }>(
            (tokenMap, token) => {
              tokenMap[token.address.toLowerCase()] = token
              return tokenMap
            },
            // must make a copy because reduce modifies the map, and we do not
            // want to make a copy in every iteration
            { ...mapWithoutUrls }
          )
      )
    }

    return mapWithoutUrls
  }, [chainId, userAddedTokens, tokenMap, includeUserAdded])
}

export function useAllTokens(): { [address: string]: Token } {
  const chainId = useChainId()
  const allTokens = useDeltaTokenList(chainId)
  return useTokensFromMap(allTokens, true)
}

type BridgeInfo = Record<
  SupportedChainId,
  {
    tokenAddress: `0x${string}`
    originBridgeAddress: string
    destBridgeAddress: string
  }
>

export function useIsTokenActive(token: Token | undefined): boolean {
  const activeTokens = useAllTokens()

  if (!activeTokens || !token) {
    return false
  }

  return !!activeTokens[token.address]
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency | undefined): boolean {
  const userAddedTokens = useUserAddedTokens()

  if (!currency) {
    return false
  }

  return !!userAddedTokens.find((token) => currency.equals(token))
}

// Check if currency on specific chain is included in custom list from user storage
export function useIsUserAddedTokenOnChain(
  address: string | undefined | null,
  chain: number | undefined | null
): boolean {
  const userAddedTokens = useUserAddedTokensOnChain(chain)

  if (!address || !chain) {
    return false
  }

  return !!userAddedTokens.find((token) => token.address.toLowerCase() === address.toLowerCase())
}

// undefined if invalid or does not exist
// null if loading or null was passed
// otherwise returns the token
export function useToken(tokenAddress?: string | null): Token | undefined {
  const tokens = useAllTokens()
  return useTokenFromMapOrNetwork(tokens, tokenAddress)
}

export function useCurrency(
  currencyId?: string | null
): Currency | undefined {
  const tokens = useAllTokens()
  return useCurrencyFromMap(tokens, currencyId)
}

export function useCurrencyWithFallback(
  currencyId?: string | null,
  lendingProtocol = Lender.AAVE_V3
): Currency | undefined {
  const validatedId = (currencyId === 'MATIC' || currencyId === 'MNT') ? 'ETH' : currencyId
  const chainId = useChainId()
  const ccy = useCurrency(validatedId)
  return Boolean(ccy) ? ccy : getTokensByAddress(chainId, lendingProtocol)[validatedId?.toLowerCase() ?? '']
}
