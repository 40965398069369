import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { multicallViem } from 'utils/multicall'
import ORACLE_ABI from 'abis/aave/AAVEOracle.json'
import UNI_V2_PAIR_ABI from 'abis/uniswap/uniswap-v2-pair.json'
import CHAINLINK_AGGREGATOR_ABI from 'abis/chainlink/ChainLinkAggregator.json'
import API3_ABI from 'abis/api3/Api3Oracle.json'
import RWA_DYNAMIC_ABI from 'abis/rwa-dynamic/RWADynamicOracle.json'

import { SupportedChainId } from 'constants/chains'
import { getAaveV3TokenAddresses, getLendleTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { getAaveOracleContract } from 'hooks/1delta/use1DeltaContract'
import { formatAavePrice, parseRawAmount } from 'utils/tableUtils/prices'
import { chainlinkOracles, getChainLinkKeys } from 'hooks/1delta/addresses'
import { SupportedAssets } from 'types/1delta'
import { api3OracleAddresses } from 'hooks/1delta/oracles/api3'
import { fetchDefillamaData } from './defillama/fetchDefillamaData'
import { ChainlinkAggregatorIndexes } from 'state/lenders/compound-v3/fetchPublicData'

const LENDLE_PAIR_MANTLE = '0x4c57BE599d0e0414785943569E9B6A66dA79Aa6b'
const WMNT_USDT_PAIR = '0x3e5922cd0cec71dc2d60ec8b36aa4c05b7c1672f'
const RWA_DYNAMIC_ORACLE = '0xA96abbe61AfEdEB0D14a20440Ae7100D9aB4882f'

interface OracleData {
  data: {
    [key: string]: number
  }
  chainId: number
}

interface QueryParams {
  chainId: number
}

/**
 * Fetches Aave and uniswap V2 oracle data
 */
export const fetchOracleData: AsyncThunk<OracleData, QueryParams, any> = createAsyncThunk<
  OracleData,
  QueryParams
>('oracles/fetchOracleData', async ({ chainId }) => {
  if (chainId === SupportedChainId.BSC) return { chainId, data: {} }

  const rawAddressDict = chainId === SupportedChainId.MANTLE ? getLendleTokenAddresses(chainId) : getAaveV3TokenAddresses(chainId)
  const filtered = Object.fromEntries(Object.entries(rawAddressDict));
  const aaveAssetNames = Object.keys(filtered)
  const addressesAaveUnderlyings = Object.values(filtered)

  const callAave = getAaveCalls(chainId, addressesAaveUnderlyings)

  const [callsChainLink, chainLinkNames] = getChainLinkCalls(chainId)

  const uniswapV2Calls = getUniswapV2Calls(chainId)

  const [api3Calls, api3Names] = getApi3Calls(chainId)

  const [rwaCalls, rwaNames] = getRWADynamicOracleCalls(chainId)

  const multicallResult = await multicallViem(
    chainId,
    [...CHAINLINK_AGGREGATOR_ABI, ...UNI_V2_PAIR_ABI, ...ORACLE_ABI, ...API3_ABI, ...RWA_DYNAMIC_ABI],
    [...callsChainLink, ...callAave, ...uniswapV2Calls, ...api3Calls, ...rwaCalls],
    1 // secondary
  )

  const chainLinkResults = multicallResult.slice(
    0,
    callsChainLink.length
  )

  const [aaveResult] = multicallResult.slice(
    callsChainLink.length,
    callsChainLink.length + callAave.length
  )

  const chainLinkData = parseChainLinkResults(chainId, chainLinkResults, chainLinkNames)

  const aaveData = parseAaveResults(chainId, aaveResult, aaveAssetNames)

  const uniswapResult = multicallResult.slice(
    callsChainLink.length + callAave.length,
    callsChainLink.length + callAave.length + uniswapV2Calls.length
  )

  const api3Result = multicallResult.slice(
    callsChainLink.length + callAave.length + uniswapV2Calls.length,
    callsChainLink.length + callAave.length + uniswapV2Calls.length + api3Calls.length
  )


  const uniswapData = parseUniswapV2results(uniswapResult, chainId)

  const api3Data = parseApi3Results(chainId, api3Result, api3Names)

  const rwaResult = multicallResult.slice(
    callsChainLink.length + callAave.length + uniswapV2Calls.length + api3Calls.length,
    callsChainLink.length + callAave.length + uniswapV2Calls.length + api3Calls.length + rwaCalls.length
  )

  const rwaData = parseRWADynamicOracleResults(chainId, rwaResult, rwaNames)

  const { prices: defillamaPrices } = await fetchDefillamaData()

  return { data: { ...defillamaPrices, ...uniswapData, ...aaveData, ...chainLinkData, ...api3Data, ...rwaData, }, chainId }
})

/**
 * Gets the calls foir the uniswap pools
 * @param chainId network id
 * @returns array of calls
 */
const getUniswapV2Calls = (chainId: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return [
        {
          address: WMNT_USDT_PAIR,
          name: 'getReserves',
          params: []
        },
        {
          address: LENDLE_PAIR_MANTLE,
          name: 'getReserves',
          params: []
        },
      ]
    default:
      return []
  }
}

/**
 * Create calldata for aave oracles
 * @param chainId network
 * @param addressesAaveUnderlyings address array 
 * @returns call data
 */
const getAaveCalls = (chainId: number, addressesAaveUnderlyings: string[]) => {
  switch (chainId) {
    case SupportedChainId.BSC:
      return []
    default: {
      const aaveOracle = getAaveOracleContract(chainId)
      return [{
        address: aaveOracle.address,
        name: 'getAssetsPrices',
        params: [addressesAaveUnderlyings],
      }]
    }

  }
}

/**
 * Create calldata for chainLink oracles
 * @param chainId network
 * @param addressesAaveUnderlyings address array 
 * @returns call data
 */
const getChainLinkCalls = (chainId: number): [
  {
    address: string,
    name: string
    params: any[],
  }[],
  string[]
] => {
  switch (chainId) {
    case SupportedChainId.MANTLE:
      return [[], []]
    default: {
      const keys = getChainLinkKeys(chainId).filter(k => k.split('')[1] === 'USD')
      const addresses = keys.map((k) => chainlinkOracles[k][chainId])
      return [
        addresses.map((tk) => {
          return {
            address: tk,
            name: 'latestRoundData',
            params: [],
          }
        }),
        keys.map(k => k.split('-')[0])
      ]
    }
  }
}


/**
 * Processes data creeated from fetch through 'getUniswapV2Calls
 * @param data the result data array slice from the multicall
 * @param chainId network id
 * @returns price dictionary asset->number
 */
const parseUniswapV2results = (data: any[], chainId: number) => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      let uniswapData = {}
      if (data.length > 0) {
        const reserves = data[1]
        // lendle and wmnt are respective token0s
        const lendleReserve = parseRawAmount(reserves[0].toString(), 18)
        const wmntReserve = parseRawAmount(reserves[1].toString(), 18)
        const reservesWmntUsdt = data[0]
        const usdtReserve = parseRawAmount(reservesWmntUsdt[0].toString(), 6)
        const wmnt2Reserve = parseRawAmount(reservesWmntUsdt[1].toString(), 18)
        const lendPrice = wmntReserve / lendleReserve / wmnt2Reserve * usdtReserve
        uniswapData['LEND'] = lendPrice
      }
      return uniswapData
    }
    default:
      return {}
  }
}

/**
 * Parser for aave results in ulticall slice
 * @param chainId network
 * @param data result array slice
 * @param assetName asset names (aligned with the originall addresses called)
 * @returns price mapping asset->number
 */
const parseAaveResults = (chainId: number, data: any, assetName: string[]) => {
  switch (chainId) {
    case SupportedChainId.BSC: {

      return {}
    }
    default: {
      return Object.assign(
        {},
        ...data.map((entry, index) => {
          return {
            [assetName[index]]: formatAavePrice(entry.toString(), chainId),
          }
        })
      )
    }
  }
}


/**
 * Parser for aave results in ulticall slice
 * @param chainId network
 * @param data result array slice
 * @param assetName asset names (aligned with the originall addresses called)
 * @returns price mapping asset->number
 */
const parseChainLinkResults = (chainId: number, data: any[], assetName: string[]) => {
  switch (chainId) {
    case SupportedChainId.BSC: {
      return {}
    }
    default: {
      return Object.assign(
        {},
        ...data.map((entry, index) => {
          return {
            [assetName[index]]: parseRawAmount(entry?.[ChainlinkAggregatorIndexes.answer]?.toString() ?? '0', 8)
          }
        })
      )
    }
  }
}


/**
 * Create calldata for api3 oracles
 * @param chainId network
 * @returns call data
 */
const getApi3Calls = (chainId: number): [
  {
    address: string,
    name: string
    params: any[],
  }[],
  string[]
] => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      const keys = [SupportedAssets.WETH, SupportedAssets.METH]
      const addresses = keys.map((k) => api3OracleAddresses[chainId][k])
      return [
        addresses.map((tk) => {
          return {
            address: tk,
            name: 'read',
            params: [],
          }
        }),
        keys
      ]
    }
    default:
      return [[], []]
  }
}

/**
 * Parser for api3 results in multicall slice
 * @param chainId network
 * @param data result array slice
 * @param assetName asset names (aligned with the originall addresses called)
 * @returns price mapping asset->number
 */
const parseApi3Results = (chainId: number, data: any[], assetName: string[]) => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      let prices = Object.assign(
        {},
        ...data.map((entry, index) => {
          return {
            [assetName[index]]: parseRawAmount(entry?.[0]?.toString() ?? '0', 18)
          }
        })
      )
      // METH is given in mETH/WETH 
      prices[SupportedAssets.METH] = prices[SupportedAssets.METH] * prices[SupportedAssets.WETH]
      return prices
    }
    default: {
      return {}
    }
  }
}

/**
 * Create calldata for RWADynamicOracle (wrap rate for musd/usdy)
 * @param chainId network
 * @returns call data
 */
const getRWADynamicOracleCalls = (chainId: number): [
  {
    address: string,
    name: string
    params: any[],
  }[],
  string[]
] => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      const keys = [SupportedAssets.USDY_MUSD]
      return [[{
        address: RWA_DYNAMIC_ORACLE,
        name: 'getPrice',
        params: [],
      }], keys]
    }
    default:
      return [[], []]
  }
}

/**
 * Parser for RWADynamicOracle (wrap rate for musd/usdy) results in multicall slice
 * @param chainId network
 * @param data result array slice
 * @param assetName asset names (aligned with the originall addresses called)
 * @returns price mapping asset->number
 */
const parseRWADynamicOracleResults = (chainId: number, data: any[], assetName: string[]) => {
  switch (chainId) {
    case SupportedChainId.MANTLE: {
      let prices = Object.assign(
        {},
        ...data.map((entry, index) => {
          return {
            [assetName[index]]: parseRawAmount(entry?.toString() ?? '0', 18)
          }
        })
      )
      return prices
    }
    default: {
      return {}
    }
  }
}