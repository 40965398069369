import { createAction } from '@reduxjs/toolkit'
import { Field, MarginTradeType, QuickActionType, SupportedAssets } from 'types/1delta';

export const resetSelectionState = createAction<void>('easymode/resetSelectionState')
export const selectEasyModeCurrency = createAction<{ field: Field; currencyId: string }>('easymode/selectEasyModeCurrency')
export const switchCurrencies = createAction<void>('easymode/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('easymode/typeInput')
export const setRecipient = createAction<{ recipient: string | null }>('easymode/setRecipient')
export const setEasyModeInteraction = createAction<{ marginTradeType: MarginTradeType }>('easymode/setEasyModeInteraction')
export const setQuickActionType = createAction<{ quickActionType: QuickActionType }>('easymode/setQuickActionType')

export const setBaseCurrency = createAction<{ asset: SupportedAssets }>('easymode/setBaseCurrency')
export const replaceEasyModeState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
}>('easymode/replaceEasyModeState')