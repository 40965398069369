import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export interface Account1DeltaUserResponse {
  chainId: number
  accounts: {
    [index: number]: {
      accountAddress: string
      accountOwner: string
      accountName: string
      creationTimestamp: number
    }
  }
}

export interface DeltaUserQueryParams {
  chainId: number
  account?: string
}

export const fetch1DeltaUserAccountDataAsync: AsyncThunk<Account1DeltaUserResponse, DeltaUserQueryParams, any> =
  createAsyncThunk<Account1DeltaUserResponse, DeltaUserQueryParams>(
    '1delta/fetch1DeltaUserAccountDataAsync',

    async ({ chainId, account }) => {
      if (!account) return { accounts: {}, chainId: 0 }

      return { accounts: {}, chainId }
    }
  )
