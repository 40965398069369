import { HStack } from '@chakra-ui/react'

interface OverlappingLogosProps {
  children: React.ReactNode
}

export const OverlappingLogos: React.FC<OverlappingLogosProps> = ({ children }) => {
  return (
    <HStack
      gap="0"
      css={{
        '& > *:not(:first-of-type)': {
          marginLeft: '-0.25rem',
        },
      }}
      w={'max-content'}
    >
      {children}
    </HStack>
  )
}
