import { Menu, MenuList, VStack } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { NavigationButton } from 'components/Button/Action/NavigationButton'
import { HamburgerButton } from 'components/Button/Action/HamburgerButton'
import { isProductionEnv } from 'utils/env'
import { NewTag } from 'components/Pills/NewTag'
import { getTypography } from 'theme/typographies'
import { trackPageViewed } from 'utils/analytics'
import { pages } from 'components/Navbar'

interface NavigationMenuProps {
  pathname: string
  selectPath: (path: string) => void
}

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ pathname, selectPath }) => {
  const handleChangePage = (path: string, onClose: () => void) => () => {
    trackPageViewed(pages[path])
    selectPath(path)
    onClose()
  }

  return (
    <Menu>
      {({ isOpen, onClose }) => (
        <>
          <HamburgerButton isOpen={isOpen} />
          <MenuList
            minWidth="120px"
            padding="1rem"
            border="none"
            borderRadius={'0.5rem'}
            bg={validateColor('Surface/Surface-primary')}
            boxShadow="dark"
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
          >
            <VStack alignItems={'flex-start'} w="100%">
              <NavigationButton
                selected={pathname.includes('/markets')}
                onClick={handleChangePage('/markets', onClose)}
              >
                <NewTag
                  text="Markets"
                  style={
                    pathname.includes('/markets')
                      ? getTypography('Typography/Body-Labels/Bold/Body-Label')
                      : getTypography('Typography/Body-Labels/Normal/Body')
                  }
                />
              </NavigationButton>
              <NavigationButton selected={pathname.includes('/swap')} onClick={handleChangePage('/swap', onClose)}>
                Swap
              </NavigationButton>
              <NavigationButton selected={pathname.includes('/margin')} onClick={handleChangePage('/margin', onClose)}>
                Margin
              </NavigationButton>
              {!isProductionEnv() && (
                <NavigationButton
                  selected={pathname.includes('/easymode')}
                  onClick={handleChangePage('/easymode', onClose)}
                >
                  EasyMode
                </NavigationButton>
              )}
              <NavigationButton selected={pathname.includes('/yields')} onClick={handleChangePage('/yields', onClose)}>
                Yields
              </NavigationButton>
              <NavigationButton
                selected={pathname.includes('/bridge')}
                onClick={handleChangePage('/bridge', onClose)}
              >
                Bridge
              </NavigationButton>
            </VStack>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
