import React, { PropsWithChildren } from 'react'
import { Code, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { PageContainerWithAnimation } from 'components/PageLayout'
import { getTypography } from 'theme/typographies'
import { PrimaryLargeButton } from 'components/Button/Primary'
import { default as somethingWentWrongImage } from 'assets/images/something-went-wrong.svg'
import { SecondaryLargeButton } from 'components/Button/Secondary'
import { CopyButton } from 'components/Button/Action/CopyButton'

type ErrorBoundaryState = {
  error: Error | null
}

async function updateServiceWorker(): Promise<ServiceWorkerRegistration> {
  const ready = await navigator.serviceWorker.ready
  // the return type of update is incorrectly typed as Promise<void>. See
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
  return ready.update() as unknown as Promise<ServiceWorkerRegistration>
}

export default class ErrorBoundary extends React.Component<PropsWithChildren<unknown>, ErrorBoundaryState> {
  constructor(props: PropsWithChildren<ErrorBoundaryState>) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    updateServiceWorker()
      .then(async (registration) => {
        // We want to refresh only if we detect a new service worker is waiting to be activated.
        // See details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
        if (registration?.waiting) {
          await registration.unregister()

          // Makes Workbox call skipWaiting(). For more info on skipWaiting see: https://developer.chrome.com/docs/workbox/handling-service-worker-updates/
          registration.waiting.postMessage({ type: 'SKIP_WAITING' })

          // Once the service worker is unregistered, we can reload the page to let
          // the browser download a fresh copy of our app (invalidating the cache)
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error('Failed to update service worker', error)
      })
    return { error }
  }

  render() {
    const { error } = this.state

    if (error) {
      return (
        <PageContainerWithAnimation margin={0} mt="5rem">
          <VStack w="100%" gap="2.5rem" maxW="60rem" margin="auto" alignItems="center" textAlign="center">
            <Image src={somethingWentWrongImage} alt="Something went wrong" w="15.155rem" h="15.986rem" />
            <VStack gap="1.5rem">
              <Text
                style={getTypography('Typography/Captions/Bold/Caption 1')}
                color={validateColor('Navigation/Navigation-text-active')}
              >
                Something went wrong!
              </Text>
              <Text
                style={getTypography('Typography/Captions/Normal/Caption 1')}
                color={validateColor('Text/Lables/Label-text-default')}
              >
                The application has encountered a problem. We apologize for the inconvenience.
              </Text>
            </VStack>
            <VStack
              w="100%"
              bg={validateColor('Surface/Surface-secondary')}
              border="none"
              borderRadius="0.25rem"
              p="1rem"
              gap="1rem"
            >
              <HStack justifyContent="center">
                <Text
                  style={getTypography('Typography/Small/Bold/Small 1')}
                  color={validateColor('Text/Headings & Titles/Title-text')}
                >
                  Details
                </Text>
                <CopyButton w="1.25rem" h="1.25rem" text={error.message} />
              </HStack>
              <Text
                style={getTypography('Typography/Small/Normal/Small 1')}
                color={validateColor('Text/Lables/Label-text-default')}
                as={Code}
                textAlign={'left'}
                bg="none"
              >
                {error.message}
              </Text>
            </VStack>
            <HStack gap="1.5rem">
              <SecondaryLargeButton
                onClick={() => {
                  window.location.href = '/'
                }}
              >
                Go to Home
              </SecondaryLargeButton>
              <PrimaryLargeButton
                onClick={() => {
                  window.location.reload()
                }}
              >
                Reload
              </PrimaryLargeButton>
            </HStack>
          </VStack>
        </PageContainerWithAnimation>
      )
    }

    return this.props.children
  }
}
