import { Button, ButtonProps } from '@chakra-ui/button'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'

interface NavigationButtonProps extends ButtonProps {
  selected: boolean
}

export const NavigationButton: React.FC<NavigationButtonProps> = ({ selected, ...props }) => {
  return (
    <Button
      h="auto"
      lineHeight={1}
      color={
        selected
          ? validateColor('Navigation/Navigation-text-active')
          : validateColor('Navigation/Navigation-text-inactive')
      }
      _hover={{
        color: selected
          ? validateColor('Navigation/Navigation-text-active')
          : validateColor('Navigation/Navigation-text-hover'),
        cursor: selected ? 'default' : 'pointer',
      }}
      style={
        selected
          ? getTypography('Typography/Body-Labels/Bold/Body-Label')
          : getTypography('Typography/Body-Labels/Normal/Body')
      }
      background={'transparent'}
      _active={{
        backgroundColor: 'transparent',
      }}
      padding={0}
      onClick={selected ? undefined : props.onClick}
      {...props}
    />
  )
}
