import {
  HStack,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Image,
} from '@chakra-ui/react'
import { useThemeColors, validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { SortConfig } from './useSortConfig'
import { useUserTheme } from 'state/user/hooks'
import { filledArrowDownIcon } from 'constants/1delta'

export interface TableHeaderCellProps extends TableColumnHeaderProps {
  sort?: {
    requestSort: (key: any) => void
    key: string
    sortConfig: SortConfig<any> | null
  }
}

export const TableHeaderCell = (props: TableHeaderCellProps) => {
  const theme = useUserTheme()
  const arrow = filledArrowDownIcon[theme]
  return (
    <Th
      style={getTypography('Typography/Small/Normal/Small 1')}
      background={validateColor('Surface/Surface-primary')}
      color={validateColor('Text/Lables/Label-text-default')}
      textTransform={'none'}
      fontFamily={'unset'}
      letterSpacing={'unset'}
      borderBottom="none"
      padding="0"
      px={'1rem'}
      height="42px"
      cursor={props.sort ? 'pointer' : 'default'}
      transition={'all 0.2s ease-in-out'}
      onClick={props.sort ? () => props.sort?.requestSort(props.sort.key) : undefined}
      {...props}
    >
      <HStack w="auto" justifyContent={props.justifyContent} gap="0.1rem">
        <Text>{props.children}</Text>
        {props.sort && props.sort?.sortConfig?.key !== props.sort?.key && (
          <Image src={arrow} w="1.5rem" h="1.5rem" opacity={0.7} mr="-7px" />
        )}
        {props.sort && props.sort?.sortConfig?.key === props.sort?.key && (
          <Image
            src={arrow}
            w="1.5rem"
            h="1.5rem"
            transform={props.sort?.sortConfig?.direction === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)'}
            filter="brightness(2)"
            transition={'transform 0.2s ease'}
            mr="-7px"
          />
        )}
      </HStack>
    </Th>
  )
}

export const TableCell = (props: TableColumnHeaderProps) => {
  return (
    <Td
      style={getTypography('Typography/Body-Labels/Normal/Body')}
      color={validateColor('Text/Lables/Label-text-default')}
      border="none"
      padding={'0.5rem 1rem'}
      transition={'background-color 0.2s ease'}
      {...props}
    />
  )
}

export const scrollbarCssProps = (scrollbarBgColor: string) => {
  return {
    '&::-webkit-scrollbar': {
      height: '0.5rem',
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: scrollbarBgColor,
      borderRadius: '0.25rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '0.25rem',
    },
  }
}

interface CustomTableProps extends TableContainerProps {
  header: React.ReactNode
  body: React.ReactNode
}

export const CustomTable: React.FC<CustomTableProps> = ({ header, body, ...props }) => {
  const [scrollbarBgColor] = useThemeColors(['Surface/Surface-secondary'])
  return (
    <TableContainer
      border="1px solid"
      borderColor={validateColor('Borders/Border-secondary')}
      borderRadius={'0.5rem'}
      backgroundColor={validateColor('Surface/Page-background')}
      w="100%"
      css={scrollbarCssProps(scrollbarBgColor)}
      {...props}
    >
      <Table variant="simple">
        <Thead>{header}</Thead>
        <Tbody
          css={{
            '& tr:last-of-type td': {
              borderBottom: 'none',
            },
          }}
        >
          {body}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
