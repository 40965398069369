import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { SupportedAssets, toOracleKey } from 'types/1delta'
import _ from 'lodash'
import { fetchDexscreenerPair } from './fetchDexscreenerPairs'
import { useChainId } from 'state/globalNetwork/hooks'
import { Lender } from 'types/lenderData/base'
import { SupportedChainId } from 'constants/chains'
import { addressesTokens } from 'hooks/1delta/addressesTokens'
import { LenderRewardsMap, NumberMap } from './reducer'

export function usePrices(assets: (SupportedAssets | undefined | string)[]): number[] {
  const prices = useAppSelector((state) => state.oracles.live)
  return useMemo(() => assets.map((a) => (a ? prices[toOracleKey(a)] : 0)), [prices, assets])
}

export function usePriceAndRef(asset: SupportedAssets | string): { price: number; refPrice: number } {
  const price = useAppSelector((state) => state.oracles.live[toOracleKey(asset)])
  const refPrice = useAppSelector((state) => state.oracles.ref[toOracleKey(asset)])
  return { price, refPrice }
}

/**
 * Get prices from 24h ago
 * @param assets assets array
 * @returns asset->price mapping
 */
export const use24HPrices = (assets: SupportedAssets[]): { [a: string]: number } => {
  const hist = useAppSelector((state) => state.oracles.history)
  if (!hist) return {}
  return Object.assign(
    {},
    ...assets.map((a) => {
      return { [a]: hist[toOracleKey(a)] }
    })
  )
}

export const useGetDexscreenerPairs = (chainId: number) => {
  const dexscreenerPairs = useAppSelector((state) => state.oracles.dexscreenerPairs)
  return useMemo(() => dexscreenerPairs[chainId] || [], [dexscreenerPairs, chainId])
}

/**
 * Gets a dexscreener pair address. If none is found in the state, we just fetch it
 * @param chainId network
 * @param tokenAddress token ref
 * @returns pair address
 */
export const useFetchDexscreenerPair = (chainId: number, tokenAddress: string) => {
  const dispatch = useAppDispatch()
  const dexscreenerPairs = useAppSelector((state) => state.oracles.dexscreenerPairs)
  const pair = dexscreenerPairs?.[chainId]?.[tokenAddress.toLowerCase()]
  useEffect(() => {
    if (tokenAddress && chainId) {
      if (!pair) {
        dispatch(fetchDexscreenerPair({ chainId, tokenAddress }))
      }
    }
  }, [pair?.pairAddress, dispatch, chainId, tokenAddress])
}

/**
 * Gets a dexscreener pair address. If none is found in the state, we just fetch it
 * @param chainId network
 * @param tokenAddress token ref
 * @returns pair address
 */
export const useGetDexscreenerPair = (chainId: number, tokenAddress: string) => {
  const dexscreenerPairs = useAppSelector((state) => state.oracles.dexscreenerPairs)
  return dexscreenerPairs?.[chainId]?.[tokenAddress?.toLowerCase()]
}

/**
 * Return prices from an asset array as a dictionary asset->number
 * @param assets asset array
 * @returns asset->number mapping
 */
export function usePricesDict(assets: SupportedAssets[]): { [a: string]: number } {
  const prices = useAppSelector((state) => state.oracles.live)
  return useMemo(
    () =>
      Object.assign(
        {},
        ...assets.map((a) => {
          return { [a]: prices[toOracleKey(a)] }
        })
      ),
    [prices, assets]
  )
}

/**
 * Fetches prices from all sources
 * If an address is provided, we use dexscreener, if an asset enum is provided, we use oracles
 * @param assetsOrAddresses
 * @returns price array
 */
export function useGeneralPrices(assetsOrAddresses: (SupportedAssets | string | undefined)[]): number[] {
  const chainId = useChainId()
  const prices = useAppSelector((state) => state.oracles.live)
  const dexscreenerPairs = useAppSelector((state) => state.oracles.dexscreenerPairs)?.[chainId]
  return assetsOrAddresses.map((a) =>
    a ? (a.slice(0, 2) === '0x' ? dexscreenerPairs?.[a.toLowerCase()]?.priceUsd ?? 0 : prices[toOracleKey(a)]) : 0
  )
}


/**
 * Fetches prices from all sources as price dictionary
 * @returns price mapping lowerCaseAddress -> price number
 */
export function useGeneralPricesDict(): { [a: string]: number } {
  const chainId = useChainId()
  const prices = useAppSelector((state) => state.oracles.live)
  const dexscreenerPairs = useAppSelector((state) => state.oracles.dexscreenerPairs)?.[chainId]
  return useMemo(() => {
    let priceList: { [a: string]: number } = {}
    Object.keys(prices).forEach(k => {
      const key = addressesTokens[k]?.[chainId]?.toLowerCase()
      if (key) priceList[key] = prices[k]
    })

    Object.keys(dexscreenerPairs ?? {}).forEach(p => {
      priceList[p?.toLowerCase()] = dexscreenerPairs[p].priceUsd ?? 0
    })
    return priceList
  }, [prices, dexscreenerPairs]
  )
}


export function usePricesLoaded(chainId: number): boolean {
  return useAppSelector(
    (state) =>
      (chainId === SupportedChainId.POLYGON || state.oracles.specificLiveLoaded) &&
      state.oracles.baseLiveLoaded &&
      state.oracles.apiLoaded
  )
}

export function useStakingIsLoaded(): boolean {
  return useAppSelector((state) => state.oracles.additionalYields.loaded)
}

export function useIntrinsicYields(): NumberMap {
  return useAppSelector((state) => state.oracles.additionalYields.intrinsicYields)
}


export function useLenderRewards(lender: Lender, chainId: number): LenderRewardsMap {
  return useAppSelector((state) => state.oracles.additionalYields.lenderRewards[chainId]?.[lender.toUpperCase()]) ?? {}
}

export function useTxHistory(lender: Lender, chainId: number) {
  return useAppSelector((state) => state.oracles.txns[chainId]?.[lender]) ?? []
}
