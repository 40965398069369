import { Button, ButtonProps, Image } from '@chakra-ui/react'
import { closeIcon } from 'constants/1delta'
import { useUserTheme } from 'state/user/hooks'
import { validateColor } from 'theme/colors'

export const ModalCloseButton: React.FC<ButtonProps> = (props) => {
  const theme = useUserTheme()
  const closeImg = closeIcon[theme]

  return (
    <Button
      backgroundColor="transparent"
      w={'2rem'}
      h={'2rem'}
      minH={'unset'}
      minW={'unset'}
      _hover={{
        backgroundColor: validateColor('Surface/Surface-tertiary'),
      }}
      _active={{
        backgroundColor: validateColor('Surface/Surface-tertiary'),
      }}
      transition={'background-color 0.2s ease-in-out'}
      p="0"
      {...props}
    >
      <Image src={closeImg} alt="Close Icon" w={'1rem'} h={'1rem'} pl="1px" />
    </Button>
  )
}
