import { SupportedChainId } from "constants/chains"
import { getFallbackRpcUrlByIndex, getRpcUrlByIndex } from "constants/providers"
import { createPublicClient, http } from "viem"

import { bsc, mainnet, polygon, mantle, base, blast, avalanche, linea, arbitrum, taiko } from 'viem/chains'

export const getViemProvider = ({ chainId = 1, id = 0 }: { chainId?: number, id?: number }) => {
  return createPublicClient({
    chain: getChain({ chainId }),
    transport: http(getRpcUrlByIndex(chainId, id)),
  })
}

// get provider using fallback URLS if exists
export const getFallbackViemProvider = ({ chainId = 1, id = 0 }: { chainId?: number, id?: number }) => {
  return createPublicClient({
    chain: getChain({ chainId }),
    transport: http(getFallbackRpcUrlByIndex(chainId, id)),
  })
}


const getChain = ({ chainId }: { chainId?: number }) => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return mainnet
    case SupportedChainId.BSC:
      return bsc
    case SupportedChainId.MANTLE:
      return mantle
    case SupportedChainId.POLYGON:
      return polygon
    case SupportedChainId.BASE:
      return base
    case SupportedChainId.BLAST:
      return blast
    case SupportedChainId.ARBITRUM_ONE:
      return arbitrum
    case SupportedChainId.AVALANCHE:
      return avalanche
    case SupportedChainId.LINEA:
      return linea
    case SupportedChainId.TAIKO:
      return taiko
    default:
      return bsc
  }
}