import { addressesTokens } from "hooks/1delta/addressesTokens";
import { fetchDefillamaData } from "../defillama/fetchDefillamaData";
import { AggregatorResponse, LENDLE_SUBGRAPH_URL_BASE, PRICES_HIST, PriceResponse } from "./queries";
import { getLenderAssets } from "constants/getAssets";
import { Lender } from "types/lenderData/base";
import { SupportedAssets } from "types/1delta";
import { SupportedChainId } from "constants/chains";

/**
 * Fetches all prices (current and directly before the refTime)
 * @param refTime unix timestamp for historical prices
 * @returns {prices, histPrices} dictionary
 */
export const fetchAllLendlePrices = async (refTime: number) => {
  const assets = getLenderAssets(SupportedChainId.MANTLE, Lender.LENDLE)
  const lendleTokenAddresses = Object.entries(addressesTokens)
    .filter(([asset]) => assets.includes(asset as SupportedAssets))
    .map(([asset, chainAddresses]) => ({
      name: asset === SupportedAssets.USDCE ? SupportedAssets.USDC : asset,
      address: chainAddresses[SupportedChainId.MANTLE].toLowerCase() as `0x${string}`,
    }))

  const query = PRICES_HIST(lendleTokenAddresses, refTime)
  const requestBody = {
    query,
    variables: {},
  };
  try {
    const response = await fetch(LENDLE_SUBGRAPH_URL_BASE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw new Error(`Network error: ${response.status} - ${response.statusText}`);
    }

    const data: any = await response.json();
    let rawPrices: PriceResponse = data.data
    let prices: AggregatorResponse = {}
    let histPrices: AggregatorResponse = {}

    for (let price of rawPrices.markets) {
      const key = price.inputToken.symbol.toUpperCase()
      let targetKey = key
      if (key == 'USDC') targetKey = 'USDC.E'
      prices[targetKey] = Number(price.inputTokenPriceUSD)
      histPrices[targetKey] = Number((rawPrices as any)[key]?.[0]?.inputTokenPriceUSD)
    }

    return {
      prices,
      histPrices
    };
  } catch (error) {
    console.error('Error fetching transaction history:', error);
    const { prices } = await fetchDefillamaData()
    return {
      prices,
      histPrices: {}
    };
  }
};

