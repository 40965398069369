import { MarginTradeType, QuickActionType } from "types/1delta"
import { Lender } from "types/lenderData/base";

// Use the (Object) (Verb) format for event names. Like "Song Played" or "Page Viewed".
export enum Event {
  WALLET_CONNECTED = 'Wallet Connected',
  WALLET_DISCONNECTED = 'Wallet Disconnected',
  ACCOUNT_SWITCHED = 'Account Switched',
  CHAIN_SELECTED = 'Chain Selected',
  LENDER_SELECTED = 'Lender Selected',
  BUTTON_CLICKED = 'Entity Clicked',
  QUICK_ACTION_EXECUTED = 'Quick Action Executed',
  TRADE_EXECUTED = 'Trade Executed',
  SWAP_EXECUTED = 'Swap Executed',
  TRANSACTION_ERROR = 'Transaction Error',
  UTM_TRACKED = 'UTM Tracked',
  PAGE_VIEWED = 'Page Viewed',
  MARKETS_VIEW_SELECTED = 'Markets View Selected',
  MARKETS_FILTER_SELECTED = 'Markets Filter Selected',
  MARKETS_INPUT_TYPED = 'Markets Input Typed',
  ROW_ACTION_CLICKED = 'Row Action Clicked',
  CUSTOM_TOKEN_ADDED = 'Custom Token Added',
  EMODE_SWITCHED = 'EMode Switched',
  REWARDS_CLAIMED = 'Rewards Claimed',
  REWARDS_VESTED = 'Rewards Vested',
}

// for the PAGE_VIEWED event, we can track the page name
export enum Page {
  MARKETS = 'Markets',
  SWAP = 'Swap',
  MARGIN = 'Margin',
  YIELDS = 'Yields',
  BRIDGE = 'Bridge',
  EASYMODE = 'EasyMode',
}

// Events can have properties
export enum Property {
  CHAIN = 'Chain',
  LENDER = 'Lender',
  TX_HASH = 'Tx Hash',
  QUICK_ACTION_TYPE = 'Quick Action Type',
  TRADE_TYPE = 'Trade Type',
  IS_DIRECT = 'Is Direct',
  TOKEN_IN = 'Token In',
  TOKEN_OUT = 'Token Out',
  AMOUNT_IN = 'Amount In',
  AMOUNT_OUT = 'Amount Out',
  AMOUNT_IN_USD = 'Amount In USD',
  AMOUNT_OUT_USD = 'Amount Out USD',
  BLOCK_NUMBER = 'Block Number',
  ERROR_MESSAGE = 'Error Message',
  TRADE = 'Trade',
  PAGE = 'Page',
  MARKETS_VIEW = 'Markets View',
  MARKETS_FILTER = 'Markets Filter',
  SEARCHED_QUERY = 'Searched Query',
  ROW_ACTION = 'Row Action',
  TOKEN_NAME = 'Token Name',
  TOKEN_SYMBOL = 'Token Symbol',
  TOKEN_ADDRESS = 'Token Address',
  SLIPPAGE = 'Slippage',
  AGGREGATOR = 'Aggregator',
  EMODE_ID = 'EMode ID',
  EMODE_LABEL = 'EMode Label',
}

export type QuickActionEvent = {
  [Property.TX_HASH]: string;
  [Property.QUICK_ACTION_TYPE]: QuickActionType;
  [Property.IS_DIRECT]: boolean;
  [Property.LENDER]: Lender;
  [Property.TOKEN_IN]: string;
  [Property.AMOUNT_IN]: string;
  [Property.AMOUNT_IN_USD]: string;
  [Property.TOKEN_OUT]?: string;
  [Property.AMOUNT_OUT]?: string;
  [Property.AMOUNT_OUT_USD]?: string;
}

export type TradeEvent = {
  [Property.TX_HASH]: string;
  [Property.TRADE_TYPE]: MarginTradeType | "Swap";
  [Property.LENDER]?: Lender;
  [Property.TOKEN_IN]: string;
  [Property.AMOUNT_IN]: string;
  [Property.AMOUNT_IN_USD]: string;
  [Property.TOKEN_OUT]: string;
  [Property.AMOUNT_OUT]: string;
  [Property.AMOUNT_OUT_USD]: string;
  [Property.SLIPPAGE]: string;
  [Property.AGGREGATOR]?: string;
}

export type TradeErrorEvent = {
  [Property.BLOCK_NUMBER]: string;
  [Property.TRADE_TYPE]: MarginTradeType | QuickActionType | "Swap";
  [Property.LENDER]?: Lender;
  [Property.TRADE]: string;
  [Property.ERROR_MESSAGE]: string;
}

export type EModeEvent = {
  [Property.TX_HASH]: string;
  [Property.LENDER]: Lender;
  [Property.EMODE_ID]: string;
  [Property.EMODE_LABEL]: string;
}

export type VestedRewardsEvent = {
  [Property.TX_HASH]: string;
  [Property.LENDER]: Lender;
}

export type ClaimedRewardsEvent = {
  [Property.TX_HASH]: string;
  [Property.LENDER]: Lender;
}
