import { createReducer } from '@reduxjs/toolkit'
import { getAvailableChainIds } from 'constants/chains'
import { fetchTokenList } from './actions'
import { Token } from '@1delta/base-sdk'

interface GeneralTokenListEntry {
  chainId: number
  name: string
  address: string
  symbol: string
  decimals: number
  logoURI?: string
}

export type DeltaTokenList = { [addr: string]: GeneralTokenListEntry }
export type VersionedDeltaTokenList = {
  list: DeltaTokenList,
  mainTokens: string[]
  version: string
}
export type TypedTokenList = { [address: string]: Token }

export interface ListsState {
  readonly byChain: {
    readonly [chain: number]: {
      readonly currentList: VersionedDeltaTokenList | null
      readonly pendingUpdate: VersionedDeltaTokenList | null
      readonly loadingRequestId: string | null
      readonly error: string | null
    }
  }
}

type ListState = ListsState['byChain'][number]

const NEW_LIST_STATE: ListState = {
  error: null,
  currentList: null,
  loadingRequestId: null,
  pendingUpdate: null,
}

type Mutable<T> = { -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : T[P] }

const initialState: ListsState = {
  byChain: {
    ...getAvailableChainIds().reduce<Mutable<ListsState['byChain']>>((memo, chain) => {
      memo[chain] = NEW_LIST_STATE
      return memo
    }, {}),
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchTokenList.pending, (state, { payload: { requestId, chain } }) => {
      if (!state.byChain) state.byChain = {}
      if (!state.byChain[chain]) state.byChain[chain] = NEW_LIST_STATE
      const current = state.byChain?.[chain]?.currentList ?? null
      const pendingUpdate = state.byChain?.[chain]?.pendingUpdate ?? null

      state.byChain[chain] = {
        currentList: current,
        pendingUpdate,
        loadingRequestId: requestId,
        error: null,
      }
    })
    .addCase(fetchTokenList.fulfilled, (state, { payload: { requestId, tokenList, chain } }) => {
      const current = state.byChain[chain]?.currentList
      const loadingRequestId = state.byChain[chain]?.loadingRequestId

      // no-op if update does nothing
      if (current) {
        const upgrade = Number(current?.version ?? 0) < Number(tokenList.version)
        if (!upgrade) return;

        if (loadingRequestId === null || loadingRequestId === requestId) {
          state.byChain[chain] = {
            currentList: current,
            pendingUpdate: tokenList,
            loadingRequestId: null,
            error: null,
          }
        }
      } else {
        state.byChain[chain] = {
          currentList: tokenList,
          pendingUpdate: null,
          loadingRequestId: null,
          error: null,
        }
      }
    })
    .addCase(fetchTokenList.rejected, (state, { payload: { chain, requestId, errorMessage } }) => {
      if (state.byChain[chain]?.loadingRequestId !== requestId) {
        // no-op since it's not the latest request
        return
      }

      state.byChain[chain] = {
        currentList: state.byChain[chain].currentList ? state.byChain[chain].currentList : null,
        pendingUpdate: null,
        loadingRequestId: null,
        error: errorMessage,
      }
    })
)
