import { createSlice } from '@reduxjs/toolkit'

export enum ApplicationModal {
  ADDRESS_CLAIM,
  BLOCKED_ACCOUNT,
  DELEGATE,
  CLAIM_POPUP,
  MENU,
  NETWORK_SELECTOR,
  POOL_OVERVIEW_OPTIONS,
  PRIVACY_POLICY,
  SELF_CLAIM,
  SETTINGS,
  VOTE,
  WALLET,
  WALLET_DROPDOWN,
  QUEUE,
  EXECUTE,
  TIME_SELECTOR,
  SHARE,
  NETWORK_FILTER,
  FEATURE_FLAGS,
}

export interface ApplicationState {
  readonly chainId: number | null
  readonly openModal: ApplicationModal | null
  readonly walletMenuOpen: boolean
}

const initialState: ApplicationState = {
  chainId: null,
  openModal: null,
  walletMenuOpen: false,
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateChainId(state, action) {
      const { chainId } = action.payload
      state.chainId = chainId
    },
    setOpenModal(state, action) {
      state.openModal = action.payload
    },
    onToggleWalletMenu(state) {
      state.walletMenuOpen = !state.walletMenuOpen
    },
    onCloseWalletMenu(state) {
      state.walletMenuOpen = false
    }
  },
})

export const { updateChainId, setOpenModal, onToggleWalletMenu } = applicationSlice.actions
export default applicationSlice.reducer
