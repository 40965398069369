import { useSortConfig } from "components/Table/useSortConfig"
import { ETHEREUM_CHAINS, TEN, TOKEN_META } from "constants/1delta"
import { SupportedChainId } from "constants/chains"
import { getLenderAssets } from "constants/getAssets"
import { ethers } from "ethers"
import { formatEther } from "ethers/lib/utils"
import { lenderToReduxSlice } from "hooks/lenders"
import { useAllTokens } from "hooks/Tokens"
import { useEffect, useMemo, useState } from "react"
import { useGetSelectedSubAccountData } from "state/1delta/hooks"
import { useNativeBalance } from "state/globalNetwork/hooks"
import { useAppSelector } from "state/hooks"
import { useGeneralPricesDict, useGetDexscreenerPairs, usePrices } from "state/oracles/hooks"
import { NATIVE_ASSET, SupportedAssets, WRAPPED_NATIVE_ASSET } from "types/1delta"
import { Lender } from "types/lenderData/base"
import { useSubscribeToBalances } from "./useSubscribeToCurrencies"
import { CurrencyAmount } from "@1delta/base-sdk"

export interface WalletAssetData {
  hasPosition: boolean
  price: number;
  hasBorrowPosition: boolean
  assetId: SupportedAssets;
  walletBalance: number
  walletBalanceUSD: number
}

/**
 * Prepares wallet asset data based on state
 * Approach: we filter native from the lender and add it later with wrapped native data
 * overriden by native symbol and balance
 * @param chainId chainId
 * @param account user wallet address
 * @returns wallet balances per asset and total wallet balacne in USD
 */
export const useWalletAssetData = (
  chainId: number,
  account: string | undefined,
  lender = Lender.AAVE_V3
): {
  walletData: WalletAssetData[],
  totalWalletBalanceUSD: number,
  hasNoBalances: boolean,
  balancesLoading: boolean
} => {
  const selectedAccount = useGetSelectedSubAccountData(lender, chainId, account)
  const reduxSlice = lenderToReduxSlice(lender)


  const nativeAsset = NATIVE_ASSET[chainId]
  const wrappedNativeAsset = WRAPPED_NATIVE_ASSET[chainId]

  const assets: SupportedAssets[] = useMemo(() => getLenderAssets(chainId, lender).filter(a => a != nativeAsset), [chainId, lender, nativeAsset])

  const lenderDataUser: { [a: string]: any } = useAppSelector(
    state => state[reduxSlice]?.[chainId]?.userData?.[selectedAccount?.accountId ?? '']
  )

  const userAssetsFlags: { [a: string]: [boolean, boolean] } = useMemo(
    () => Object.assign({}, ...assets.map((a: any) => {
      {
        return { [a]: [lenderDataUser?.[a]?.deposits > 0, lenderDataUser?.[a]?.debt > 0 || lenderDataUser?.[a]?.debtStable > 0] }
      }
    }))
    , [lenderDataUser]
  )

  const walletBalancesDict = useAppSelector(state => state.globalNetwork.networkData[chainId]?.balances)

  const native = useNativeBalance()
  const prices = usePrices(assets)
  const [wrappedNativePrice] = usePrices([wrappedNativeAsset])
  return useMemo(() => {
    const walletData: WalletAssetData[] = []
    let totalWalletBalanceUSD = 0
    const hasAccount = Boolean(account)
    if (!hasAccount) return {
      walletData: [],
      totalWalletBalanceUSD: 0,
      hasNoBalances: true,
      balancesLoading: false
    }
    for (let i = 0; i < assets.length; i++) {
      const price = prices[i]
      const asset = assets[i]
      const rawBalance = walletBalancesDict?.[asset]
      const decimals = TOKEN_META[asset]?.decimals ?? 18

      // assign balance
      const walletBalance = Number(
        formatEther(
          ethers.BigNumber.from(rawBalance ?? '0').mul(
            TEN.pow(18 - (decimals ?? 0))
          )
        )
      )
      if (walletBalance > 0) {
        const walletBalanceUSD = walletBalance * price;
        totalWalletBalanceUSD += walletBalanceUSD
        const user = userAssetsFlags[asset]
        walletData.push(
          {
            price,
            assetId: asset,
            hasPosition: hasAccount && Boolean(user?.[0]),
            walletBalance,
            walletBalanceUSD,
            hasBorrowPosition: Boolean(user?.[0]),
          }
        )
      }
    }

    const nativeBalance = Number(formatEther(native ?? '0'))
    if (nativeBalance > 0)
      // add native balance if positive
      walletData.unshift(
        {
          ...walletData[wrappedNativeAsset],
          assetId: nativeAsset,
          walletBalance: nativeBalance,
          walletBalanceUSD: nativeBalance * wrappedNativePrice
        }
      )

    return {
      walletData,
      totalWalletBalanceUSD,
      balancesLoading: Boolean(
        account && !walletData.filter(a => a.assetId === NATIVE_ASSET[chainId])[0]
      ),
      hasNoBalances: Boolean(
        account && nativeBalance === 0 &&
        walletData.filter(a => a.walletBalance > 0).length === 0
      )

    }
  },
    [
      chainId,
      account,
      userAssetsFlags
    ]
  )
}

/**
 * Prepares wallet asset data based on state
 * @param chainId chainId
 * @param account user wallet address
 * @returns wallet balances per asset and total wallet balacne in USD
 */
export const useAllWalletAssetData = (chainId: number, account: string | undefined): {
  walletData: WalletAssetData[],
  totalWalletBalanceUSD: number
} => {
  const tokens = useAllTokens()
  useSubscribeToBalances(chainId, Object.values(tokens))
  const walletBalancesDict = useAppSelector(state => state.globalNetwork.networkData[chainId]?.subscribedData)

  const native = useNativeBalance()
  const prices = useGeneralPricesDict()
  const wrappedNativeAsset = WRAPPED_NATIVE_ASSET[chainId]
  const [wrappedNativePrice] = usePrices([wrappedNativeAsset])
  const [repeater, setRepeater] = useState(0)
  return useMemo(() => {
    const nativeAsset = NATIVE_ASSET[chainId]
    const walletData: WalletAssetData[] = []
    let totalWalletBalanceUSD = 0
    const hasAccount = Boolean(account)
    if (!hasAccount) return { walletData: [], totalWalletBalanceUSD: 0 }
    for (const tokenAddress in tokens) {
      const assetKey = tokenAddress.toLowerCase()
      const price = prices[assetKey]
      const token = tokens[assetKey]
      const rawBalance = walletBalancesDict?.[assetKey]?.balance ?? '0'

      const walletBalance = hasAccount ?
        (ETHEREUM_CHAINS.includes(chainId) ? assetKey === SupportedAssets.ETH :
          chainId === SupportedChainId.MANTLE ? assetKey === SupportedAssets.MNT : assetKey === SupportedAssets.MATIC) ?
          Number(formatEther(native ?? '0')) :
          Number(
            CurrencyAmount.fromRawAmount(token, rawBalance)?.toExact() ?? 0
          ) : 0
      const walletBalanceU = walletBalance * price;
      totalWalletBalanceUSD += walletBalanceU
      walletData.push(
        {
          price,
          assetId: token.address as any,
          hasPosition: false,
          walletBalance,
          walletBalanceUSD: walletBalanceU,
          hasBorrowPosition: false,
        }
      )
    }
    const nativeBalance = Number(formatEther(native ?? '0'))
    if (nativeBalance > 0)
      // add native balance if positive
      walletData.unshift(
        {
          ...walletData[wrappedNativeAsset],
          assetId: nativeAsset,
          walletBalance: nativeBalance,
          walletBalanceUSD: nativeBalance * wrappedNativePrice
        }
      )
    setTimeout(() => setRepeater((prevState) => prevState + 1), 5000)
    return { walletData, totalWalletBalanceUSD }
  },
    [
      repeater,
      chainId,
      account
    ]
  )
}

export const useFetchAndSortWalletData = ({
  chainId,
  account
}: {
  chainId: number
  account: string | undefined
}) => {
  const dexscreenerPairs = useGetDexscreenerPairs(chainId)
  // get base wallet asset data
  const { walletData } = useAllWalletAssetData(chainId, account)
  // this has to be replaced with a proper fetcher, which returns these three values in a single call
  const [assetData, setAssetData] = useState<WalletAssetData[]>(walletData)

  const [dataIsLoading, setDataIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setDataIsLoading(false), 10000)
  }, [])

  // on chain change, we have to reset the data
  useEffect(() => {
    setAssetData([])
  }, [chainId])

  const [repeater, setRepeater] = useState(0)
  useEffect(() => {
    const keys = Object.keys(dexscreenerPairs)
    const tempData = walletData.map((data) => {
      if (keys.includes(data.assetId)) data.price = dexscreenerPairs[data.assetId].priceUsd || 0
      return data
    })
    setAssetData(tempData)
    // if we have the load flag active, we disable it here
    if (dataIsLoading)
      setDataIsLoading(false)

    const intervalId = setInterval(() => {
      setRepeater((prevState) => prevState + 1)
    }, 5000) // 5s

    return () => clearInterval(intervalId)
  }, [repeater, walletData])

  const { requestSort, sortedData, sortConfig } = useSortConfig<WalletAssetData>(assetData.filter((d) => d.walletBalance > 0))

  return {
    sortedData,
    requestSort,
    sortConfig,
    dataIsLoading,
    dexscreenerPairs
  }
}
