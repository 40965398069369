import { TOKEN_META } from "constants/1delta";
import { SupportedChainId } from "constants/chains";
import { addressToAsset } from "hooks/1delta/addressesTokens";
import { parseRawAmount } from "utils/tableUtils/prices";
import { AaveTypeTx } from "../utils/types";

export const POLLING_INTERVAL = 60000;

export const AAVE_SUBGRAPH_URL: { [k: number]: string } = {
  [SupportedChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v3-polygon',
}

export const AAVE_V3_REDEEMS_ROUTER = (router: string, hashes: string[]) => {
  return `
    query redeemUnderlyings($first: Int, $skip: Int)
    {
      redeemUnderlyings(
        first: $first
        skip: $skip
        where:{
          user: "${router.toLowerCase()}"
          txHash_in:["${hashes.join('","')}"]
        }
        orderBy: timestamp
        orderDirection: desc
      ){
        reserve {
          underlyingAsset
        }
        assetPriceUSD
        amount
        action
        timestamp
        txHash
      }
    }
  `
}

export const AAVE_V3_TRADES = (user: string, refTime: number) => {
  return `
  query Trades($first: Int, $skip: Int) {
    borrows(
      first: $first
      skip: $skip
      where:{
        user: "${user}"
        timestamp_gt: ${refTime}
      }
      orderBy: timestamp
      orderDirection: desc
    ){
      txHash,
      reserve {
        underlyingAsset
      }
      amount
      action
      assetPriceUSD
      timestamp
    }
    repays(
      first: $first
      skip: $skip
      where:{
        user: "${user}"
        timestamp_gt: ${refTime}
      }
      orderBy: timestamp
      orderDirection: desc
    ){
      txHash,
      reserve {
        underlyingAsset
      }
      amount
      action
      assetPriceUSD
      timestamp
    }
    redeemUnderlyings(
      first: $first
      skip: $skip
      where:{
        user: "${user}"
        timestamp_gt: ${refTime}
      }
      orderBy: timestamp
      orderDirection: desc
    ){
      txHash,
      reserve {
        underlyingAsset
      }
      amount
      action
      assetPriceUSD
      timestamp
    }
    supplies(
      first: $first
      skip: $skip
      where:{
        user: "${user}"
        timestamp_gt: ${refTime}
      }
      orderBy: timestamp
      orderDirection: desc
    ){
      txHash,
      reserve {
        underlyingAsset
      }
      amount
      action
      assetPriceUSD
      timestamp
    }
  }
  `
}
export interface AaveTxEntry {
  reserve: {
    underlyingAsset: string
  }
  txHash: string
  amount: string
  action: string
  assetPriceUSD: string
  timestamp: number
}

export interface AaveV3Trades {
  id: string
  borrows: AaveTxEntry[]
  redeemUnderlyings: AaveTxEntry[]
  repays: AaveTxEntry[]
  supplies: AaveTxEntry[]
}

export const parseAaveV3TradeEntry = (entry: AaveTxEntry, chainId: number, plus = true, type = ''): AaveTypeTx => {
  const asset = addressToAsset(chainId, entry.reserve.underlyingAsset)
  const amountPositive = parseRawAmount(entry.amount, TOKEN_META[asset].decimals)
  const amount = plus ? amountPositive : -amountPositive
  const price = Number(entry.assetPriceUSD)
  return {
    asset,
    type,
    amount,
    amountUSD: amount * price,
    timestamp: entry.timestamp,
    hash: entry.txHash
  }
}

// queries historical data
export const AAVE_V3_USER_RESERVES = (ref: number, assets: { [k: string]: string }, user: string, addressProvider: string, first = 5) => {
  return `
  query
  {
  ${Object.keys(assets).map((asset, i) =>
    `
  asset_${i}: userReserves(
    first: ${first}
    where:{
      lastUpdateTimestamp_lt: ${ref}
      id: "${user.toLowerCase() + assets[asset].toLowerCase() + addressProvider.toLowerCase()}"
    }
    orderBy: lastUpdateTimestamp
    orderDirection: desc
    ) {
    id
    lastUpdateTimestamp
    reserve {
      underlyingAsset
    }
    currentATokenBalance
    currentVariableDebt
    principalStableDebt
    currentStableDebt
    currentTotalDebt
  }
`).join(`
`)
    }
  }`
}

export interface AaveV3UserReserves {
  id: string
  lastUpdateTimestamp: number
  reserve: {
    underlyingAsset: string
  }
  currentATokenBalance: string
  currentVariableDebt: string
  principalStableDebt: string
  currentStableDebt: string
  currentTotalDebt: string
}


export interface AaveV3userReservesResponse {
  [asset: string]: AaveV3UserReserves[]
}

/** Fetches Aave's historical prices */
export const PRICE_HIST_MULTI = (ref: number, assets: string[]) => {
  return `
  query
  {
  ${assets.map((asset, i) =>
    `
  asset_${i}: priceHistoryItems(first:1, where:{
    timestamp_lt: ${ref + 10 * 3600}
    asset_in: ["${asset}"],
  }) {
  id
  asset {
    id
  }
  price
  timestamp
  }
`).join(`
`)
    }
  }`
}


/** Fetches Aave's historical prices */
export const PRICE_HIST = (ref: number, asset: string) => {
  return `
  query
  {
    priceHistoryItems(first:1, where:{
      timestamp_lt: ${ref + 10 * 3600}
      asset: "${asset}",
    }) 
    {
      id
      asset {
        id
      }
      price
      timestamp
    }
  }`
}
