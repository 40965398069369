import jazzicon from '@metamask/jazzicon'
import { useLayoutEffect, useMemo, useRef } from 'react'
import { useWeb3ReactWrapped } from 'hooks/web3'

export default function Identicon({ size }: { size?: number }) {
  const { account } = useWeb3ReactWrapped()
  const iconSize = size ? size : 16

  const icon = useMemo(() => account && jazzicon(iconSize, parseInt(account.slice(2, 10), 16)), [account, iconSize])
  const iconRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const current = iconRef.current
    if (icon) {
      current?.appendChild(icon)
      return () => {
        try {
          current?.removeChild(icon)
        } catch (e) {
          console.error('Avatar icon not found')
        }
      }
    }
    return
  }, [icon, iconRef])

  return (
    <div style={{
      height: `${iconSize}px`,
      width: `${iconSize}px`,
      borderRadius: "1.125rem",
      fontSize: "initial"
    }}>
      <span ref={iconRef} />
    </div>
  )
}
