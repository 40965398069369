import { Box, Text, VStack } from '@chakra-ui/react'
import { TransactionInformation } from './hooks/useTransactionHistory'
import { getTypography } from 'theme/typographies'
import { validateColor } from 'theme/colors'
import { TransactionCard, TransactionStatus } from './TransactionCard'
import { TransactionDetailsV2 } from 'state/transactions/types'

interface TransactionProps {
  pending: TransactionDetailsV2[]
  confirmedTransactions: TransactionInformation[]
}

export const Transactions = ({ pending, confirmedTransactions }: TransactionProps) => {
  return (
    <>
      {pending.length > 0 && (
        <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
          <Text
            style={getTypography('Typography/Body-Labels/Normal/Body')}
            color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
          >
            Pending
          </Text>
          <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
            {pending.map((pendingTx, i) => {
              return <TransactionCard key={i} details={pendingTx} status={TransactionStatus.PENDING} />
            })}
          </VStack>
        </VStack>
      )}
      {confirmedTransactions.length > 0 ? (
        <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
          <Text
            style={getTypography('Typography/Body-Labels/Normal/Body')}
            color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
          >
            History
          </Text>
          <VStack overflow="auto" maxH="30rem" w="100%">
            <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
              {confirmedTransactions.map((confirmedTx, i) => {
                return (
                  <VStack key={i} w="100%" gap="0.5rem">
                    {confirmedTx.transactions.map((cf, j) => {
                      const success = cf.receipt?.status === 1 || typeof cf.receipt?.status === 'undefined'
                      return (
                        <TransactionCard
                          key={j}
                          details={cf}
                          status={success ? TransactionStatus.COMPLETE : TransactionStatus.ERROR}
                        />
                      )
                    })}
                  </VStack>
                )
              })}
            </VStack>
          </VStack>
        </VStack>
      ) : (
        <VStack gap="0.5rem" w="100%" alignItems={'flex-start'} paddingBottom="0.5rem">
          <Text
            style={getTypography('Typography/Small/Normal/Small 1')}
            color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
          >
            Your Transactions will appear here.
          </Text>
        </VStack>
      )}
    </>
  )
}
