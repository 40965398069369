import { Avatar, AvatarBadge, AvatarProps } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'

interface NewTagProps extends AvatarProps {
  text: string
}

export const NewTag: React.FC<NewTagProps> = ({ text, ...props }) => {
  return (
    <Avatar
      textTransform={'none'}
      h="22px"
      color="inherit"
      bg="none"
      w={'auto'}
      css={{
        svg: {
          display: 'none',
        },
      }}
      {...props}
    >
      {text}
      <AvatarBadge
        border="none"
        borderColor={validateColor('Borders/Border-tertiary')}
        bg={validateColor('Text/Lables/Lable-success')}
        placement="top-end"
        boxSize={'1.8rem'}
        height={'0.8rem'}
        borderRadius={'5rem'}
        padding="0.125rem 0.0625rem"
        color={validateColor('Notifications/Number')}
        fontSize={'0.625rem'}
        fontWeight={700}
        right="-10px"
        top="-1px"
      >
        New
      </AvatarBadge>
    </Avatar>
  )
}
