import { MenuItem, Radio, RadioGroup } from '@chakra-ui/react'
import { getTypography } from 'theme/typographies'

export const SelectorRadioRow = ({
  active,
  onClick,
  children,
}: {
  active: boolean
  onClick: () => void
  children: React.ReactNode
}) => {
  return (
    <MenuItem justifyContent="space-between" alignItems="center" p="0" margin="2px" backgroundColor={'transparent'}>
      <RadioGroup value={active ? '1' : '0'} w="100%" onChange={onClick}>
        <Radio variant="withoutBorder" value="1" style={getTypography('Typography/Small/Normal/Small 1')}>
          {children}
        </Radio>
      </RadioGroup>
    </MenuItem>
  )
}
