
import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: `0x${string}` }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap("0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f")
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')

// celo v3 addresses
const CELO_V3_CORE_FACTORY_ADDRESS = '0xAfE208a311B21f13EF87E33A90049fC17A7acDEc'
const CELO_ROUTER_ADDRESS = '0x5615CDAb10dc425a742d643d949a7F474C01abc4'
const CELO_V3_MIGRATOR_ADDRESS = '0x3cFd4d48EDfDCC53D3f173F596f621064614C582'
// Uniswap interface Multicalls
const CELO_MULTICALL_ADDRESS = '0x633987602DE5C4F337e3DbF265303A1080324204'
const BNB_MULTICALL_ADDRESS = '0x963Df249eD09c358A4819E39d9Cd5736c3087184'
const MANTLE_MULTICALL_ADDRESS = '0x2A51f2F647E1883F88C992244dEd171238693107'
const ARBITRUM_ONE_MULTICALL_ADDRESS = '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB'
const ARBITRUM_RINKEBY_MULTICALL_ADDRESS = '0xa501c031958F579dB7676fF1CE78AD305794d579'
const LINEA_MULTICALL_ADDRESS = '0xac1cE734566f390A94b00eb9bf561c2625BF44ea'
const AVALANCHE_MULTICALL_ADDRESS = '0x0139141Cd4Ee88dF3Cdb65881D411bAE271Ef0C2'
const BASE_MULTICALL_ADDRESS = '0x091e99cb1C49331a94dD62755D168E941AbD0693'
const BASE_V3_CORE_FACTORY_ADDRESS = '0x33128a8fC17869897dcE68Ed026d694621f6FDfD'
const BASE_V3_MIGRATOR_ADDRESS = '0x23cF10b1ee3AdfCA73B0eF17C07F7577e7ACd2d7'
const BASE_QUOTER_ADDRESS = '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a'
const BASE_NONFUNGIBLE_POSITION_MANAGER_ADDRESS = '0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1'
const BASE_TICK_LENS_ADDRESS = '0x0CdeE061c75D43c82520eD998C23ac2991c9ac6d'
const CELO_QUOTER_ADDRESS = '0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8'
const CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESS = '0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A'
const CELO_TICK_LENS_ADDRESS = '0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D'
const BLAST_V3_CORE_FACTORY_ADDRESS = '0x792edAdE80af5fC680d96a2eD80A44247D2Cf6Fd'
const BLAST_V3_MIGRATOR_ADDRESS = '0x15CA7043CD84C5D21Ae76Ba0A1A967d42c40ecE0'
const BLAST_MULTICALL_ADDRESS = '0xdC7f370de7631cE9e2c2e1DCDA6B3B5744Cf4705'
const BLAST_QUOTER_ADDRESS = '0x6Cdcd65e03c1CEc3730AeeCd45bc140D57A25C77'
const BLAST_NONFUNGIBLE_POSITION_MANAGER_ADDRESS = '0xB218e4f7cF0533d4696fDfC419A0023D33345F28'
const BLAST_TICK_LENS_ADDRESS = '0x2E95185bCdD928a3e984B7e2D6560Ab1b17d7274'
const TAIKO_MULTICALL_ADDRESS = '0x8E24CfC19c6C00c524353CB8816f5f1c2F33c201'

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0x1F98431c8aD98523631AE4a59f267346ea31F984", [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.BASE]: BASE_V3_CORE_FACTORY_ADDRESS,
  [SupportedChainId.CELO]: CELO_V3_CORE_FACTORY_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_CORE_FACTORY_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_V3_CORE_FACTORY_ADDRESS,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.BASE]: BASE_V3_MIGRATOR_ADDRESS,
  [SupportedChainId.CELO]: CELO_V3_MIGRATOR_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_MIGRATOR_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_V3_MIGRATOR_ADDRESS,
}


/**
 * Uniswap Interface Multicall
 */
export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: ARBITRUM_ONE_MULTICALL_ADDRESS,
  [SupportedChainId.ARBITRUM_RINKEBY]: ARBITRUM_RINKEBY_MULTICALL_ADDRESS,
  [SupportedChainId.BASE]: BASE_MULTICALL_ADDRESS,
  [SupportedChainId.CELO]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.BSC]: BNB_MULTICALL_ADDRESS,
  [SupportedChainId.MANTLE]: MANTLE_MULTICALL_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_MULTICALL_ADDRESS,
  [SupportedChainId.LINEA]: LINEA_MULTICALL_ADDRESS,
  [SupportedChainId.AVALANCHE]: AVALANCHE_MULTICALL_ADDRESS,
  [SupportedChainId.TAIKO]: TAIKO_MULTICALL_ADDRESS,
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.BASE]: BASE_QUOTER_ADDRESS,
  [SupportedChainId.CELO]: CELO_QUOTER_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_QUOTER_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_QUOTER_ADDRESS,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.BASE]: BASE_NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  [SupportedChainId.CELO]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.BASE]: BASE_TICK_LENS_ADDRESS,
  [SupportedChainId.CELO]: CELO_TICK_LENS_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_TICK_LENS_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_TICK_LENS_ADDRESS,
}

export const META_AGGREGATOR_ADDRESSES: AddressMap = {
  [SupportedChainId.MANTLE]: '0xCc0264D9235756950613C770455424f9F7294A1f',
}