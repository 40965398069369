import { SupportedChainId } from 'constants/chains'

export const accountFactoryAddress: { [chainId: number]: string } = {
  [SupportedChainId.POLYGON]: '0x36E5e5e12782389359dFc89A7C3BAAA6E39666AA',
}

export const initBrokerAddresses = {
  [SupportedChainId.MANTLE]: '0xc08BFef7E778f3519D79E96780b77066F5d4FCC0'
}

export const ONE_DELTA_COMPOSER = {
  [SupportedChainId.MANTLE]: '0x9bc92bF848FaF2355c429c54d1edE3e767bDd790',
  [SupportedChainId.POLYGON]: '0x6A6faa54B9238f0F079C8e6CBa08a7b9776C7fE4',
  [SupportedChainId.TAIKO]: '0x0bd7473CbBf81d9dD936c61117eD230d95006CA2',
}