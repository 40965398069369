import { useEffect, useState } from 'react'

export const breakpoints = {
  sm: 640,
  md: 768,
  fiftyFifty: 915,
  lg: 1024,
  sixtyFourty: 1135,
  xl: 1450,
  xxl: 1536,
  xxxl: 1920,
}

const isClient = typeof window !== 'undefined'

function getIsTablet() {
  return isClient ? window.innerWidth < breakpoints.lg && window.innerWidth >= breakpoints.sm : false
}

export function useIsTablet(): boolean {
  const [isTablet, setIsTablet] = useState(getIsTablet)

  useEffect(() => {
    function handleResize() {
      setIsTablet(getIsTablet())
    }

    if (isClient) {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return undefined
  }, [])

  return isTablet
}


function getIsMedium() {
  return isClient ? window.innerWidth < breakpoints.xl && window.innerWidth >= breakpoints.sm : false
}

export function useIsMedium(): boolean {
  const [isMedium, setIsMedium] = useState(getIsMedium)

  useEffect(() => {
    function handleResize() {
      setIsMedium(getIsMedium())
    }

    if (isClient) {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return undefined
  }, [])

  return isMedium
}

function getIsSixtyFourtyRatio() {
  return isClient ? window.innerWidth > breakpoints.sixtyFourty : false
}

export function useIsSixtyFourtyRatio(): boolean {
  const [isSixtyFourtyRatio, setIsSixtyFourtyRatio] = useState(getIsSixtyFourtyRatio)

  useEffect(() => {
    function handleResize() {
      setIsSixtyFourtyRatio(getIsSixtyFourtyRatio())
    }

    if (isClient) {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return undefined
  }, [])

  return isSixtyFourtyRatio
}

function getIsFiftyFiftyRatio() {
  return isClient ? window.innerWidth > breakpoints.fiftyFifty : false
}

export function useIsFiftyFiftyRatio(): boolean {
  const [isFiftyFiftyRatio, setIsFiftyFiftyRatio] = useState(getIsFiftyFiftyRatio)

  useEffect(() => {
    function handleResize() {
      setIsFiftyFiftyRatio(getIsFiftyFiftyRatio())
    }

    if (isClient) {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return undefined
  }, [])

  return isFiftyFiftyRatio
}