import { SupportedChainId } from 'constants/chains'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import CeloLogo from '../../assets/svg/celo_logo.svg'
import MaticLogo from '../../assets/svg/matic-token-icon.svg'
import MantleLogo from '../../assets/svg/mnt-logo.svg'
import AvaxLogo from '../../assets/svg/avax-logo.svg'

type Network = 'ethereum' | 'arbitrum' | 'optimism' | 'polygon' | 'mantle' | 'bsc' | 'base' | 'avalanche' | 'linea' | 'blast' | 'taiko'

export function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'ethereum'
    case SupportedChainId.ARBITRUM_ONE:
      return 'arbitrum'
    case SupportedChainId.OPTIMISM:
      return 'optimism'
    case SupportedChainId.POLYGON:
      return 'polygon'
    case SupportedChainId.MANTLE:
      return 'mantle'
    case SupportedChainId.BSC:
      return 'bsc'
    case SupportedChainId.BASE:
      return 'base'
    case SupportedChainId.BLAST:
      return 'blast'
    case SupportedChainId.LINEA:
      return 'linea'
    case SupportedChainId.TAIKO:
      return 'taiko'
    case SupportedChainId.AVALANCHE:
      return 'avalanche'
    default:
      return 'ethereum'
  }
}

export function getNativeLogoURI(chainId: SupportedChainId = SupportedChainId.MAINNET): string {
  switch (chainId) {
    case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return MaticLogo
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
      return CeloLogo
    case SupportedChainId.MANTLE:
      return MantleLogo
    case SupportedChainId.AVALANCHE:
      return AvaxLogo
    default:
      return EthereumLogo
  }
}
