import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'

export const addressesInitCore: AddressDictionary = {
  InitOracle: {
    [SupportedChainId.MANTLE]: '0x4E195A32b2f6eBa9c4565bA49bef34F23c2C0350'
  },
  Core: {
    [SupportedChainId.MANTLE]: '0x972BcB0284cca0152527c4f70f8F689852bCAFc5'
  },
  Config: {
    [SupportedChainId.MANTLE]: '0x007F91636E0f986068Ef27c950FA18734BA553Ac'
  },
  PosManager: {
    [SupportedChainId.MANTLE]: '0x0e7401707CD08c03CDb53DAEF3295DDFb68BBa92'
  },
  Lens: {
    [SupportedChainId.MANTLE]: '0x7d2b278b8ef87bEb83AeC01243ff2Fed57456042'
  },
  PublicLens: {
    [SupportedChainId.MANTLE]: '0xDf75fF0452eDf4897B051A54b406eB384503Ac39'
  },
}

export const addressesInitPoolTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MANTLE]: '0xf36afb467d1f05541d998bbbcd5f7167d67bd8fc'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: '0xe71cbaaa6b093fce66211e6f218780685077d8b5'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: '0x44cccbbd7a5a9e2202076ea80c185da0058f1715'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: '0x787cb0d29194f0faca73884c383cf4d2501bb874'
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: '0x683696523512636b46a826a7e3d1b0658e8e2e1c'
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: '0x0e927aa52a38783c1fd5dfa5c8873cbdbd01d2ca'
  },
}