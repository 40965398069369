import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
import { VersionedDeltaTokenList } from './reducer';

export const fetchTokenList: Readonly<{
  pending: ActionCreatorWithPayload<{ chain: number; requestId: string }>
  fulfilled: ActionCreatorWithPayload<{ chain: number; tokenList: VersionedDeltaTokenList; requestId: string }>
  rejected: ActionCreatorWithPayload<{ chain: number; errorMessage: string; requestId: string }>
}> = {
  pending: createAction('lists/fetchTokenList/pending'),
  fulfilled: createAction('lists/fetchTokenList/fulfilled'),
  rejected: createAction('lists/fetchTokenList/rejected'),
}
