import type { Actions, AddEthereumChainParameter, ProviderRpcError } from '@web3-react/types'
import { Connector } from '@web3-react/types'
import { Polygon, Mantle, BNBChain, Avalanche, Ethereum } from '@particle-network/chains'
import { Config, ParticleNetwork, WalletEntryPosition } from "@particle-network/auth"
import { ParticleProvider } from "@particle-network/provider"
import { ColorMode } from 'theme'

// we read the user theme from local storage
// a hook like useIsLightMode() should be called from outside, and the result
// should be passed to too many functions
const getThemeModeFromLocalStorage = () => {
  const reduxLocalStorage = localStorage.getItem('redux_localstorage_simple_user')
  const theme = JSON.parse(reduxLocalStorage as string).userTheme || ColorMode.DARK
  return theme === ColorMode.LIGHT ? 'light' : 'dark'
}

export interface ParticleConnectorConstructorArgs {
  actions: Actions
  onError?: (error: Error) => void
}

export class ParticleConnector extends Connector {
  public provider?: ParticleProvider
  public particleNetwork?: ParticleNetwork
  private eagerConnection?: Promise<ParticleProvider>

  constructor({ actions, onError }: ParticleConnectorConstructorArgs) {
    super(actions, onError)
  }

  private disconnectListener = (error: ProviderRpcError) => {
    this.actions.resetState()
    if (error) this.onError?.(error)
  }

  private chainChangedListener = (chainId: string): void => {
    this.actions.update({ chainId: Number.parseInt(chainId, 16) })
  }

  private accountsChangedListener = (accounts: string[]): void => {
    this.actions.update({ accounts })
  }

  private async initializeProvider(): Promise<ParticleProvider> {
    const particleConfig = getParticleConfig()
    this.particleNetwork = new ParticleNetwork(particleConfig)
    this.particleNetwork.setAuthTheme({
      uiMode: getThemeModeFromLocalStorage(),
    });
    this.provider = new ParticleProvider(this.particleNetwork.auth)

    return this.provider
      .on('disconnect', this.disconnectListener)
      .on('chainChanged', this.chainChangedListener)
      .on('accountsChanged', this.accountsChangedListener)
  }

  private isomorphicInitialize(): Promise<ParticleProvider> {
    if (this.eagerConnection) return this.eagerConnection
    return (this.eagerConnection = this.initializeProvider())
  }

  public async connectEagerly(): Promise<void> {
    await this.isomorphicInitialize()
    const cancelActivation = this.actions.startActivation()

    try {
      const account = await this.particleNetwork?.auth?.getEVMAddress()
      if (!account) {
        throw new Error('No active session found. Connect your wallet first.')
      }
      this.actions.update({ chainId: this.particleNetwork?.auth?.getChainId(), accounts: [account] })
    } catch (error) {
      await this.deactivate()
      cancelActivation()
      throw error
    }
  }

  public async activate(chainParameters: AddEthereumChainParameter): Promise<void> {
    const provider = await this.isomorphicInitialize()

    const accountExists = (await this.particleNetwork?.auth?.getEVMAddress()) !== undefined
    if (accountExists) {
      if (!chainParameters || chainParameters.chainId === this.particleNetwork?.auth?.getChainId()) return

      return provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainParameters.chainId.toString(16)}` }],
      })
    }

    const cancelActivation = this.actions.startActivation()
    await this.particleNetwork?.auth?.login()
    const account = await this.particleNetwork?.auth?.getEVMAddress()
    if (!account) throw ("Login failed.")

    try {
      await provider.enable()
      this.actions.update({ chainId: this.particleNetwork?.auth?.getChainId(), accounts: [account] })
    } catch (error) {
      await this.deactivate()
      cancelActivation()
      throw error
    }
  }

  public async deactivate(): Promise<void> {
    this.provider
      ?.removeListener('disconnect', this.disconnectListener)
      .removeListener('chainChanged', this.chainChangedListener)
      .removeListener('accountsChanged', this.accountsChangedListener)
      .disconnect()
    this.provider = undefined
    this.particleNetwork = undefined
    this.eagerConnection = undefined
    this.actions.resetState()
  }
}

const getParticleConfig = (): Config => {
  return {
    projectId: process.env.REACT_APP_PARTICLE_PROJECT_ID as string,
    clientKey: process.env.REACT_APP_PARTICLE_CLIENT_KEY as string,
    appId: process.env.REACT_APP_PARTICLE_API_ID as string,
    chainName: Mantle.name,
    chainId: Mantle.id,
    wallet: {
      displayWalletEntry: true,
      defaultWalletEntryPosition: WalletEntryPosition.BL,
      supportChains: [Polygon, Mantle, BNBChain, Avalanche, Ethereum],
      customStyle: {},
      uiMode: getThemeModeFromLocalStorage()
    },
    securityAccount: {  // Optional: Configuration of security requirements upon login
      // If, and in what frequency, will the user be prompted to set a payment password
      // 0: None, 1: Once (default), 2: Always
      promptSettingWhenSign: 1,
      // If, and in what frequency, will the user be prompted to set a master password
      // 0: None (default), 1: Once, 2: Always
      promptMasterPasswordSettingWhenLogin: 1
    },
  }
}