import { AppState } from 'state'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { OneDeltaAccount } from './reducer'
import { COMPOUND_V2_FORKS, Lender } from 'types/lenderData/base'
import { lenderToReduxSlice } from 'hooks/lenders'
import { useCallback } from 'react'
import { selectPosId } from 'state/lenders/init/actions'
import { BaseAccountConfig } from 'types/lenderData/init'

export function useDeltaState(): AppState['delta'] {
  return useAppSelector((state) => state.delta)
}

export function useGetCurrentAccount(chainId: number) {
  return useAppSelector((state) => state.delta).userMeta[chainId]?.selectedAccountData?.account
}

export const convertAccountArray = (accountData: { [a: number]: OneDeltaAccount }): { [a: number]: string } => {
  if (!accountData) return {}
  return Object.assign(
    {},
    ...Object.values(accountData).map((x, i) => {
      return { [i]: x.accountAddress }
    })
  )
}

export const useGetSelectedSubAccountId = (lender: Lender, chainId?: number, account?: string): string | undefined => {
  const deltaState = useAppSelector((state) => state[lenderToReduxSlice(lender)]?.[Number(chainId)])?.userConfigs
  const selectedConfig = useAppSelector((state) => state[lenderToReduxSlice(lender)])?.[Number(chainId)]?.selectedConfig?.id
  if (!chainId || !account) return undefined
  if (lender === Lender.INIT) return selectedConfig
  if (COMPOUND_V2_FORKS.includes(lender)) return deltaState?.userMeta?.[chainId]?.selectedAccountData?.account?.accountAddress
  return account
}

export function useHasDeltaAccount(chainId: number): boolean {
  const accounts = useAppSelector((state) => state.delta.userMeta[chainId].accounts1Delta)
  return accounts ? Object.values(accounts).length > 0 : false
}

export function useHasInitAccount(chainId: number) {
  return Object.values(useAppSelector((state) => state.init[chainId].userConfigs) ?? {}).length > 0
}

export function useSelectSubAccount(lender: Lender, chainId: number) {
  const dispatch = useAppDispatch()
  return useCallback((accountId: string) => {
    if (lender === Lender.INIT) {
      dispatch(selectPosId({ chainId, posId: accountId }))
    }
  }, [lender, chainId])
}

export function useSubAccounts(lender: Lender, chainId: number): BaseAccountConfig[] {
  const deltaState = useAppSelector((state) => state[lenderToReduxSlice(lender)])?.[Number(chainId)]?.userConfigs
  if (lender !== Lender.INIT) return []
  return Object.values(deltaState ?? {})
}

export const useGetSelectedSubAccountData = (lender: Lender, chainId?: number, account?: string): { accountId: string, mode: number } | undefined => {
  const selectedConfig = useAppSelector((state) => state[lenderToReduxSlice(lender)])?.[chainId ?? 0]?.selectedConfig
  if (!chainId || !account) return undefined
  if (lender === Lender.INIT) return selectedConfig ? { accountId: selectedConfig.id, mode: selectedConfig.mode } : undefined
  return { accountId: account, mode: 0 }
}
