import { HStack } from '@chakra-ui/react'
import { CurrencyDetails } from 'components/CurrencyDetails'
import { BalanceTokenAndUsd } from 'components/Table/MarginTable/BalanceTokenAndUsd'
import { useCurrency } from 'hooks/Tokens'
import { WalletAssetData } from 'hooks/asset/useWalletAssetData'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'

interface WalletMenuTableRowProps {
  assetData: WalletAssetData
  isMobile: boolean
  price: number
}

export const WalletMenuAssetsRow: React.FC<WalletMenuTableRowProps> = ({ assetData, isMobile, price }) => {
  const currency = useCurrency(assetData.assetId as any)

  return currency ? (
    <HStack p="0.25rem 0" w="100%" justifyContent="space-between">
      <CurrencyDetails
        currency={currency}
        shortName={true}
        logoSize="2.5rem"
        symbolProps={{ style: getTypography('Typography/Body-Labels/Medium/Body-Label-Medium') }}
        nameProps={{ style: getTypography('Typography/Small/Normal/Small 1') }}
        textStackProps={{ gap: '0.375rem' }}
      />
      <BalanceTokenAndUsd
        balance={assetData.walletBalance}
        balanceUsd={assetData.walletBalanceUSD}
        tokenSymbol={currency?.symbol ?? ''}
        topTextProps={{
          color: validateColor('Text/Headings & Titles/Title-text'),
          lineHeight: '1 !important',
        }}
        stackProps={{
          alignItems: 'flex-end',
          gap: '0.375rem',
        }}
      />
    </HStack>
  ) : null
}
