import { useCallback, useMemo, useState } from "react";

export type SortConfig<T> = {
  key: keyof T;
  direction: 'asc' | 'desc';
}

export function useSortConfig<T>(rawData: T[], defaultSortConfig: SortConfig<T> | null = null) {
  const [sortConfig, setSortConfig] = useState<SortConfig<T> | null>(defaultSortConfig);

  const requestSort = useCallback((key: keyof T) => {
    let direction: 'asc' | 'desc' = 'desc';
    if (sortConfig && sortConfig.key as any === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    setSortConfig({ key, direction });
  }, [setSortConfig, sortConfig]);

  const sortedData = useMemo(() => {
    const sortableData = [...rawData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key as any] < b[sortConfig.key as any]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key as any] > b[sortConfig.key as any]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData
  }, [sortConfig, rawData]);

  return {
    sortedData,
    requestSort,
    sortConfig
  }
}