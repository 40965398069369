import { Currency } from '@1delta/base-sdk'
import { Flex, Image, Text } from '@chakra-ui/react'
import { useGetTokenURI } from 'constants/tokenLists'
import { useCurrencyFromAsset } from 'hooks/asset/useCurrencyFromAsset'
import { useEffect, useState } from 'react'
import { useIsLightMode } from 'state/user/hooks'
import { validateColor } from 'theme/colors'
import { SupportedAssets } from 'types/1delta'

/**
 * Split too long symbols
 */
const splitSymbol = (s: string | undefined) => {
  if (!s) return ''
  if (s.length > 3) {
    return <Text>{s.slice(0, 3)}</Text>
  }
  return <Text>{s}</Text>
}

interface FallbackProps {
  size: string
  symbol: string | undefined
}
export const FallbackCurrencyLogo = ({ symbol, size }: FallbackProps) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg={validateColor('Button/Button Secondary/Button-secondary-border-default')}
      width={size ?? '24px'}
      height={size ?? '24px'}
      borderRadius={'50%'}
      lineHeight={'0.8'}
      color={validateColor('Text/Lables/Label-text-default')}
      fontSize={'0.7rem'}
    >
      {splitSymbol(symbol)}
    </Flex>
  )
}

export default function CurrencyLogo({
  currency,
  size = '24px',
  ...rest
}: {
  currency?: Currency | undefined
  size?: string
}) {
  const uri = useGetTokenURI(currency)
  const isLightMode = useIsLightMode()

  // we have a manual fallback-logo that is used
  // on error in the image component
  const [imageIsDead, setImageIsDead] = useState(false);

  const handleError = () => {
    setImageIsDead(true);
  };
  // optimistically assume that
  // the ccy has a logo
  useEffect(() => {
    setImageIsDead(false)
  }, [currency])

  return !imageIsDead ? (
    <Image
      src={uri}
      borderRadius={'50%'}
      alt={currency?.symbol ?? 'select'}
      width={size}
      onError={handleError}
      height={size}
      filter={isLightMode && currency?.symbol === 'WMNT' ? 'invert(1)' : 'none'}
      {...rest}
    />
  ) : (
    <FallbackCurrencyLogo size={size} symbol={currency?.symbol} />
  )
}

interface AssetImageProps {
  chainId: number
  asset: SupportedAssets
  size?: string | undefined
}

export const AssetImage = ({ asset, chainId, size }: AssetImageProps) => {
  return <CurrencyLogo currency={useCurrencyFromAsset(chainId, asset)} size={size ?? '1.2rem'} />
}
