import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { AppState } from '../index'
import { ApplicationModal, setOpenModal } from './reducer'

export function useCloseModal(_modal: ApplicationModal): () => void {
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenModal(null)), [dispatch])
}

export function useWalletMenuDisclosure() {
  const walletMenuOpen = useAppSelector((state: AppState) => state.application.walletMenuOpen)
  const dispatch = useAppDispatch()
  const onToggleWalletMenu = useCallback(() => {
    dispatch({ type: 'application/onToggleWalletMenu' })
  }, [dispatch])
  const onCloseWalletMenu = useCallback(() => {
    dispatch({ type: 'application/onCloseWalletMenu' })
  }, [dispatch])
  return {
    walletMenuOpen,
    onToggleWalletMenu,
    onCloseWalletMenu,
  }
}
