import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary, FlatAddressDictionary } from './addresses'

export const venusAddresses: AddressDictionary = {
  Comptroller: {
    [SupportedChainId.POLYGON]: '0xf6C14D4DFE45C132822Ce28c646753C54994E59C',
  },
  Unitroller: {
    [SupportedChainId.POLYGON]: '0xfD36E2c2a6789Db23113685031d7F16329158384',
  },
  CompoundLens: {
    [SupportedChainId.BSC]: '0xfB0f09dB330dC842a6637BfB959209424BbFE8C7'
  }
}

export const addressesVenusVTokens: AddressDictionary = {
  [SupportedAssets.AAVE]: {
    [SupportedChainId.BSC]: '0x26DA28954763B92139ED49283625ceCAf52C6f94'
  },
  [SupportedAssets.ADA]: {
    [SupportedChainId.BSC]: '0x9A0AF7FDb2065Ce470D72664DE73cAE409dA28Ec'
  },
  [SupportedAssets.BCH]: {
    [SupportedChainId.BSC]: '0x5F0388EBc2B94FA8E123F404b79cCF5f40b29176'
  },
  [SupportedAssets.BETH]: {
    [SupportedChainId.BSC]: '0x972207A639CC1B374B893cc33Fa251b55CEB7c07'
  },
  [SupportedAssets.BNB]: {
    [SupportedChainId.BSC]: '0xA07c5b74C9B40447a954e1466938b865b6BBea36'
  },
  [SupportedAssets.BTCB]: {
    [SupportedChainId.BSC]: '0x882C173bC7Ff3b7786CA16dfeD3DFFfb9Ee7847B'
  },
  [SupportedAssets.BUSD]: {
    [SupportedChainId.BSC]: '0x95c78222B3D6e262426483D42CfA53685A67Ab9D'
  },
  [SupportedAssets.CAKE]: {
    [SupportedChainId.BSC]: '0x86aC3974e2BD0d60825230fa6F355fF11409df5c'
  },
  [SupportedAssets.CAN]: {
    [SupportedChainId.BSC]: '0xeBD0070237a0713E8D94fEf1B728d3d993d290ef'
  },
  [SupportedAssets.DAI]: {
    [SupportedChainId.BSC]: '0x334b3eCB4DCa3593BCCC3c7EBD1A1C1d1780FBF1'
  },
  [SupportedAssets.DOGE]: {
    [SupportedChainId.BSC]: '0xec3422Ef92B2fb59e84c8B02Ba73F1fE84Ed8D71'
  },
  [SupportedAssets.DOT]: {
    [SupportedChainId.BSC]: '0x1610bc33319e9398de5f57B33a5b184c806aD217'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.BSC]: '0xf508fCD89b8bd15579dc79A6827cB4686A3592c8'
  },
  [SupportedAssets.FIL]: {
    [SupportedChainId.BSC]: '0xf91d58b5aE142DAcC749f58A49FCBac340Cb0343'
  },
  [SupportedAssets.LINK]: {
    [SupportedChainId.BSC]: '0x650b940a1033B8A1b1873f78730FcFC73ec11f1f'
  },
  [SupportedAssets.LTC]: {
    [SupportedChainId.BSC]: '0x57A5297F2cB2c0AaC9D554660acd6D385Ab50c6B'
  },
  [SupportedAssets.LUNA]: {
    [SupportedChainId.BSC]: '0xb91A659E88B51474767CD97EF3196A3e7cEDD2c8'
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.BSC]: '0x5c9476FcD6a4F9a3654139721c949c2233bBbBc8'
  },
  [SupportedAssets.SXP]: {
    [SupportedChainId.BSC]: '0x2fF3d0F6990a40261c66E1ff2017aCBc282EB6d0'
  },
  [SupportedAssets.TRX]: {
    [SupportedChainId.BSC]: '0xC5D3466aA484B040eE977073fcF337f2c00071c1'
  },
  [SupportedAssets.TRXOLD]: {
    [SupportedChainId.BSC]: '0x61eDcFe8Dd6bA3c891CB9bEc2dc7657B3B422E93'
  },
  [SupportedAssets.TUSD]: {
    [SupportedChainId.BSC]: '0xBf762cd5991cA1DCdDaC9ae5C638F5B5Dc3Bee6E'
  },
  [SupportedAssets.TUSDOLD]: {
    [SupportedChainId.BSC]: '0x08CEB3F4a7ed3500cA0982bcd0FC7816688084c3'
  },
  [SupportedAssets.BUSDC]: {
    [SupportedChainId.BSC]: '0xecA88125a5ADbe82614ffC12D0DB554E2e2867C8'
  },
  [SupportedAssets.BUSDT]: {
    [SupportedChainId.BSC]: '0xfD5840Cd36d94D7229439859C0112a4185BC0255'
  },
  [SupportedAssets.UST]: {
    [SupportedChainId.BSC]: '0x78366446547D062f45b4C0f320cDaa6d710D87bb'
  },
  [SupportedAssets.WBETH]: {
    [SupportedChainId.BSC]: '0x6CFdEc747f37DAf3b87a35a1D9c8AD3063A1A8A0'
  },
  [SupportedAssets.XRP]: {
    [SupportedChainId.BSC]: '0xB248a295732e0225acd3337607cc01068e3b9c10'
  },
  [SupportedAssets.XVS]: {
    [SupportedChainId.BSC]: '0x151B1e2635A717bcDc836ECd6FbB62B674FE3E1D'
  },
}

export const getVenusVTokens = (chainId: number, assets: SupportedAssets[]): FlatAddressDictionary => {
  try {
    return Object.assign(
      {},
      ...assets.map((a) => {
        return { [a]: addressesVenusVTokens[a][chainId] }
      })
    )
  } catch (e) {
    console.log("Error fetching vTokens", e)
    return {}
  }
}
