import React from 'react'

import { Card, HStack, Img, Link, Text, VStack } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'

export default function Option({
  link = undefined,
  clickable = true,
  onClick = undefined,
  color,
  header,
  icon,
  isActive = false,
  id,
}: {
  link?: string | undefined
  clickable?: boolean
  onClick?: undefined | (() => void)
  color: string
  header: React.ReactNode
  icon: string
  isActive?: boolean
  id: string
}) {
  const content = (
    <Card
      width="100%"
      padding="1rem"
      background={validateColor('Surface/Surface-primary 2')}
      id={id}
      onClick={onClick}
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      _hover={{
        cursor: 'pointer',
        background: validateColor('Surface/Surface-secondary'),
        transition: 'background-color 0.1s ease-in-out',
      }}
      alignItems="center"
    >
      <Text
        textAlign="left"
        color={
          isActive
            ? validateColor('Navigation/Navigation-text-active')
            : validateColor('Navigation/Navigation-text-inactive')
        }
      >
        {header}
      </Text>
      <Img src={icon} alt={'Icon'} w="2rem" h="2rem" />
    </Card>
  )
  if (link) {
    return (
      <Link href={link} target="_blank" w="100%" _hover={{ textDecoration: 'none' }}>
        {content}
      </Link>
    )
  }

  return content
}
