import { createReducer } from '@reduxjs/toolkit'
import {
  flushAccount,
  resetState,
  set1DeltaAccount,
  set1DeltaAccountMetaLoading,
} from './actions'

import { fetch1DeltaUserAccountDataAsync } from './fetch1DeltaAccountData'
import { STATE_CHAINIDS, SupportedChainId } from 'constants/chains'

export interface OneDeltaAccount {
  accountAddress: string
  accountOwner: string
  accountName: string
  creationTimestamp: number
  compoundSummary?: {
    markets: string[]
    liquidity: number
    shortfall: number
  }
}
export interface DeltaState {
  userMeta: {
    [chainId: number]: {
      loaded: boolean
      accounts1Delta: { [index: number]: OneDeltaAccount }
      selectedAccountData: { index: number; account: OneDeltaAccount | undefined }
    }
  }
}

const userMetaDummy = {
  loaded: false,
  selectedAccountData: { account: undefined, index: -1 },
  accounts1Delta: {},
}

export const initialState: DeltaState = {

  userMeta: Object.assign(
    {},
    ...STATE_CHAINIDS.map((x) => {
      return { [x]: userMetaDummy }
    })
  )
}

export default createReducer<DeltaState>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    // ==== 1Delta User Account =====
    .addCase(set1DeltaAccount, (state, action) => {
      state.userMeta[action.payload.chainId].selectedAccountData = {
        account: state.userMeta[action.payload.chainId].accounts1Delta[action.payload.index],
        index: action.payload.index,
      }
    })
    .addCase(flushAccount, (state) => {
      state.userMeta = Object.assign(
        {},
        ...Object.values(SupportedChainId).map((x) => {
          return { [x]: userMetaDummy }
        })
      )
    })
    .addCase(set1DeltaAccountMetaLoading, (state, action) => {
      state.userMeta[action.payload.chainId].loaded = action.payload.state
    })
    // ==== Account Fetch
    .addCase(fetch1DeltaUserAccountDataAsync.pending, (state) => {
      // 
    })
    .addCase(fetch1DeltaUserAccountDataAsync.fulfilled, (state, action) => {
      state.userMeta[action.payload.chainId].accounts1Delta = action.payload.accounts
      state.userMeta[action.payload.chainId].loaded = true
    })
)
