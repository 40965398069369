import { getTypography } from 'theme/typographies'
import { Box, ButtonProps, Image, Button, ImageProps, forwardRef } from '@chakra-ui/react'
import { default as LoadingGif } from 'assets/gifs/loading-button.gif'

export const Icon: React.FC<ImageProps> = ({ src, alt, ...props }) => {
  return <Image src={src} alt={alt} {...props} />
}

const BeatLoader: React.FC = () => {
  return <Icon src={LoadingGif} alt="loading" w="3rem" opacity={0.5} />
}

const BaseButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return (
    <Button
      spinner={<BeatLoader />}
      spinnerPlacement="end"
      {...props}
      _loading={{
        '> *:not(:first-of-type)': {
          marginInlineStart: '0',
        },
      }}
      loadingText={<Box ml="1rem">{props.loadingText}</Box>}
      ref={ref}
    />
  )
})

export const LargeButton: React.FC<ButtonProps> = (props) => {
  return (
    <BaseButton
      height={'2.5rem'}
      padding={'0.5rem 1.5rem'}
      justifyContent={'center'}
      alignItems={'center'}
      borderRadius={'0.25em'}
      style={getTypography('Typography/Body-Labels/Normal/Body')}
      {...props}
    />
  )
}

export const MediumButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return (
    <BaseButton
      height={'2rem'}
      padding={'0.5rem'}
      textAlign={'center'}
      style={getTypography('Typography/Small/Normal/Small 1')}
      lineHeight={'auto'}
      borderRadius={'0.25em'}
      ref={ref}
      {...props}
    />
  )
})

export const SmallButton: React.FC<ButtonProps> = (props) => {
  return (
    <BaseButton
      padding={'0.25rem 0.5rem'}
      height={'auto'}
      textAlign={'center'}
      style={getTypography('Typography/Small/Normal/Small 3')}
      lineHeight={1}
      {...props}
    />
  )
}

export interface ButtonWithIconProps extends ButtonProps {
  iconSrc?: string // Icon name (e.g. "eye.svg")
  alt?: string // Icon alt text
}
