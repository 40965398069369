import { ButtonProps } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { ButtonWithIconProps, LargeButton, MediumButton, Icon, SmallButton } from '../common'

const PrimaryButtonProps: ButtonProps = {
  backgroundColor: validateColor('Button/Button Primary/Button-primary-default'),
  color: validateColor('Button/Button Primary/Button-primary-text-default'),
  _hover: {
    backgroundColor: validateColor('Button/Button Primary/Button-primary-hover'),
  },
  _active: {
    backgroundColor: validateColor('Button/Button Primary/Button-primary-active'),
  },
  _disabled: {
    backgroundColor: validateColor('Button/Button Primary/Button-primary-disabled'),
    color: validateColor('Text/Lables/Label-text-disabled'),
    cursor: 'not-allowed',
  },
}

export const PrimaryLargeButton: React.FC<ButtonProps> = (props) => {
  return <LargeButton {...PrimaryButtonProps} {...props} />
}

export const PrimaryMediumButton: React.FC<ButtonProps> = (props) => {
  return <MediumButton {...PrimaryButtonProps} {...props} />
}

export const PrimaryMediumIconLeftButton: React.FC<ButtonWithIconProps> = ({ iconSrc, alt, ...props }) => {
  return (
    <MediumButton
      {...PrimaryButtonProps}
      {...props}
      leftIcon={<Icon src={iconSrc} alt={alt} />}
      iconSpacing={'0.25rem'}
    />
  )
}

export const PrimaryMediumIconRightButton: React.FC<ButtonWithIconProps> = ({ iconSrc, alt, ...props }) => {
  return <MediumButton {...PrimaryButtonProps} {...props} rightIcon={<Icon src={iconSrc} alt={alt} />} />
}

export const PrimarySmallButton: React.FC<ButtonProps> = (props) => {
  return <SmallButton {...PrimaryButtonProps} {...props} />
}

export const PrimarySmallIconRightButton: React.FC<ButtonWithIconProps> = ({ iconSrc, alt, ...props }) => {
  return <SmallButton {...PrimaryButtonProps} {...props} rightIcon={<Icon src={iconSrc} alt={alt} width="0.8em" />} />
}
