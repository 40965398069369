import { HStack, Link, Switch, Text } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { TradeAggregator } from 'types/1delta'

const AGGREGATOR_TEXT = {
  [TradeAggregator.OneDelta]: ['The classic', ''],
  [TradeAggregator.Paraswap]: ['Paraswap', 'https://app.paraswap.io/'],
  [TradeAggregator.OneInch]: ['1inch', 'https://app.1inch.io/'],
  [TradeAggregator.Odos]: ['Odos', 'https://app.odos.xyz/'],
  [TradeAggregator.Kyberswap]: ['Kyberswap', 'https://kyberswap.com/'],
  [TradeAggregator.Bebop]: ['Bebop', 'https://bebop.xyz/'],
}
interface AggregatorRowProps {
  selectedAggregators: TradeAggregator[]
  aggregator: TradeAggregator
  triggerChange: () => void
}

export const AggregatorSelectionEntry = ({ aggregator, selectedAggregators, triggerChange }: AggregatorRowProps) => {
  return (
    <HStack justifyContent={'space-between'} w="100%">
      <Text
        style={getTypography('Typography/Small/Normal/Small 1')}
        color={validateColor('Text/Lables/Label-text-default')}
      >
        <Link href={AGGREGATOR_TEXT[aggregator][1]}>{aggregator}</Link>
      </Text>
      <Switch onChange={triggerChange} isChecked={selectedAggregators?.includes(aggregator)} />
    </HStack>
  )
}
