import { lenderToReduxSlice, toLenderAssetKey } from "hooks/lenders"
import { useChainId, useNativeBalance } from "state/globalNetwork/hooks"
import { useAppSelector } from "state/hooks"
import { useBaseAsset } from "state/margin/hooks"
import { SupportedAssets } from "types/1delta"
import { useSelectedLender } from 'state/user/hooks'


export interface AssetFlags {
  reachedSupplyCap: boolean
  reachedBorrowCap: boolean
  isFrozen: boolean
}

/**
 * Fetches and computes asset flags
 * @param the supported asset as enum
 * @returns flags for the coresponding asset
 */
export const useAssetFlags = (asset: SupportedAssets | string): AssetFlags => {

  const chainId = useChainId()
  const lender = useSelectedLender()
  const baseAsset = useBaseAsset()

  const reduxSlice = lenderToReduxSlice(lender)
  const deltaAssets = useAppSelector(state => state[reduxSlice]?.[chainId]?.lenderData)
  const lenderKey = toLenderAssetKey(asset, lender, baseAsset)

  const totalSupply = deltaAssets[lenderKey]?.totalDeposits ?? 0
  const reachedSupplyCap = deltaAssets[lenderKey]?.supplyCap > 0 && totalSupply >= deltaAssets[lenderKey]?.supplyCap * 0.99

  const totalBorrow = (deltaAssets[lenderKey]?.totalDebt ?? 0) + (deltaAssets[lenderKey]?.totalDebtStable ?? 0)
  const reachedBorrowCap = deltaAssets[lenderKey]?.borrowCap > 0 && totalBorrow >= deltaAssets[lenderKey]?.borrowCap * 0.99

  return {
    reachedSupplyCap: reachedSupplyCap ?? false,
    reachedBorrowCap: reachedBorrowCap ?? false,
    isFrozen: deltaAssets[lenderKey]?.isFrozen ?? false,
  }
}
