import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { C_TOKEN_PRICE_DECIMALS, RESERVE_MANTISSA_DECIMALS, TOKEN_META } from 'constants/1delta'
import { SupportedChainId } from 'constants/chains'
import { getCompoundCTokens } from 'hooks/1delta/addressesCompound'
import { getCompoundLensContract } from 'hooks/1delta/use1DeltaContract'
import { Call, multicallViem } from 'utils/multicall'
import OVIX_LENS_ABI from 'abis/compound-v2/OVixLens.json'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { calculateRateForCompound } from 'utils/tableUtils/format'
import { getLenderAssets } from 'constants/getAssets'
import { LENDER_MODE_NO_MODE, Lender } from 'types/lenderData/base'

const getEntry = (entry: any,) => {
  return entry
}

export interface CompoundPublicResponse {
  chainId: number
  data: {
    [tokenSymbol: string]: {
      exchangeRateCurrent: number
      // interest rates
      depositRate: number,
      variableBorrowRate: number
      // deposits
      totalDebt: number
      totalDeposits: number
      // flag
      isListed: boolean
      config: {
        [0]: {
          modeId: 0,
          // collateral factors
          borrowCollateralFactor: number,
          collateralFactor: number,
          borrowFactor: 1
        }
      }
      underlyingAssetAddress: number
      cTokenDecimals: number
      // rewards (TBU)
      compSupplySpeed: number
      compBorrowSpeed: number
      // cap
      borrowCap: number
    }
  }
}

export interface CompoundPublicQueryParams {
  chainId: number
  prices: { [asset: string]: number }
  stakingYields: { [asset: string]: number; }
}

export const fetch0vixPublicData: AsyncThunk<CompoundPublicResponse, CompoundPublicQueryParams, any> =
  createAsyncThunk<CompoundPublicResponse, CompoundPublicQueryParams>(
    'vix/fetch0vixPublicData',
    async ({ chainId, stakingYields, prices }) => {
      const isEthereum = chainId === SupportedChainId.MAINNET || chainId === SupportedChainId.GOERLI
      if (!isEthereum) return {
        chainId,
        data: {}
      }

      const rawAddressDict = getCompoundCTokens(chainId, getLenderAssets(chainId, Lender.COMPOUND_V2))

      const lensContract = getCompoundLensContract(chainId)

      const tokens = Object.values(rawAddressDict)

      const names = Object.keys(rawAddressDict)

      const calls: Call[] = tokens.map((tk) => {
        return {
          address: lensContract.address,
          name: 'cTokenMetadata',
          params: [tk],
        }
      })
      const multicallResult = await multicallViem(chainId,
        OVIX_LENS_ABI,
        calls
      )

      const result = Object.assign(
        {},
        ...multicallResult.map((entry, index) => {
          const asset = names[index]
          const decs = TOKEN_META[asset].decimals
          const currentEntry = getEntry(entry)
          const borrowCollateralFactor = parseRawAmount(currentEntry?.collateralFactorMantissa?.toString(), RESERVE_MANTISSA_DECIMALS)
          const exchangeRateCurrent = parseRawAmount(currentEntry?.exchangeRateCurrent?.toString(), C_TOKEN_PRICE_DECIMALS)
          const totalSupplyUnderlying = parseRawAmount(currentEntry?.totalSupply?.toString(), decs) * exchangeRateCurrent
          const totalDebt = parseRawAmount(currentEntry?.totalBorrows?.toString(), decs)
          const totalLiquidity = totalSupplyUnderlying - totalDebt
          const price = prices[asset]
          return {
            [asset]: {
              exchangeRateCurrent,
              // interest rates
              depositRate: calculateRateForCompound(currentEntry?.supplyRatePerBlock?.toString(), chainId, Lender.COMPOUND_V2),
              variableBorrowRate: calculateRateForCompound(currentEntry?.borrowRatePerBlock?.toString(), chainId, Lender.COMPOUND_V2),
              // deposits
              totalDebt: parseRawAmount(currentEntry?.totalBorrows?.toString(), decs),
              totalDeposits: totalSupplyUnderlying,
              totalDebtUSD: totalDebt * price,
              totalDepositsUSD: totalSupplyUnderlying * price,
              totalLiquidity,
              totalLiquidityUSD: totalLiquidity * price,
              stakingYield: stakingYields[asset] ?? 0,
              // flag
              isListed: Boolean(currentEntry?.isListed),
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor,
                  collateralFactor: borrowCollateralFactor,
                  borrowFactor: 1
                }
              },
              underlyingAssetAddress: currentEntry?.underlyingAssetAddress,
              cTokenDecimals: Number(currentEntry?.cTokenDecimals?.toString()),
              // rewards (TBU)
              compSupplySpeed: parseRawAmount(currentEntry?.compSupplySpeed?.toString(), 5),
              compBorrowSpeed: parseRawAmount(currentEntry?.borrowSpeedKey?.toString(), 5),
              // cap
              borrowCap: parseRawAmount(currentEntry?.borrowCap?.toString(), decs),
            },
          }
        })
      )
      return { data: result, chainId }
    }
  )
