import { createReducer } from '@reduxjs/toolkit'
import {
  Field,
  MarginTradeType,
  SupportedAssets,
  QuickActionType
} from 'types/1delta'
import {
  replaceEasyModeState,
  resetSelectionState,
  selectEasyModeCurrency,
  setRecipient,
  setEasyModeInteraction,
  setQuickActionType,
  typeInput,
} from './actions'

export interface EasyModeState {
  [Field.INPUT]: { currencyId: string | undefined }
  [Field.OUTPUT]: { currencyId: string | undefined }
  typedValue: string,
  independentField: Field
  marginTradeType: MarginTradeType
  quickActionType: QuickActionType
  baseCurrency: SupportedAssets
  recipient?: string | null
}

const defaultState: EasyModeState = {
  [Field.INPUT]: { currencyId: '' },
  [Field.OUTPUT]: { currencyId: '' },
  typedValue: '',
  independentField: Field.INPUT,
  marginTradeType: MarginTradeType.Open,
  quickActionType: QuickActionType.Deposit,
  baseCurrency: SupportedAssets.USDCE, // only relevant for Compound V3
  recipient: ''
}

export default createReducer<EasyModeState>(defaultState, (builder) =>
  builder
    .addCase(resetSelectionState, () => defaultState)
    .addCase(setEasyModeInteraction, (state, action) => {
      state.marginTradeType = action.payload.marginTradeType
    })
    .addCase(setQuickActionType, (state, action) => {
      state.quickActionType = action.payload.quickActionType
    })
    .addCase(selectEasyModeCurrency, (state, { payload: { currencyId, field } }) => {
      return {
        ...state,
        [field]: { currencyId },
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
    .addCase(
      replaceEasyModeState,
      (_, { payload: { typedValue, recipient, field, inputCurrencyId, outputCurrencyId } }) => {
        return {
          ...defaultState,
          [Field.INPUT]: {
            currencyId: inputCurrencyId ?? undefined,
          },
          [Field.OUTPUT]: {
            currencyId: outputCurrencyId ?? undefined,
          },
          independentField: field,
          typedValue,
          recipient,
        }
      }
    )
)
