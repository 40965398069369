import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import {
  getLendlePoolDataProviderContract,
} from 'hooks/1delta/use1DeltaContract'
import { Call, multicallViem } from 'utils/multicall'
import AAVE_POOL_DATA_PROVIDER_ABI from 'abis/lendle/ProtocolDataProvider.json'
import INCENTIVES_CONTROLLER_ABI from 'abis/lendle/IncentivesController.json'
import UNI_V2_PAIR_ABI from 'abis/uniswap/uniswap-v2-pair.json'
import { BPS, TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { addressesLendleCore, addressesLendleLTokens, addressesLendleVTokens } from 'hooks/1delta/addressesLendle'
import { getLendleTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { LENDER_MODE_NO_MODE, Lender } from 'types/lenderData/base'
import { formatAaveRawApyToApr } from 'utils/1delta/generalFormatters'
import { getLenderAssets } from 'constants/getAssets'
import { AaveV2TypeGetReserveConfigurationData, AaveV2TypeGetReserveDataIndexes } from '../aave-v2/fetchPublicData'

const LENDLE_PAIR_MANTLE = '0x4c57BE599d0e0414785943569E9B6A66dA79Aa6b'
const WMNT_USDT_PAIR = '0x3e5922cd0cec71dc2d60ec8b36aa4c05b7c1672f'

export enum AaveV2TypeIncentivesControllerPoolInfoIndexes {
  totalSupply = 0,
  allocPoint,
  lastRewardTime,
  accRewardPerShare,
  onwardIncentives,
}

interface LendlePoolReserveResponse {
  data: {
    [tokenSymbol: string]: {
      // token amounts
      totalDeposits: number;
      totalDebtStable: number;
      totalDebt: number;
      // USD amounts
      totalDepositsUSD: number;
      totalDebtStableUSD: number;
      totalDebtUSD: number;

      // reserve market data
      depositRate: number
      variableBorrowRate: number
      stableBorrowRate: number
      lastUpdateTimestamp?: number
      stakingYield: number

      // rewards
      collateralRewards: number
      borrowRewards: number

      // reserve config
      decimals?: number
      usageAsCollateralEnabled?: boolean
      hasStable?: boolean

      // frozen
      isActive?: boolean
      isFrozen?: boolean
    }
  }
  config: {
    [tokenSymbol: string]: {
      decimals: number;

      config: {
        [0]: {
          modeId: 0,
          // collateral factors
          borrowCollateralFactor: number,
          collateralFactor: number,
          borrowFactor: number
        }
      }

      // flags
      collateralActive: boolean;
      borrowingEnabled: boolean;
      hasStable: boolean;
      isActive: boolean;
      isFrozen: boolean;
    }
  }
  chainId: number
}

interface LendleReservesQueryParams {
  chainId: number
  prices: { [asset: string]: number }
  stakingYields: { [asset: string]: number; }
}


export const fetchLendlePublicData: AsyncThunk<LendlePoolReserveResponse, LendleReservesQueryParams, any> =
  createAsyncThunk<LendlePoolReserveResponse, LendleReservesQueryParams>(
    'lendle/fetchLendlePublicData',
    async ({ chainId, prices, stakingYields }) => {
      const providerContract = getLendlePoolDataProviderContract(chainId)
      const tokenDict = getLendleTokenAddresses(chainId)
      const assetsToQuery = getLenderAssets(chainId, Lender.LENDLE)
      const assets = assetsToQuery.map((a) => tokenDict[a])
      const names = Object.keys(tokenDict)
      const calls: Call[] = assets.map((tk) => {
        return {
          address: providerContract.address,
          name: 'getReserveData',
          params: [tk],
        }
      })
      const aTokenDict = getAddressesForChainIdFromAssetDict(addressesLendleLTokens, chainId, Lender.LENDLE)
      const aTokenNames = Object.keys(aTokenDict)
      const callsConfig: Call[] = assets.map((tk) => {
        return {
          address: providerContract.address,
          name: 'getReserveConfigurationData',
          params: [tk],
        }
      })

      const callsIncentives: Call[] = aTokenNames.map((tk) => {
        return {
          address: addressesLendleCore.IncentivesController[chainId],
          name: 'poolInfo',
          params: [aTokenDict[tk]],
        }
      })
      const vTokenDict = getAddressesForChainIdFromAssetDict(addressesLendleVTokens, chainId, Lender.LENDLE)
      const callBorrowIncentives: Call[] = aTokenNames.map((tk) => {
        return {
          address: addressesLendleCore.IncentivesController[chainId],
          name: 'poolInfo',
          params: [vTokenDict[tk]],
        }
      })

      const callRewardsPerSecond = {
        address: addressesLendleCore.IncentivesController[chainId],
        name: 'rewardsPerSecond',
        params: []
      }

      const callAllocPoint = {
        address: addressesLendleCore.IncentivesController[chainId],
        name: 'totalAllocPoint',
        params: []
      }

      const callReservesInPair = {
        address: LENDLE_PAIR_MANTLE,
        name: 'getReserves',
        params: []
      }


      const callReservesUsdtWmnt = {
        address: WMNT_USDT_PAIR,
        name: 'getReserves',
        params: []
      }

      let multicallResult: any[]
      try {
        if (calls.length > 0)
          multicallResult = await multicallViem(
            chainId,
            [...AAVE_POOL_DATA_PROVIDER_ABI, ...INCENTIVES_CONTROLLER_ABI, ...UNI_V2_PAIR_ABI],
            [
              ...calls, ...callsConfig, ...callsIncentives, ...callBorrowIncentives,
              callRewardsPerSecond, callAllocPoint, callReservesInPair, callReservesUsdtWmnt
            ],
            1 // secondary
          )
        else
          multicallResult = []
      } catch (err) {
        console.log('error', err)
        multicallResult = []
      }
      // slice the results
      const multicallResultReserves = multicallResult.slice(0, calls.length)
      const multicallResultRewards = multicallResult.slice(
        calls.length + callsConfig.length,
        calls.length + callsConfig.length + callsIncentives.length
      )
      const multicallResultBorrowRewards = multicallResult.slice(
        calls.length + callsConfig.length + callsIncentives.length,
        calls.length + callsConfig.length + callsIncentives.length + callBorrowIncentives.length
      )
      // calculate usd/asset per year reward payout
      const rewardsPerSecond = parseRawAmount(multicallResult[multicallResult.length - 4].toString(), 18)
      const totalAllocPoint = Number(multicallResult[multicallResult.length - 3].toString())
      const reserves = multicallResult[multicallResult.length - 2]
      // lendle and wmnt are respective token0s
      const lendleReserve = parseRawAmount(reserves[0].toString(), 18)
      const wmntReserve = parseRawAmount(reserves[1].toString(), 18)
      const reservesWmntUsdt = multicallResult[multicallResult.length - 1]
      const usdtReserve = parseRawAmount(reservesWmntUsdt[0].toString(), 6)
      const wmnt2Reserve = parseRawAmount(reservesWmntUsdt[1].toString(), 18)
      const lendPrice = wmntReserve / lendleReserve / wmnt2Reserve * usdtReserve

      const resultReserves: any = Object.assign(
        {},
        ...multicallResultReserves.map((entry: any, index) => {
          const asset = names[index]
          const decimals = TOKEN_META[asset]?.decimals ?? 18
          const totalStableDebt = parseRawAmount(entry?.[AaveV2TypeGetReserveDataIndexes.totalStableDebt]?.toString(), decimals)
          const totalVariableDebt = parseRawAmount(entry?.[AaveV2TypeGetReserveDataIndexes.totalVariableDebt]?.toString(), decimals)
          const liquidity = parseRawAmount(entry?.[AaveV2TypeGetReserveDataIndexes.availableLiquidity]?.toString(), decimals)
          const totalAToken = liquidity + totalStableDebt + totalVariableDebt
          const collateralRewards = Number(multicallResultRewards?.[index]?.[AaveV2TypeIncentivesControllerPoolInfoIndexes.allocPoint].toString()) / totalAllocPoint * rewardsPerSecond
          const borrowRewards = Number(multicallResultBorrowRewards?.[index]?.[AaveV2TypeIncentivesControllerPoolInfoIndexes.allocPoint].toString()) / totalAllocPoint * rewardsPerSecond
          const totalSupplyCollateral = parseRawAmount(multicallResultRewards?.[index]?.[AaveV2TypeIncentivesControllerPoolInfoIndexes.totalSupply]?.toString(), decimals)
          const totalSupplyBorrow = parseRawAmount(multicallResultBorrowRewards?.[index]?.[AaveV2TypeIncentivesControllerPoolInfoIndexes.totalSupply]?.toString(), decimals)
          const price = prices[asset] ?? 1

          return {
            [asset]: {
              // token amounts
              totalDeposits: totalAToken,
              totalDebtStable: totalStableDebt,
              totalDebt: totalVariableDebt,
              totalLiquidity: liquidity,
              // USD amountss
              totalDepositsUSD: totalAToken * price,
              totalDebtStableUSD: totalStableDebt * price,
              totalDebtUSD: totalVariableDebt * price,
              totalLiquidityUSD: liquidity * price,
              // rates
              depositRate: formatAaveRawApyToApr(entry?.[AaveV2TypeGetReserveDataIndexes.liquidityRate]?.toString()),
              variableBorrowRate: formatAaveRawApyToApr(entry?.[AaveV2TypeGetReserveDataIndexes.variableBorrowRate]?.toString()),
              stableBorrowRate: formatAaveRawApyToApr(entry?.[AaveV2TypeGetReserveDataIndexes.stableBorrowRate]?.toString()),
              stakingYield: stakingYields[asset] ?? 0,

              // misc
              lastUpdateTimestamp: entry?.[AaveV2TypeGetReserveDataIndexes.lastUpdateTimestamp],
              collateralRewards: convertRateToApr(collateralRewards / totalSupplyCollateral, lendPrice) / price,
              borrowRewards: convertRateToApr(totalSupplyBorrow > 0 ? borrowRewards / totalSupplyBorrow : 0, lendPrice) / price,
              stableBorrowRewards: 0,
            },
          }
        })
      )

      const multicallResultConfig = multicallResult.slice(calls.length, calls.length + callsConfig.length)
      const resultConfig: any = Object.assign(
        {},
        ...multicallResultConfig.map((entry: any, index) => {
          return {
            [aTokenNames[index]]: {
              decimals: Number(entry?.[AaveV2TypeGetReserveConfigurationData.decimals]),
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: Number(entry?.[AaveV2TypeGetReserveConfigurationData.ltv].toString()) / BPS,
                  collateralFactor: Number(entry?.[AaveV2TypeGetReserveConfigurationData.liquidationThreshold].toString()) / BPS,
                  borrowFactor: 1
                }
              },
              liquidationBonus: Number(entry?.[AaveV2TypeGetReserveConfigurationData.liquidationBonus].toString()) / BPS,

              // flags
              collateralActive: entry?.[AaveV2TypeGetReserveConfigurationData.usageAsCollateralEnabled],
              borrowingEnabled: entry?.[AaveV2TypeGetReserveConfigurationData.borrowingEnabled],
              hasStable: entry?.[AaveV2TypeGetReserveConfigurationData.stableBorrowRateEnabled],
              isActive: entry?.[AaveV2TypeGetReserveConfigurationData.isActive],
              isFrozen: entry?.[AaveV2TypeGetReserveConfigurationData.isFrozen],

            },
          }
        })
      )

      return {
        data: resultReserves,
        config: resultConfig,
        chainId,
      }
    }
  )

// converts rate per second to USD per year
const convertRateToApr = (ratePerSecond: number, price: number) => {
  return ratePerSecond * 3600 * 24 * 365 * price * 100
}