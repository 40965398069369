import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect, useState } from 'react'
import { fetchBlockDataAndBalances } from 'state/globalNetwork/fetchGeneralData'
import { useAllTokenAddresses, useSubscribeConfig } from 'state/globalNetwork/hooks'
import { useAppDispatch } from 'state/hooks'
import { useWeb3ReactWrapped } from 'hooks/web3'
import _ from 'lodash'

export default function usePolling() {
  // we call the hook to update chainId, account, provider etc.
  const { chainId } = useActiveWeb3React()
  const { account } = useWeb3ReactWrapped()

  // fetches timestamp and
  const [repeater, setRepeater] = useState(0)
  const dispatch = useAppDispatch()
  const { tokens, approvalTargets } = useSubscribeConfig(chainId)
  const additionalAddresses = useAllTokenAddresses()
  useEffect(() => {
    const tokensToQuery = _.uniq([...additionalAddresses, ...(tokens ?? [])])
    // we also directly fetch the native balance if connected
    dispatch(fetchBlockDataAndBalances({ chainId, account, assetsToQuery: tokensToQuery, approvalTargets }))
    const intervalId = setInterval(() => {
      setRepeater((prevState) => prevState + 1)
    }, 10000) // 10s
    return () => clearInterval(intervalId)
  }, [repeater, chainId, account, tokens, approvalTargets])
}
