import { createReducer } from '@reduxjs/toolkit'
import { resetState } from './actions'
import { fetchCompoundV2PublicData } from './fetchPublicData'
import { fetchCompoundV2UserData } from './fetchUserData'
import { CompoundV2State } from 'types/lenderData/compound-v2'
import { STATE_CHAINIDS } from 'constants/chains'

const emptyData = {
  publicLoaded: false,
  lenderData: {},
  userData: {},
  balanceData: {},
  aprData: {},
}

export const initialState: CompoundV2State = Object.assign(
  {}, ...STATE_CHAINIDS.map(cId => { return { [cId]: emptyData } })
)

export default createReducer<CompoundV2State>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    // new reserve data-fetch using aave data provider
    .addCase(fetchCompoundV2PublicData.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      const chainId = action.payload.chainId
      if (assetKeys.length === 0) return; // prevents setting load flags
      if (!state[chainId]) state[chainId] = emptyData
      // assign public data
      for (let i = 0; i < assetKeys.length; i++) {
        const asset = assetKeys[i]
        state[chainId].lenderData[asset] = {
          ...state[chainId].lenderData[asset],
          ...action.payload.data[asset],
        }
      }
      state[chainId].publicLoaded = true
    })
    .addCase(fetchCompoundV2PublicData.pending, (state) => {
      //
    })
    // user data from provider
    .addCase(fetchCompoundV2UserData.fulfilled, (state, action) => {

    })
    .addCase(fetchCompoundV2UserData.pending, (state) => {
      //
    })
)
