import { TOKEN_META } from "constants/1delta";
import { addressToAsset } from "hooks/1delta/addressesTokens";
import { parseRawAmount } from "utils/tableUtils/prices";
import { AaveTypeTx } from "../utils/types";
import { SupportedAssets } from "types/1delta";
import _ from "lodash"

/** This is for the lender action from subgraph txns */
export enum LenderAction {
  LONG = 'Long',
  SHORT = 'Short',
  DECREASE_LONG = 'Decrease Long',
  DECREASE_SHORT = 'Decrease Short',
  LIQUIDATION = 'Liquidation',
}

export const POLLING_INTERVAL = 60000;

export const LENDLE_SUBGRAPH_URL = 'https://subgraph.lendle.xyz/subgraphs/name/lendle-finance/lendle-finance-mantle-next'
export const LENDLE_SUBGRAPH_URL_BASE = 'https://subgraph.lendle.xyz/subgraphs/name/lendle-finance/lendle-finance-mantle'

export const LENDLE_WITHDRAWS_ROUTER = (hashes: string[]) => {
  return `
    query Withdrawals($first: Int, $skip: Int)
    {
      withdraws(
        first: $first
        skip: $skip
        where: {
          hash_in:["${hashes.join('","')}"]
        }
      ) {
      hash
      timestamp
      amountUSD
      amount
      market {
        id
      }
    }
    }
  `
}

export const LENDLE_TRADES = (user: string, refTime: number) => {
  return `
  query Trades($first: Int!, $skip: Int!) {
    repays(
      first: $first
      skip: $skip
      where: {
        sender: "${user}"
        timestamp_gt: ${refTime}
      }
    ) {
    hash
    timestamp
    amountUSD
    amount
    market {
      id
    }
  }
  borrows(
      first: $first
      skip: $skip
      where: {
        sender: "${user}"
        timestamp_gt: ${refTime}
      }
    ) {
    hash
    timestamp
    amountUSD
    amount
    market {
      id
    }
  }
  withdraws(
      first: $first
      skip: $skip
      where: {
        sender: "${user}"
        timestamp_gt: ${refTime}
      }
    ) {
    hash
    timestamp
    amountUSD
    amount
    market {
      id
    }
  }
  deposits(
      first: $first
      skip: $skip
      where: {
        sender: "${user}"
        timestamp_gt: ${refTime}
      }
    ) {
    hash
    timestamp
    amountUSD
    amount
    market {
      id
    }
  }
  }`
}


export interface LendleTxEntry {
  market: {
    id: string
  }
  hash: string
  amount: string
  amountUSD: string
  action: string
  timestamp: number
}

export interface LendleTrades {
  id: string
  borrows: LendleTxEntry[]
  withdraws: LendleTxEntry[]
  repays: LendleTxEntry[]
  deposits: LendleTxEntry[]
}

export const parseLendleTradeEntry = (entry: LendleTxEntry, chainId: number, plus = true, type = ''): AaveTypeTx => {
  const asset = addressToAsset(chainId, entry.market.id)
  const amountPositive = parseRawAmount(entry.amount, TOKEN_META[asset].decimals)
  const sign = plus ? 1 : -1
  const amount = sign * amountPositive
  return {
    asset,
    amount,
    type,
    amountUSD: sign * Number(entry.amountUSD),
    timestamp: entry.timestamp,
    hash: entry.hash
  }
}

export const mergeAndOrderTxArray = (txs: AaveTypeTx[] | undefined): AaveTypeTx[] => {
  if (!txs) return []
  const handledHashes: string[] = []
  const newTx: AaveTypeTx[] = []
  for (const tx of txs) {
    const hash = tx.hash
    if (handledHashes.includes(hash)) continue;
    handledHashes.push(hash)
    const otherTxs = txs.filter(t => t.hash === hash)
    if (otherTxs.length > 0) {
      let amount = 0
      let amountUSD = 0
      for (const otherTx of otherTxs) {
        amount += otherTx.amount
        amountUSD += otherTx.amountUSD
      }
      newTx.push({ ...tx, amount, amountUSD })
    } else {
      newTx.push(tx)
    }
  }
  return newTx
}

/**
 * Liquidation query
 * @param user address
 * @returns query string
 */
export const LENDLE_LIQUIDATIONS = (user: string, refTime: number) => {
  return `
  query Liquidations($first: Int, $skip: Int){
    liquidates(
      first: $first
      skip: $skip
      where: {
        liquidatee: "${user}"
        timestamp_gt: ${refTime}
      }
    ) {
      amount
      amountUSD
      profitUSD
      collateralTokenPriceUSD
      debtTokenPriceUSD
      debtAmountRepaid
      hash
      timestamp
      liquidatee {
        id
      }
      market {
        id
      }
      debtMarket {
        id
      }
    }
  }
  `
}

interface LendleLiquidationEntry {
  timestamp: number
  hash: string
  // collatearl data
  amount: string
  amountUSD: string
  profitUSD: string
  market: {
    id: string
  }
  // debt data
  debtAmountRepaid: string
  debtTokenPriceUSD: string
  debtMarket: {
    id: string
  }
}

export interface LendleLiquidations {
  id: string
  liquidates: LendleLiquidationEntry[]
}


/**
 * Liquidation parser -> returns withdrawal and repay tx
 * @param entry liq entry
 * @param chainId network to identify token metadata
 * @returns {withdarwal,repay}
 */
export const parseLendleLiquidationEntry = (entry: LendleLiquidationEntry, chainId: number): { withdrawal: AaveTypeTx, repay: AaveTypeTx } => {
  const asset = addressToAsset(chainId, entry.market.id) as SupportedAssets
  const withdrawAmount = parseRawAmount(entry.amount, TOKEN_META[asset].decimals)
  const withdrawal = {
    asset,
    amount: -withdrawAmount,
    amountUSD: -Number(entry.amountUSD) - Number(entry.profitUSD), // we have to subtract liquidation pnl, too
    timestamp: Number(entry.timestamp),
    hash: entry.hash,
    type: 'liquidation'
  }

  const assetRepay = addressToAsset(chainId, entry.debtMarket.id) as SupportedAssets
  const repayAmount = -parseRawAmount(entry.debtAmountRepaid, TOKEN_META[assetRepay].decimals)
  const repay = {
    asset: assetRepay,
    amount: repayAmount,
    amountUSD: repayAmount * Number(entry.debtTokenPriceUSD),
    timestamp: Number(entry.timestamp),
    hash: entry.hash,
    type: 'liquidation'
  }
  return {
    withdrawal,
    repay
  }
}


/**
 * Fetch current and historical prices
 * @param refTime time for historical prices 
 * @returns query string
 */
export const PRICES_HIST = (assets: {
  name: string;
  address: `0x${string}`;
}[], refTime: number) => {
  const assetQueries = assets.map(asset => `
      ${asset.name}: marketDailySnapshots(
        first: 1
        where: {
          market: "${asset.address}"
          timestamp_lt: ${refTime}
        }
        orderBy: timestamp
        orderDirection: desc
      ){
        inputTokenPriceUSD
        timestamp
        market{
          id
          name
        }
      }
    `).join("")

  return `
    query Markets {
      markets(first: ${assets.length}) {
        inputTokenPriceUSD
        inputToken {
          id
          symbol
        }
      }
      ${assetQueries}
    }
  `
}

interface CurrentPrice {
  inputTokenPriceUSD: string
  inputToken: {
    id: string
    symbol: string
  }
}

interface HistPrice {
  inputTokenPriceUSD: string
  timestamp: string
  market: {
    id: string
    name: string
  }
}

export interface PriceResponse {
  markets: CurrentPrice[]
  WMNT: HistPrice[]
  USDT: HistPrice[]
  USDC: HistPrice[]
  WETH: HistPrice[]
  WBTC: HistPrice[]
  METH: HistPrice[]
  USDE: HistPrice[]
}

export interface AggregatorResponse {
  [tokenSymbol: string]: number
}



/**
 * Trade fetcher
 * @param user address
 * @param refTime time to go back to
 * @param firsts allows to skip a fetch by enering 0 at the respective index
 *      [0] repays
 *      [1] borrows
 *      [2] withdraws
 *      [3] deposits
 * @returns query to fetch all trade types (does not include withdrawals via router)
 */
export const LENDLE_TRADES_GENERAL = (user: string, refTime: number, firsts: number[], skips: number[]) => {
  return `
  query Trades {
  ${firsts[0] === 0 ? '' :
      `
      repays(
        first: ${firsts[0]}
        skip: ${skips[0]}
        where: {
          sender: "${user}"
          timestamp_gt: ${refTime}
        }
      ) {
          hash
          timestamp
          amountUSD
          amount
          market {
            id
          }
      }
  `
    }
    ${firsts[1] === 0 ? '' :
      `
      borrows(
        first: ${firsts[1]}
        skip: ${skips[1]}
        where: {
          sender: "${user}"
          timestamp_gt: ${refTime}
        }
      ) {
        hash
        timestamp
        amountUSD
        amount
        market {
          id
        }
    }
  `
    }

    ${firsts[2] === 0 ? '' :
      `
    withdraws(
      first: ${firsts[2]}
      skip: ${skips[2]}
      where: {
        sender: "${user}"
        timestamp_gt: ${refTime}
      }
    ) {
    hash
    timestamp
    amountUSD
    amount
    market {
      id
    }
  }
`
    }
  ${firsts[3] === 0 ? '' :
      `
    deposits(
      first: ${firsts[3]}
      skip: ${skips[3]}
      where: {
        sender: "${user}"
        timestamp_gt: ${refTime}
      }
    ) {
    hash
    timestamp
    amountUSD
    amount
    market {
      id
    }
  }
`
    }
  }`
}