import { Redirect, RouteComponentProps } from 'react-router-dom'

// Redirects from the /swap/:outputCurrency path to the /swap?outputCurrency=:outputCurrency format
export function RedirectToYields(props: RouteComponentProps<{ chain: string, lender: string }>) {
  const {
    location,
    location: { search },
    match: {
      params: { chain, lender },
    },
  } = props

  return (
    <Redirect
      to={{
        ...location,
        pathname: '/yields',
        search:
          search && search.length > 1
            ? `${search}&chain=${chain}&lender=${lender}`
            : `?chain=${chain}&lender=${lender}`,
      }}
    />
  )
}
