import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Field, MarginTradeType, QuickActionType, SupportedAssets } from 'types/1delta'
import { selectMarginCurrency, setBaseCurrency, setRecipient, setMarginInteraction, typeInput, setQuickActionType } from './actions'
import { USDC_ON } from 'constants/tokens'
import { WRAPPED_NATIVE_CURRENCY } from '@1delta/base-sdk'
import { assetToAddress } from 'hooks/1delta/addressesTokens'

export const useSetBaseAsset = () => {
  const dispatch = useAppDispatch()
  return useCallback((asset: SupportedAssets) => {
    dispatch(setBaseCurrency({ asset }))
  }, [dispatch])
}

export const useBaseAsset = (): SupportedAssets => {
  return useAppSelector(state => state.margin.baseCurrency)
}

export const useSetQuickActionType = () => {
  const dispatch = useAppDispatch()
  return useCallback((quickActionType: QuickActionType) => {
    dispatch(setQuickActionType({ quickActionType }))
  }, [dispatch])
}

export const useGetQuickActionType = (): QuickActionType => {
  return useAppSelector(state => state.margin.quickActionType)
}

export function useMarginActionHandlers(chainId: number): {
  onAssetSelection: (field: Field, asset: SupportedAssets) => void
  onOperationChange: (op: MarginTradeType) => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
} {
  const dispatch = useAppDispatch()
  const onAssetSelection = useCallback(
    (field: Field, asset: SupportedAssets) => {

      dispatch(
        selectMarginCurrency({
          field,
          currencyId: assetToAddress(asset, chainId),
        })
      )
    },
    [dispatch, chainId]
  )

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch]
  )

  const onOperationChange = useCallback((marginTradeType: MarginTradeType) => {
    dispatch(setMarginInteraction({ marginTradeType }))
  },
    [dispatch]
  )

  return {
    onAssetSelection,
    onUserInput,
    onOperationChange,
    onChangeRecipient,
  }
}

export const useMarginAssetsAndOperation = (chainId: number): {
  currencyIdIn: string,
  currencyIdOut: string,
  marginTradeType: MarginTradeType
} => {
  const {
    [Field.INPUT]: ccyIn,
    [Field.OUTPUT]: ccyOut,
    marginTradeType
  } = useAppSelector(state => state.margin)

  return {
    currencyIdIn: ccyIn.currencyId ?? USDC_ON(chainId)?.address,
    currencyIdOut: ccyOut.currencyId ?? WRAPPED_NATIVE_CURRENCY[chainId]?.address,
    marginTradeType
  }
}
export const useSelectMarginTradeType = () => {
  const dispatch = useAppDispatch()
  const selectedMarginTradeType = useAppSelector(state => state.margin.marginTradeType)
  return useCallback((marginTradeType: MarginTradeType) => {
    if (marginTradeType !== selectedMarginTradeType)
      dispatch(setMarginInteraction({ marginTradeType }))
  },
    [dispatch, selectedMarginTradeType]
  )
}