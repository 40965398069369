import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'

interface Card {
  buttonBody: React.ReactNode
  panelBody: React.ReactNode
}

export const Card: React.FC<Card> = ({ buttonBody, panelBody }) => {
  return (
    <Accordion allowToggle w="100%" maxW={'340px'}>
      <AccordionItem borderRadius={'0.5rem'} border="none" overflow="auto">
        <AccordionButton
          padding="0.5rem 1rem"
          bg={validateColor('Surface/Surface-secondary')}
          color={validateColor('Text/Lables/Label-text-default')}
          _expanded={{
            bg: validateColor('Surface/Surface-tertiary'),
            _hover: {
              bg: validateColor('Surface/Surface-tertiary'),
            },
          }}
          _hover={{ bg: validateColor('Surface/Surface-secondary') }}
          maxW={'340px'}
        >
          {buttonBody}
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel
          borderBottomRadius={'0.5rem'}
          bg={validateColor('Surface/Surface-secondary')}
          padding={'0.75rem 1rem'}
        >
          {panelBody}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
