import { HamburgerIcon } from '@chakra-ui/icons'
import { SecondaryMediumButton } from '../Secondary'
import { MenuButton, MenuButtonProps } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'

interface HamburgerButtonProps extends MenuButtonProps {
  isOpen: boolean
}

export const HamburgerButton: React.FC<HamburgerButtonProps> = ({ isOpen, ...props }) => {
  return (
    <SecondaryMediumButton
      as={MenuButton}
      p="0"
      minW={0}
      border="none"
      w={'30px'}
      h={'30px'}
      _active={{
        backgroundColor: validateColor('Surface/Surface-tertiary'),
      }}
      {...props}
    >
      <HamburgerIcon
        w="1.35rem"
        h="1.35rem"
        color={isOpen ? validateColor('Text/Lables/Label-text-default') : validateColor('Icons/Icon-default')}
      />
    </SecondaryMediumButton>
  )
}
