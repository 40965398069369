import packageJson from "../package.json";

const VERSION_CACHE_KEY = 'version'

export function handleCaching() {
  let version = localStorage.getItem(VERSION_CACHE_KEY);
  if (!version || version != packageJson.version) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload();
    }

    localStorage.clear();
    localStorage.setItem(VERSION_CACHE_KEY, packageJson.version);
  }
};