// To define Events, refer to the best practices:
// https://docs.mixpanel.com/docs/data-structure/events-and-properties#best-practices

import mixpanel from "mixpanel-browser"
import { Event, Property, QuickActionEvent, TradeEvent, TradeErrorEvent, Page, VestedRewardsEvent, ClaimedRewardsEvent, EModeEvent } from "./types"
import { CHAIN_IDS_TO_PATH_NAMES } from "constants/chains";
import { Lender } from "types/lenderData/base"
import { MarketsView } from "components/Table/TokensTable/MarketsView";
import { MarketsFilter } from "components/Table/TokensTable";
import { Action } from "components/Table/HoverableRow";

const TOKEN_MIXPANEL = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || ""

export const initTracking = (account: string | undefined) => {
  try {
    if (TOKEN_MIXPANEL) {
      mixpanel.init(
        TOKEN_MIXPANEL,
        {
          debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
          track_pageview: true,
          persistence: 'localStorage',
          api_host: process.env.REACT_APP_MIXPANEL_PROXY_URL
        }
      );
      account && mixpanel.identify(account)
    }
  } catch { }
}

export const trackWalletConnected = (userAddress: string) => {
  try {
    mixpanel.identify(userAddress) // see https://docs.mixpanel.com/docs/tracking-methods/identifying-users#usage
    mixpanel.track(Event.WALLET_CONNECTED)
  } catch { }
}

export const trackWalletDisconnected = () => {
  try {
    mixpanel.track(Event.WALLET_DISCONNECTED)
    mixpanel.reset() // see https://docs.mixpanel.com/docs/tracking-methods/identifying-users#usage
  } catch { }
}

export const trackAccountSwitched = (userAddress: string) => {
  try {
    mixpanel.identify(userAddress)
    mixpanel.track(Event.ACCOUNT_SWITCHED)
  } catch { }
}

export const trackChainSelected = (chainId: number) => {
  try {
    mixpanel.track(
      Event.CHAIN_SELECTED,
      { [Property.CHAIN]: CHAIN_IDS_TO_PATH_NAMES[chainId] }
    )
  } catch { }
}

export const trackLenderSelected = (lender: Lender) => {
  try {
    mixpanel.track(
      Event.LENDER_SELECTED,
      { [Property.LENDER]: lender }
    )
  } catch { }
}

// generic for any quick action (modal)
export const trackQuickActionExecuted = (quickAction: QuickActionEvent) => {
  try {
    mixpanel.track(
      Event.QUICK_ACTION_EXECUTED,
      quickAction
    )
  } catch { }
}

// generic for any trade (main panel / swap)
export const trackTradeExecuted = (trade: TradeEvent) => {
  try {
    mixpanel.track(
      Event.TRADE_EXECUTED,
      trade
    )
  } catch { }
}

// track error on gas estimate
export const trackExecutionError = (error: TradeErrorEvent) => {
  try {
    mixpanel.track(
      Event.TRANSACTION_ERROR,
      error
    )
  } catch { }
}

export const trackPageViewed = (page: Page) => {
  try {
    mixpanel.track(
      Event.PAGE_VIEWED,
      { [Property.PAGE]: page }
    )
  } catch { }
}

export const trackMarketsViewSelected = (view: MarketsView) => {
  try {
    mixpanel.track(
      Event.MARKETS_VIEW_SELECTED,
      { [Property.MARKETS_VIEW]: view }
    )
  } catch { }
}

export const trackMarketsFilterSelected = (filter: MarketsFilter) => {
  try {
    mixpanel.track(
      Event.MARKETS_FILTER_SELECTED,
      { [Property.MARKETS_FILTER]: filter }
    )
  } catch { }
}

export const trackMarketsInputTyped = (query: string) => {
  try {
    mixpanel.track(
      Event.MARKETS_INPUT_TYPED,
      { [Property.SEARCHED_QUERY]: query }
    )
  } catch { }
}

export const trackRowActionClicked = (action: Action) => {
  try {
    mixpanel.track(
      Event.ROW_ACTION_CLICKED,
      { [Property.ROW_ACTION]: action }
    )
  } catch { }
}

export const trackCustomTokenAdded = (name: string | undefined, symbol: string, address: string, chainId: number) => {
  try {
    mixpanel.track(
      Event.CUSTOM_TOKEN_ADDED,
      {
        [Property.TOKEN_NAME]: name,
        [Property.TOKEN_SYMBOL]: symbol,
        [Property.TOKEN_ADDRESS]: address,
        [Property.CHAIN]: CHAIN_IDS_TO_PATH_NAMES[chainId]
      }
    )
  } catch { }
}

export const trackVestedRewards = (vestedRewards: VestedRewardsEvent) => {
  try {
    mixpanel.track(
      Event.REWARDS_VESTED,
      vestedRewards
    )
  } catch { }
}

export const trackClaimedRewards = (claimedRewards: ClaimedRewardsEvent) => {
  try {
    mixpanel.track(
      Event.REWARDS_CLAIMED,
      claimedRewards
    )
  } catch { }
}

export const trackEModeSwitched = (emodeEvent: EModeEvent) => {
  try {
    mixpanel.track(
      Event.EMODE_SWITCHED,
      emodeEvent
    )
  } catch { }
}
