import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getCometContract,
} from 'hooks/1delta/use1DeltaContract'
import { SupportedAssets } from 'types/1delta'
import { Call, multicallViem } from 'utils/multicall'

import COMET_ABI from 'abis/compound-v3/Comet.json'
import COMET_EXT_ABI from 'abis/compound-v3/CometExt.json'
import IR_GETTER_ABI from 'abis/compound-v3/IrGetter.json'
import CHAINLINK_AGGREGATOR_ABI from 'abis/chainlink/ChainLinkAggregator.json'

import { COMET_RISK_DECIMALS, POLYGON_CHAINS, TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { chainlinkOracles } from 'hooks/1delta/addresses'
import { calculateRateForCompound } from 'utils/tableUtils/format'
import { LENDER_MODE_NO_MODE, Lender } from 'types/lenderData/base'
import { compoundV3AssetKey } from './reducer'
import { addressesCompoundV3Core } from 'hooks/1delta/addressesCompoundV3'
import { getCompoundV3TokenAddresses } from 'hooks/lenders/lenderAddressGetter'

export enum ChainlinkAggregatorIndexes {
  roundId = 0,
  answer,
  startedAt,
  updatedAt,
  answeredInRound,
}

enum CometInterestRateIndexes {
  borrowRate = 0,
  supplyRate = 1,
  utilization = 2
}


export interface CompoundV3PublicResponse {
  data: {
    [tokenSymbol: string]: {

      config: {
        [0]: {
          modeId: 0,
          // collateral factors
          borrowCollateralFactor: number,
          collateralFactor: number,
          borrowFactor: 1
        }
      }

      liquidationFactor: string;
      supplyCap: number;
      // additional
      isBase: boolean;

      // interest rates
      variableBorrowRate: number;
      depositRate: number;

      utilization: number;

      totalDebt: number;
      // deposits are different outputs for base asset and others
      totalDeposits: number;
      // rewards
      collateralRewards: number;
      borrowRewards: number;
    }
  },
  chainId: number;
}

export interface CompoundV3PublicQueryParams {
  chainId: number,
  baseAsset: SupportedAssets
  prices: { [asset: string]: number }
  stakingYields: { [asset: string]: number; }
}

const SECONDS_PER_DAY = 3600 * 24
const DAYS_IN_YEAR = 365

export const fetchCompoundV3PublicData: AsyncThunk<CompoundV3PublicResponse, CompoundV3PublicQueryParams, any> =
  createAsyncThunk<CompoundV3PublicResponse, CompoundV3PublicQueryParams>(
    'compound-v3/fetchCompoundV3PublicData',

    async ({ chainId, baseAsset, prices, stakingYields }) => {
      if (!POLYGON_CHAINS.includes(chainId) || !addressesCompoundV3Core.irGetter[chainId]) return {
        chainId,
        data: {}
      }

      const rawAddressDict = getCompoundV3TokenAddresses(chainId)
      const cometContract = getCometContract(chainId, baseAsset)
      const names = Object.keys(rawAddressDict)
      const tokensNoBase = names.filter(a => a !== baseAsset)

      const callsAssetInfo: Call[] = tokensNoBase.map((tk) => {
        return {
          address: cometContract.address,
          name: 'getAssetInfoByAddress',
          params: [rawAddressDict[tk]],
        }
      })

      const callsTotals: Call[] = tokensNoBase.map((tk) => {
        return {
          address: cometContract.address,
          name: 'totalsCollateral',
          params: [rawAddressDict[tk]],
        }
      })

      const callSupplyRewards: Call = {
        address: cometContract.address,
        name: 'baseTrackingSupplySpeed',
        params: [],
      }

      const callBorrowRewards: Call = {
        address: cometContract.address,
        name: 'baseTrackingBorrowSpeed',
        params: [],
      }

      const callIndexScale: Call = {
        address: cometContract.address,
        name: 'baseIndexScale',
        params: [],
      }

      const callSupply: Call = {
        address: cometContract.address,
        name: 'totalSupply',
        params: [],
      }

      const callBorrow: Call = {
        address: cometContract.address,
        name: 'totalBorrow',
        params: [],
      }

      const callPrice: Call = {
        address: chainlinkOracles['COMP-USD'][chainId],
        name: 'latestRoundData',
        params: [],
      }

      const callInterest: Call = {
        address: addressesCompoundV3Core.irGetter[chainId],
        name: 'getCometInterest',
        params: [cometContract.address],
      }

      let ABI = [...IR_GETTER_ABI, ...COMET_ABI, ...CHAINLINK_AGGREGATOR_ABI, ...COMET_EXT_ABI]
      // remove duplicate definitions in ABI
      ABI = ABI.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj['name']).indexOf(obj['name']) === pos;
      });

      const multicallResult = await multicallViem(chainId,
        ABI,
        [
          // ...calls, // calls from lens
          ...callsAssetInfo, ...callsTotals,
          callSupplyRewards, callBorrowRewards, callIndexScale, // rewards data
          callSupply, callBorrow,  // supply / borrow
          callPrice, // call from chainlink oracles
          callInterest
        ],
        2
      )
      const baseLength = callsAssetInfo.length + callsTotals.length

      const resultsAssetInfo = multicallResult.slice(0, callsAssetInfo.length)
      const resultsTotals = multicallResult.slice(callsAssetInfo.length, baseLength)


      const result = Object.assign(
        {},
        ...tokensNoBase.map((asset, index) => {
          const decimals = TOKEN_META[asset]?.decimals ?? 18

          const assetInfo = resultsAssetInfo[index]
          const totals = parseRawAmount(resultsTotals[index][0], decimals)
          return {
            [compoundV3AssetKey(baseAsset, asset)]: {

              asset: assetInfo.asset,

              scale: assetInfo.scale.toString(),
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: parseRawAmount(assetInfo.borrowCollateralFactor.toString(), COMET_RISK_DECIMALS),
                  collateralFactor: parseRawAmount(assetInfo.liquidateCollateralFactor.toString(), COMET_RISK_DECIMALS),
                  borrowFactor: 1
                }
              },
              liquidationFactor: assetInfo.liquidationFactor.toString(),
              supplyCap: parseRawAmount(assetInfo.supplyCap.toString(), decimals),
              // additional
              isBase: false,

              // interest rates
              variableBorrowRate: 0,
              depositRate: 0,
              stakingYield: stakingYields[asset] ?? 0,

              utilization: 0,
              // debt and liquidity are zero
              totalDebt: 0,
              totalDebtUSD: 0,
              totalLiquidity: 0,
              totalLiquidityUSD: 0,
              // deposits are different outputs for base asset and others
              totalDeposits: totals,
              totalDepositsUSD: totals * (prices[asset] ?? 1),
              // rewards
              collateralRewards: 0,
              borrowRewards: 0,
              stableBorrowRewards: 0,
              borrowingEnabled: false
            }
          }
        })
      )

      // parse data for rewards calculation
      const resultsSupplyRewards = Number(multicallResult[baseLength]?.toString())
      const resultsBorrowRewards = Number(multicallResult[baseLength + 1]?.toString())
      const resultsIndexScale = multicallResult[baseLength + 2]?.toString()
      const resultsSupply = parseRawAmount(multicallResult[baseLength + 3]?.toString(), 6)
      const resultsBorrow = parseRawAmount(multicallResult[baseLength + 4]?.toString(), 6)
      const resultsRewardPrice = parseRawAmount(multicallResult[baseLength + 5]?.[ChainlinkAggregatorIndexes.answer], 8)


      const compToSuppliersPerDay = resultsSupplyRewards / Number(resultsIndexScale) * SECONDS_PER_DAY;
      const compToBorrowersPerDay = resultsBorrowRewards / Number(resultsIndexScale) * SECONDS_PER_DAY;

      const resultsInterest = multicallResult[baseLength + 6]

      const price = prices[baseAsset] ?? 1
      const liquidity = resultsSupply - resultsBorrow
      const baseData = {
        [compoundV3AssetKey(baseAsset, baseAsset)]: {

          asset: 'base',

          // collateral factots
          borrowCollateralFactor: 0,
          collateralFactor: 0,

          liquidationFactor: 0,
          supplyCap: 0,
          // additional
          isBase: false,
          config: {
            [LENDER_MODE_NO_MODE]: {
              modeId: LENDER_MODE_NO_MODE,
              // collateral factors
              borrowCollateralFactor: 0,
              collateralFactor: 0,
              borrowFactor: 1
            }
          },
          // interest rates
          variableBorrowRate: calculateRateForCompound(resultsInterest[CometInterestRateIndexes.borrowRate].toString(), chainId, Lender.COMPOUND_V3),
          depositRate: calculateRateForCompound(resultsInterest[CometInterestRateIndexes.supplyRate].toString(), chainId, Lender.COMPOUND_V3),

          utilization: parseRawAmount(resultsInterest[CometInterestRateIndexes.utilization].toString(), COMET_RISK_DECIMALS),

          totalLiquidity: liquidity,
          totalLiquidityUSD: liquidity * price,

          totalDebt: resultsBorrow,
          totalDeposits: resultsSupply,

          totalDebtUSD: resultsBorrow * price,
          totalDepositsUSD: resultsSupply * price,
          // rewards
          collateralRewards: (resultsRewardPrice * compToSuppliersPerDay / resultsSupply) * DAYS_IN_YEAR * 100 / price,
          borrowRewards: (resultsRewardPrice * compToBorrowersPerDay / resultsBorrow) * DAYS_IN_YEAR * 100 / price,
          stableBorrowRewards: 0,
          borrowingEnabled: true,
          stakingYield: stakingYields[baseAsset] ?? 0,
        }
      }

      return { data: { ...result, ...baseData }, chainId }
    }
  )
