import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'

export const addressesAaveCore: AddressDictionary = {
  UiIncentiveDataProviderV3: {
    [SupportedChainId.POLYGON]: '0xF43EfC9789736BaF550DC016C7389210c43e7997',
  },
  AaveOracle: {
    [SupportedChainId.POLYGON]: '0xb023e699F5a33916Ea823A16485e259257cA8Bd1',
  },
  FallbackOracleAave: {
  },
  PoolProxy: {
    [SupportedChainId.POLYGON]: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // marked as pool in docs
    [SupportedChainId.MANTLE]: '0xCFa5aE7c2CE8Fadc6426C1ff872cA45378Fb7cF3'
  },
  PoolDataProvider: {
    [SupportedChainId.POLYGON]: '0x69FA688f1Dc47d4B5d8029D5a35FB7a548310654',
    [SupportedChainId.MANTLE]: '0x552b9e4bae485C4B7F540777d7D25614CdB84773'
  },
  ghoOracle: {
  },
  PoolDataProviderGhst: {
  },
  ghst: {
  },
}

export const addressesAaveATokens: AddressDictionary = {
  [SupportedAssets.AAVE]: {
    [SupportedChainId.POLYGON]: '0xf329e36C7bF6E5E86ce2150875a84Ce77f477375',
  },
  [SupportedAssets.AGEUR]: {
    [SupportedChainId.POLYGON]: '0x8437d7c167dfb82ed4cb79cd44b7a32a1dd95c77',
  },
  [SupportedAssets.BAL]: {
    [SupportedChainId.POLYGON]: '0x8ffDf2DE812095b1D19CB146E4c004587C0A0692',
  },
  [SupportedAssets.CRV]: {
    [SupportedChainId.POLYGON]: '0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf',
  },
  [SupportedAssets.DAI]: {
    [SupportedChainId.POLYGON]: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
  },
  [SupportedAssets.DPI]: {
    [SupportedChainId.POLYGON]: '0x724dc807b04555b71ed48a6896b6F41593b8C637',
  },
  [SupportedAssets.EURS]: {
    [SupportedChainId.POLYGON]: '0x38d693cE1dF5AaDF7bC62595A37D667aD57922e5',
  },
  [SupportedAssets.GHST]: {
    [SupportedChainId.POLYGON]: '0x8Eb270e296023E9D92081fdF967dDd7878724424',
  },
  [SupportedAssets.JEUR]: {
    [SupportedChainId.POLYGON]: '0x6533afac2E7BCCB20dca161449A13A32D391fb00',
  },
  [SupportedAssets.LINK]: {
    [SupportedChainId.POLYGON]: '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
  },
  [SupportedAssets.SUSHI]: {
    [SupportedChainId.POLYGON]: '0xc45A479877e1e9Dfe9FcD4056c699575a1045dAA',
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.POLYGON]: '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.POLYGON]: '0xA4D94019934D8333Ef880ABFFbF2FDd611C762BD'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.POLYGON]: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.POLYGON]: '0x078f358208685046a11C85e8ad32895DED33A249',
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON]: '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.POLYGON]: '0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97',
  },
  [SupportedAssets.MAI]: {
    [SupportedChainId.POLYGON]: '0xeBe517846d0F36eCEd99C735cbF6131e1fEB775D',
  },
  'MIMATIC': {
    [SupportedChainId.POLYGON]: '0xeBe517846d0F36eCEd99C735cbF6131e1fEB775D',
  },
  [SupportedAssets.MATICX]: {
    [SupportedChainId.POLYGON]: '0x80cA0d8C38d2e2BcbaB66aA1648Bd1C7160500FE',
  },
  [SupportedAssets.STMATIC]: {
    [SupportedChainId.POLYGON]: '0xEA1132120ddcDDA2F119e99Fa7A27a0d036F7Ac9',
  },
  [SupportedAssets.GHO]: {
  },
  [SupportedAssets.WSTETH]: {
    [SupportedChainId.POLYGON]: '0xf59036CAEBeA7dC4b86638DFA2E3C97dA9FcCd40'
  }
}

export const addressesAaveVTokens: AddressDictionary = {
  [SupportedAssets.AAVE]: {
    [SupportedChainId.POLYGON]: '0xE80761Ea617F66F96274eA5e8c37f03960ecC679',
  },
  [SupportedAssets.AGEUR]: {
    [SupportedChainId.POLYGON]: '0x3ca5fa07689f266e907439afd1fbb59c44fe12f6',
  },
  [SupportedAssets.BAL]: {
    [SupportedChainId.POLYGON]: '0xA8669021776Bc142DfcA87c21b4A52595bCbB40a',
  },
  [SupportedAssets.CRV]: {
    [SupportedChainId.POLYGON]: '0x77CA01483f379E58174739308945f044e1a764dc',
  },
  [SupportedAssets.DAI]: {
    [SupportedChainId.POLYGON]: '0x8619d80FB0141ba7F184CbF22fd724116D9f7ffC',
  },
  [SupportedAssets.DPI]: {
    [SupportedChainId.POLYGON]: '0xf611aeb5013fd2c0511c9cd55c7dc5c1140741a6',
  },
  [SupportedAssets.EURS]: {
    [SupportedChainId.POLYGON]: '0x5D557B07776D12967914379C71a1310e917C7555',
  },
  [SupportedAssets.GHST]: {
    [SupportedChainId.POLYGON]: '0xce186f6cccb0c955445bb9d10c59cae488fea559',
  },
  [SupportedAssets.JEUR]: {
    [SupportedChainId.POLYGON]: '0x44705f578135cC5d703b4c9c122528C73Eb87145',
  },
  [SupportedAssets.LINK]: {
    [SupportedChainId.POLYGON]: '0x953A573793604aF8d41F306FEb8274190dB4aE0e',
  },
  [SupportedAssets.SUSHI]: {
    [SupportedChainId.POLYGON]: '0x34e2eD44EF7466D5f9E0b782B5c08b57475e7907',
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.POLYGON]: '0xFCCf3cAbbe80101232d343252614b6A3eE81C989',
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.POLYGON]: '0xE701126012EC0290822eEA17B794454d1AF8b030'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.POLYGON]: '0xfb00AC187a8Eb5AFAE4eACE434F493Eb62672df7',
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.POLYGON]: '0x92b42c66840C7AD907b4BF74879FF3eF7c529473',
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON]: '0x0c84331e39d6658Cd6e6b9ba04736cC4c4734351',
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.POLYGON]: '0x4a1c3aD6Ed28a636ee1751C69071f6be75DEb8B8',
  },
  [SupportedAssets.MAI]: {
    [SupportedChainId.POLYGON]: '0x18248226C16BF76c032817854E7C83a2113B4f06',
  },
  [SupportedAssets.MATICX]: {
    [SupportedChainId.POLYGON]: '0xB5b46F918C2923fC7f26DB76e8a6A6e9C4347Cf9',
  },
  [SupportedAssets.STMATIC]: {
    [SupportedChainId.POLYGON]: '0x18248226C16BF76c032817854E7C83a2113B4f06',
  },
  [SupportedAssets.GHO]: {
  },
  [SupportedAssets.WSTETH]: {
    [SupportedChainId.POLYGON]: '0x77fA66882a8854d883101Fb8501BD3CaD347Fc32'
  }
}

export const addressesAaveSTokens: AddressDictionary = {
  [SupportedAssets.AAVE]: {
    [SupportedChainId.POLYGON]: '0xfAeF6A702D15428E588d4C0614AEFb4348D83D48',
  },
  [SupportedAssets.AGEUR]: {
    [SupportedChainId.POLYGON]: '0x40B4BAEcc69B882e8804f9286b12228C27F8c9BF',
  },
  [SupportedAssets.BAL]: {
    [SupportedChainId.POLYGON]: '0xa5e408678469d23efdb7694b1b0a85bb0669e8bd',
  },
  [SupportedAssets.CRV]: {
    [SupportedChainId.POLYGON]: '0x08Cb71192985E936C7Cd166A8b268035e400c3c3',
  },
  [SupportedAssets.DAI]: {
    [SupportedChainId.POLYGON]: '0xd94112B5B62d53C9402e7A60289c6810dEF1dC9B',
  },
  [SupportedAssets.DPI]: {
    [SupportedChainId.POLYGON]: '0xDC1fad70953Bb3918592b6fCc374fe05F5811B6a',
  },
  [SupportedAssets.EURS]: {
    [SupportedChainId.POLYGON]: '0x8a9FdE6925a839F6B1932d16B36aC026F8d3FbdB',
  },
  [SupportedAssets.GHST]: {
    [SupportedChainId.POLYGON]: '0x3ef10dff4928279c004308ebadc4db8b7620d6fc',
  },
  [SupportedAssets.JEUR]: {
    [SupportedChainId.POLYGON]: '0x6B4b37618D85Db2a7b469983C888040F7F05Ea3D',
  },
  [SupportedAssets.LINK]: {
    [SupportedChainId.POLYGON]: '0x89D976629b7055ff1ca02b927BA3e020F22A44e4',
  },
  [SupportedAssets.SUSHI]: {
    [SupportedChainId.POLYGON]: '0x78246294a4c6fBf614Ed73CcC9F8b875ca8eE841',
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.POLYGON]: '0x307ffe186F84a3bc2613D1eA417A5737D69A7007',
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.POLYGON]: '0x70eFfc565DB6EEf7B927610155602d31b670e802',
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.POLYGON]: '0x633b207Dd676331c413D4C013a6294B0FE47cD0e',
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON]: '0xD8Ad37849950903571df17049516a5CD4cbE55F6',
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.POLYGON]: '0xF15F26710c827DDe8ACBA678682F3Ce24f2Fb56E',
  },
  [SupportedAssets.GHO]: {
  }
}
