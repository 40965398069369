import { SupportedChainId } from "constants/chains"
import {
  AggregatedDataDict,
  aggreagateTxns,
  calculateFullLendleDataFromTxns,
  dictToArray,
  mergeDicts,
  produceOriginalBalance
} from "../utils"
import { TxMap } from "../utils/types"
import { fetchAllLendlePrices } from "./fetchPrices"
import {
  LENDLE_LIQUIDATIONS,
  LENDLE_SUBGRAPH_URL,
  LENDLE_TRADES_GENERAL,
  LenderAction,
  LendleLiquidations,
  LendleTrades,
  parseLendleLiquidationEntry,
  parseLendleTradeEntry
} from "./queries"
import _ from "lodash"

const EMPTY_PNL = { pnlsLong: {}, pnlsShort: {} }

const EMPTY_DATA = {
  pnl: EMPTY_PNL, success: false, chainId: 0, txns: [],
  balanceHistLong: {}, balanceHistShort: {}, refBalance: 0,
  prices: {}
}

export async function fetchCompletePnLDataLendle(
  chainId: number,
  balancesLong: AggregatedDataDict,
  balancesShort: AggregatedDataDict,
  account?: string | undefined,
  refTime?: number
) {
  if (!refTime || !chainId || !account || (chainId !== SupportedChainId.MANTLE)) return EMPTY_DATA
  const { prices, histPrices } = await fetchAllLendlePrices(refTime)
  const subgraphUrl = LENDLE_SUBGRAPH_URL
  const {
    success: successFetchHistory,
    txDictShorts,
    txDictLongs,
  } = await fetchLendleTransactionHistory({
    chainId,
    account,
    subgraphUrl,
    refTime
  })

  if (!successFetchHistory) return EMPTY_DATA

  const {
    success: successLiq,
    txDictShorts: liquidationShorts,
    txDictLongs: liquidationLongs,
  } = await fetchLendleLiquidations({
    chainId,
    account,
    first: 250,
    skip: 0,
    subgraphUrl,
    refTime
  })

  if (!successLiq) return EMPTY_DATA

  // merge liquidations
  const allLongs = mergeDicts(txDictLongs, liquidationLongs)
  const allShorts = mergeDicts(txDictShorts, liquidationShorts)

  const aggregatedLongDict = aggreagateTxns(allLongs)
  const aggregatedShortDict = aggreagateTxns(allShorts)

  const initialLongBalances = produceOriginalBalance(balancesLong, aggregatedLongDict, histPrices)
  const initialShortBalances = produceOriginalBalance(balancesShort, aggregatedShortDict, histPrices)

  const refBalance = getRefBalance(
    initialLongBalances,
    initialShortBalances,
    allLongs,
    allShorts
  )

  const { pnl: pnlsLong, balances: balanceHistLong, txDictEnriched: enrichedLong } = calculateFullLendleDataFromTxns(initialLongBalances, balancesLong, allLongs, true, refTime)
  const { pnl: pnlsShort, balances: balanceHistShort, txDictEnriched: enrichedShort } = calculateFullLendleDataFromTxns(initialShortBalances, balancesShort, allShorts, false, refTime)

  return {
    success: true,
    prices,
    pnl: {
      pnlsLong,
      pnlsShort,
    },
    refBalance,
    balanceHistLong,
    balanceHistShort,
    txns: dictToArray(mergeDicts(enrichedLong, enrichedShort))
  }
}


interface TransactionHistoryParams {
  chainId: number
  refTime: number
  account: string;
  subgraphUrl: string;
}

interface TransactionHistoryLiquidationParams extends TransactionHistoryParams {
  first: number;
  skip: number;
}

interface TxReturn {
  txDictShorts: TxMap;
  txDictLongs: TxMap;
  success: boolean;
}

const MAX_ITERATIONS = 5;

const MAX_FETCH_AMOUNT = 250

// fetch user trqnsactions covering direct withdrawals, borrows, deposits and repays
export const fetchLendleTransactionHistory = async ({
  chainId,
  refTime,
  account,
  subgraphUrl,
}: TransactionHistoryParams): Promise<TxReturn> => {
  let txDictShorts: TxMap = {}
  let txDictLongs: TxMap = {}
  // firsts are safe amount of entries to fetch / skips are the ones that 
  let firsts = [MAX_FETCH_AMOUNT, MAX_FETCH_AMOUNT, MAX_FETCH_AMOUNT, MAX_FETCH_AMOUNT]
  let skips = [0, 0, 0, 0]
  // we cap the amount of iterations
  let iterationIndex = 0;
  while (_.sum(firsts) !== 0 && iterationIndex <= MAX_ITERATIONS) {
    const query = LENDLE_TRADES_GENERAL(account.toLowerCase(), refTime, firsts, skips);
    const requestBody = {
      query,
      variables: {},
    }
    try {
      const response = await fetch(subgraphUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`Network error: ${response.status} - ${response.statusText}`);
      }

      const data: any = await response.json();
      const reserve: LendleTrades = data.data

      // we use the return length
      let entriesLength = reserve?.borrows?.length ?? 0
      skips[0] += entriesLength // skip at least the amount fetched
      firsts[0] = entriesLength >= MAX_FETCH_AMOUNT ? MAX_FETCH_AMOUNT : 0 // only fetch if more than MAX_FETCH_AMOUNT entries
      for (let borrowTx of reserve?.borrows ?? []) {
        const parsedBorrow = parseLendleTradeEntry(borrowTx, chainId, true, LenderAction.SHORT)
        if (!txDictShorts[parsedBorrow.asset]) txDictShorts[parsedBorrow.asset] = []
        txDictShorts[parsedBorrow.asset].push(parsedBorrow)
      }
      entriesLength = reserve?.repays?.length ?? 0
      skips[1] += entriesLength
      firsts[1] = entriesLength >= MAX_FETCH_AMOUNT ? MAX_FETCH_AMOUNT : 0
      for (let repayTx of reserve?.repays ?? []) {
        const parsedRepays = parseLendleTradeEntry(repayTx, chainId, false, LenderAction.DECREASE_SHORT)
        if (!txDictShorts[parsedRepays.asset]) txDictShorts[parsedRepays.asset] = []
        txDictShorts[parsedRepays.asset].push(parsedRepays)
      }
      entriesLength = reserve?.deposits?.length ?? 0
      skips[2] += entriesLength
      firsts[2] = entriesLength >= MAX_FETCH_AMOUNT ? MAX_FETCH_AMOUNT : 0
      for (let supplyTx of reserve?.deposits ?? []) {
        const parsedSupplies = parseLendleTradeEntry(supplyTx, chainId, true, LenderAction.LONG)
        if (!txDictLongs[parsedSupplies.asset]) txDictLongs[parsedSupplies.asset] = []
        txDictLongs[parsedSupplies.asset].push(parsedSupplies)
      }
      entriesLength = reserve?.withdraws?.length ?? 0
      skips[3] += entriesLength
      firsts[3] = entriesLength >= MAX_FETCH_AMOUNT ? MAX_FETCH_AMOUNT : 0
      for (let redeemTx of reserve?.withdraws ?? []) {
        const parsedRedeems = parseLendleTradeEntry(redeemTx, chainId, false, LenderAction.DECREASE_LONG)
        if (!txDictLongs[parsedRedeems.asset]) txDictLongs[parsedRedeems.asset] = []
        txDictLongs[parsedRedeems.asset].push(parsedRedeems)
      }

    } catch (error) {
      console.error('Error fetching transaction history:', error);
      return {
        txDictShorts: {},
        txDictLongs: {},
        success: false,
      }
    }
    iterationIndex += 1;
  }
  return {
    txDictShorts,
    txDictLongs,
    success: true,
  }
};

interface TxReturn {
  txDictShorts: TxMap;
  txDictLongs: TxMap;
  success: boolean;
}

// fetch and parse liquidations
export const fetchLendleLiquidations = async ({
  chainId,
  account,
  subgraphUrl,
  refTime,
  skip,
  first
}: TransactionHistoryLiquidationParams): Promise<TxReturn> => {
  // we assume that there are not that manyn liquidations
  const query = LENDLE_LIQUIDATIONS(account.toLowerCase(), refTime);

  const requestBody = {
    query,
    variables: { first, skip },
  };
  try {
    const response = await fetch(subgraphUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw new Error(`Network error: ${response.status} - ${response.statusText}`);
    }

    const data: any = await response.json();
    const liqData: LendleLiquidations = data.data
    let txDictShorts: TxMap = {}
    let txDictLongs: TxMap = {}
    for (let liquidation of liqData.liquidates) {
      const { withdrawal, repay } = parseLendleLiquidationEntry(liquidation, chainId)
      const parsedRepays = repay
      if (!txDictShorts[parsedRepays.asset]) txDictShorts[parsedRepays.asset] = []
      txDictShorts[parsedRepays.asset].push(parsedRepays)
      const parsedRedeems = withdrawal
      if (!txDictLongs[parsedRedeems.asset]) txDictLongs[parsedRedeems.asset] = []
      txDictLongs[parsedRedeems.asset].push(parsedRedeems)
    }
    return {
      txDictShorts,
      txDictLongs,
      success: true
    }
  } catch (error) {
    console.error('Error fetching transaction history:', error);
    return {
      txDictShorts: {},
      txDictLongs: {},
      success: false
    }
  }
};

// calcultate net asset value as collateral minus debt over all assets
const getNav = (
  longBalances: AggregatedDataDict,
  shortBalances: AggregatedDataDict
) => {
  let long = 0
  let short = 0
  for (let k in longBalances) {
    long += longBalances[k].amountUSD
  }

  for (let k in shortBalances) {
    short += shortBalances[k].amountUSD
  }

  return long - short
}


/**
 * We match hashes of deposits and other tx types and remove these from the deposits
 * THes deposits plus initial nav are the ref amount
 * @param startDeposit initial net asset value
 * @param dictLong long txns
 * @param dictShort short txns
 * @returns 
 */
const filterHashesAndCalculateRef = (
  startDeposit: number,
  dictLong: TxMap,
  dictShort: TxMap
) => {

  let relevants: string[] = [] // depo hashes
  let allOther: string[] = [] // non depos
  for (let k in dictLong) {
    const baseDepos = dictLong[k].filter(a => a.type === LenderAction.LONG).map(a => a.hash)
    relevants = [...relevants, ...baseDepos]
    const nonDepos = dictLong[k].filter(a => a.type !== LenderAction.LONG).map(a => a.hash)
    allOther = [...allOther, ...nonDepos]
  }

  for (let k in dictShort) {
    const baseDepos = dictShort[k].map(a => a.hash)
    allOther = [...allOther, ...baseDepos]
  }
  // we check all relevant deposit hashes and filter matching
  // non-depsit hashes out 
  const hashesToInclude = _.uniq(relevants.filter(h => !allOther.includes(h)))

  let refBalance = startDeposit
  for (let k in dictLong) {
    refBalance += _.sumBy(
      dictLong[k].filter(
        a => a.type === LenderAction.LONG && hashesToInclude.includes(a.hash)
      ), a => a.amountUSD
    )
  }

  return refBalance
}

const safeCeilZero = (n: number) => {
  return isNaN(n) ? 0 : Math.max(n, 0)
}

export const getRefBalance = (
  initialLongBalances: AggregatedDataDict,
  initialShortBalances: AggregatedDataDict,
  allLongs: TxMap,
  allShorts: TxMap
) => {
  return filterHashesAndCalculateRef(
    safeCeilZero(getNav(initialLongBalances, initialShortBalances)), // sometimes interest dust can create negative values
    allLongs,
    allShorts
  )
} 