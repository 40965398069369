// eslint-disable-next-line no-restricted-imports
import { useMemo } from 'react'
import { AlertTriangle } from 'react-feather'
import { useAppSelector } from 'state/hooks'
import { getConnection } from 'connection'
import { useIsMobile } from 'hooks/useIsMobile'
import { useWeb3ReactWrapped } from 'hooks/web3'
import { Drawer, DrawerContent, HStack, Spinner, Text } from '@chakra-ui/react'
import WalletMenu from 'components/WalletMenu'
import { useWalletMenuDisclosure } from 'state/application/hooks'
import { SecondaryMediumButton } from 'components/Button/Secondary'
import { validateColor } from 'theme/colors'
import StatusIcon from 'components/Identicon/StatusIcon'
import { TransactionDetails } from 'state/transactions/types'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { shortenAddress } from 'utils'
import React from 'react'
import { IconButtonWithTooltip } from 'components/Button/Action/IconButtonWithTooltip'
import { useUserTheme } from 'state/user/hooks'
import { arrowRightIcon } from 'constants/1delta'

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner({ isOpen }: { isOpen: boolean }) {
  const { account, connector, chainId, ENSName } = useWeb3ReactWrapped()
  const connectionType = getConnection(connector).type

  const error = useAppSelector((state) => state.connection.errorByConnectionType[getConnection(connector).type])

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const isMobile = useIsMobile()

  if (!chainId) {
    return null
  } else if (error) {
    return (
      <HStack
        flexWrap="nowrap"
        boxSizing="border-box"
        width="100%"
        alignItems="center"
        borderRadius="5px"
        cursor="pointer"
        userSelect="none"
        background="transparent"
      >
        <AlertTriangle
          style={{
            marginLeft: '0.25rem',
            marginRight: '0.5rem',
            width: '16px',
            height: '16px',
            color: validateColor('Text/Lables/Lable-error'),
          }}
        />
        <Text color={validateColor('Text/Lables/Lable-error')}>Error</Text>
      </HStack>
    )
  } else if (account) {
    return (
      <HStack
        width="100%"
        alignItems="center"
        borderRadius="5px"
        cursor="pointer"
        userSelect="none"
        minW={isMobile ? '60px' : 'none'}
      >
        {!hasPendingTransactions && !isMobile && <StatusIcon size={17} />}
        {hasPendingTransactions ? (
          <HStack gap="0.375rem">
            <Text>{pending?.length} Pending</Text>{' '}
            <Spinner stroke={validateColor('Text/Lables/Label-text-default')} size="xs" />
          </HStack>
        ) : (
          <Text>{ENSName || shortenAddress(account, 4, isMobile)}</Text>
        )}
      </HStack>
    )
  } else {
    return (
      <HStack
        flexWrap="nowrap"
        boxSizing="border-box"
        width="auto"
        alignItems="center"
        borderRadius="5px"
        cursor="pointer"
        userSelect="none"
      >
        <Text>{isMobile ? 'Connect' : 'Connect Wallet'}</Text>
      </HStack>
    )
  }
}

const CloseDrawerButton = ({ onClose }: { onClose: () => void }) => {
  const theme = useUserTheme()
  const arrowRightSrc = arrowRightIcon[theme]
  return (
    <IconButtonWithTooltip
      position="fixed"
      right="28rem"
      src={arrowRightSrc}
      bg="transparent"
      w={'3rem'}
      h={'3rem'}
      imageProps={{
        w: '2rem',
        h: '2rem',
      }}
      _hover={{
        bg: 'transparent',
      }}
      _active={{
        bg: 'transparent',
      }}
      onClick={onClose}
    />
  )
}

export default function Web3Status({ isMobile }: { isMobile: boolean }) {
  const { walletMenuOpen, onCloseWalletMenu, onToggleWalletMenu } = useWalletMenuDisclosure()
  const btnRef = React.useRef<HTMLButtonElement>(null)
  return (
    <>
      <SecondaryMediumButton onClick={onToggleWalletMenu} p="0.25rem 0.5rem" h="30px" w="auto" ref={btnRef}>
        <Web3StatusInner isOpen={walletMenuOpen} />
      </SecondaryMediumButton>
      <Drawer isOpen={walletMenuOpen} placement="right" onClose={onCloseWalletMenu} finalFocusRef={btnRef} size="sm">
        <DrawerContent
          bg={validateColor('Surface/Surface-primary')}
          borderLeftRadius={isMobile ? '0' : '1rem'}
          shadow="dark"
        >
          {!isMobile && <CloseDrawerButton onClose={onCloseWalletMenu} />}
          <WalletMenu />
        </DrawerContent>
      </Drawer>
    </>
  )
}
