import { nanoid } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'

import { fetchTokenList } from '../state/lists/actions'
import { VersionedDeltaTokenList } from 'state/lists/reducer'
import { chainIdToNetworkName } from 'lib/hooks/useCurrencyLogoURIs'

const getListUrl = (name: string) => `https://raw.githubusercontent.com/1delta-DAO/one-delta-token-lists/main/lists/tokens-${name}.json`

function getUrl(chainId: number) {
  return getListUrl(chainIdToNetworkName(chainId))
}

export function useFetchListCallback(): (
  chain: number,
  sendDispatch?: boolean,
  skipValidation?: boolean
) => Promise<VersionedDeltaTokenList> {
  const dispatch = useAppDispatch()
  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (chain: number, sendDispatch = true) => {
      const requestId = nanoid()

      async function getDeltaTokenList() {
        const data = await fetch(getUrl(chain))
        return await data.json() as VersionedDeltaTokenList
      }

      sendDispatch && dispatch(fetchTokenList.pending({ requestId, chain: chain }))
      return getDeltaTokenList()
        .then((tokenList) => {
          sendDispatch && dispatch(fetchTokenList.fulfilled({ chain, tokenList, requestId }))
          return tokenList
        })
        .catch((error) => {
          console.debug(`Failed to get list at chain ${chain}`, error)
          sendDispatch && dispatch(fetchTokenList.rejected({ chain, requestId, errorMessage: error.message }))
          throw error
        })
    },
    [dispatch]
  )
}