import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { LENDLE_SUBGRAPH_URL } from './queries'
import { AggregatedDataDict, PnLData, aggreagateTxns, calculateFullAaveTypePnLFromTxns, dictToArray, mergeDicts, produceOriginalBalance } from '../utils'
import { SupportedChainId } from 'constants/chains'
import _ from "lodash"
import { fetchLendleLiquidations, fetchLendleTransactionHistory, getRefBalance } from './fetchLendleUserHistory'
import { AaveTypeTx } from '../utils/types'
import { fetchAllLendlePrices } from './fetchPrices'

interface ApiResponseData {
  pnl: PnLData;
  success: boolean;
  chainId: number;
  txns: AaveTypeTx[];
  refBalance: number;
}

interface TxQueryData {
  chainId: number;
  account?: string | undefined
  balancesLong: AggregatedDataDict
  balancesShort: AggregatedDataDict
  refTime: number
}

const EMPTY_DATA = { pnl: { pnlsLong: {}, pnlsShort: {} }, success: false, chainId: 0, txns: [], refBalance: 0 }

export const fetchLendleUserHistory: AsyncThunk<ApiResponseData, TxQueryData, any> = createAsyncThunk<
  ApiResponseData,
  TxQueryData
>('oracles/fetchLendleUserHistory', async ({ chainId, account, balancesLong, balancesShort, refTime }) => {
  const { histPrices } = await fetchAllLendlePrices(refTime)
  if (!chainId || !account || (chainId !== SupportedChainId.MANTLE)) return EMPTY_DATA
  const subgraphUrl = LENDLE_SUBGRAPH_URL
  const {
    success: successFetchHistory,
    txDictShorts,
    txDictLongs,
  } = await fetchLendleTransactionHistory({
    chainId,
    account,
    subgraphUrl,
    refTime
  })

  if (!successFetchHistory) return EMPTY_DATA

  const {
    success: successLiq,
    txDictShorts: liquidationShorts,
    txDictLongs: liquidationLongs,
  } = await fetchLendleLiquidations({
    chainId,
    account,
    first: 250,
    skip: 0,
    subgraphUrl,
    refTime
  })

  if (!successLiq) return EMPTY_DATA

  // merge liquidations
  const allLongs = mergeDicts(txDictLongs, liquidationLongs)
  const allShorts = mergeDicts(txDictShorts, liquidationShorts)

  const aggregatedLongDict = aggreagateTxns(allLongs)
  const aggregatedShortDict = aggreagateTxns(allShorts)

  const initialLongBalances = produceOriginalBalance(balancesLong, aggregatedLongDict, histPrices)
  const initialShortBalances = produceOriginalBalance(balancesShort, aggregatedShortDict, histPrices)

  const { pnl: pnlsLong, txDictEnriched: txnsLong } = calculateFullAaveTypePnLFromTxns(initialLongBalances, balancesLong, allLongs, true)
  const { pnl: pnlsShort, txDictEnriched: txnsShort } = calculateFullAaveTypePnLFromTxns(initialShortBalances, balancesShort, allShorts, false)

  return {
    pnl: { pnlsLong, pnlsShort },
    chainId,
    txns: dictToArray(mergeDicts(txnsLong, txnsShort)),
    success: true,
    refBalance: getRefBalance(initialLongBalances, initialShortBalances, txnsLong, txnsShort)
  }
})
