import { Image, ImageProps } from '@chakra-ui/react'
import { getLenderUri } from 'components/Table/MarginTable/SupportedLenders'
import React from 'react'
import { useChainId } from 'state/globalNetwork/hooks'
import { useIsLightMode } from 'state/user/hooks'
import { Lender } from 'types/lenderData/base'


interface LenderLogoProps extends ImageProps {
  protocol: Lender
}

export const LenderLogo: React.FC<LenderLogoProps> = ({ protocol, ...props }) => {
  const isLightMode = useIsLightMode()

  return (
    <Image
      src={getLenderUri(protocol, useChainId())}
      filter={isLightMode && protocol === Lender.LENDLE ? 'invert(1)' : undefined}
      {...props}
    />
  )
}
