import { getYear, isSameDay, isSameWeek, isSameYear } from 'date-fns'
import { useWeb3ReactWrapped } from "hooks/web3"
import { useCallback, useMemo } from "react"
import { useAppDispatch } from "state/hooks"
import { useAllTransactions } from "state/transactions/hooks"
import { clearAllTransactions } from "state/transactions/reducer"
import { TransactionDetailsV2 } from 'state/transactions/types'
import ms from 'ms.macro'

const THIRTY_DAYS = ms`30 days`


export interface TransactionInformation {
  title: string
  transactions: TransactionDetailsV2[]
}

const getConfirmedTransactions = (confirmedTransactions: Array<TransactionDetailsV2>) => {
  const now = new Date().getTime()

  const today: Array<TransactionDetailsV2> = []
  const currentWeek: Array<TransactionDetailsV2> = []
  const last30Days: Array<TransactionDetailsV2> = []
  const currentYear: Array<TransactionDetailsV2> = []
  const yearMap: { [key: string]: Array<TransactionDetailsV2> } = {}

  confirmedTransactions.forEach((transaction) => {
    const { addedTime } = transaction

    if (isSameDay(now, addedTime)) {
      today.push(transaction)
    } else if (isSameWeek(addedTime, now)) {
      currentWeek.push(transaction)
    } else if (now - addedTime < THIRTY_DAYS) {
      last30Days.push(transaction)
    } else if (isSameYear(addedTime, now)) {
      currentYear.push(transaction)
    } else {
      const year = getYear(addedTime)

      if (!yearMap[year]) {
        yearMap[year] = [transaction]
      } else {
        yearMap[year].push(transaction)
      }
    }
  })

  const transactionGroups: Array<TransactionInformation> = [
    {
      title: 'Today',
      transactions: today,
    },
    {
      title: 'This week',
      transactions: currentWeek,
    },
    {
      title: 'Past 30 Days',
      transactions: last30Days,
    },
    {
      title: 'This year',
      transactions: currentYear,
    },
  ]

  const sortedYears = Object.keys(yearMap)
    .sort((a, b) => parseInt(b) - parseInt(a))
    .map((year) => ({ title: year, transactions: yearMap[year] }))

  transactionGroups.push(...sortedYears)

  return transactionGroups.filter((transactionInformation) => transactionInformation.transactions.length > 0)
}


export const useTransactionHistoryV2 = () => {

  const allTransactions = useAllTransactions()
  const { chainId } = useWeb3ReactWrapped()
  const dispatch = useAppDispatch()
  const transactionGroupsInformation: any[] = []

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  const [confirmed, pending] = useMemo(() => {
    const confirmed: Array<TransactionDetailsV2> = []
    const pending: Array<TransactionDetailsV2> = []

    const sorted = Object.values(allTransactions).sort((a, b) => b.addedTime - a.addedTime)
    sorted.forEach((transaction) => (transaction.receipt ? confirmed.push(transaction) : pending.push(transaction)))

    return [confirmed, pending]
  }, [allTransactions])

  const confirmedTransactions = useMemo(() => getConfirmedTransactions(confirmed), [confirmed])

  if (pending.length) transactionGroupsInformation.push({ title: `Pending (${pending.length})`, transactions: pending })
  if (confirmedTransactions.length) transactionGroupsInformation.push(...confirmedTransactions)

  return {
    confirmedTransactions,
    pending,
    clearAllTransactionsCallback
  }
}