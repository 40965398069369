import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'
import { zeroAddress } from 'viem'

export const addressesMeridianCore: AddressDictionary = {
  AaveOracle: {
    [SupportedChainId.TAIKO]: '0xd79417be2cE02fc22F2E520869b1DfC02a677751',
  },
  PoolProxy: {
    [SupportedChainId.TAIKO]: '0x1697A950a67d9040464287b88fCa6cb5FbEC09BA', // marked as pool in docs
  },
  PoolDataProvider: {
    [SupportedChainId.TAIKO]: '0xef6881676d7fC7bd6E09927f833a9ACAAab0bA07',
  },
  IncentivesController: {
    [SupportedChainId.TAIKO]: '0xb3020EbFD800B488B64E92A8717E826cBf0d63E2'
  },
  MultiFeeDistribution: {
    [SupportedChainId.TAIKO]: '0xb0e103e2A80489Ba697D882EEd0Ba552389Ca31E'
  }
}

export const addressesMeridianATokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.TAIKO]: '0xa3f248A1779364FB8B6b59304395229ea8241229'
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.TAIKO]: '0x3807A7D65D82784E91Fb4eaD75044C7B4F03A462'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.TAIKO]: '0xB908808F52116380FFADCaebcab97A8cAD9409D2'
  },
  [SupportedAssets.TAIKO]: {
    [SupportedChainId.TAIKO]: '0xc2aB0FE37dB900ed7b7d3E0bc6a194cB78E33FB4'
  },
  [SupportedAssets.USDTE]: {
    [SupportedChainId.TAIKO]: '0x4361736820d9fE2A354225c7afDc246E5013135D'
  },
}

export const addressesMeridianVTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.TAIKO]: '0x22F48Ddbc34Fa22eda937496Fe702f2095D70a8e'
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.TAIKO]: '0xd37B96C82D4540610017126c042AFdde28578Afa'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.TAIKO]: '0x3Ef9b96D8a88Df1CAAB4A060e2904Fe26aE518Ce'
  },
  [SupportedAssets.TAIKO]: {
    [SupportedChainId.TAIKO]: '0xce0f8615380843EFa8CF6650a712c05e534A0e3F'
  },
  [SupportedAssets.USDTE]: {
    [SupportedChainId.TAIKO]: '0x6cD8F57977bB325359d0761b6B334D76697dA441'
  },
}

export const addressesMeridianSTokens: AddressDictionary = {
  [SupportedAssets.USDCE]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
  [SupportedAssets.TAIKO]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
  [SupportedAssets.USDTE]: {
    [SupportedChainId.TAIKO]: zeroAddress
  },
}
