import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { getAavePoolContract, getAavePoolDataProviderContract } from 'hooks/1delta/use1DeltaContract'
import { Call, multicallViem } from 'utils/multicall'
import AAVE_POOL_AND_DATA_PROVIDER_ABI from 'abis/aave/AAVEPoolAndDataProvider.json'
import { TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { getAaveV3TokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { getLenderAssets } from 'constants/getAssets'
import { Lender } from 'types/lenderData/base'

export enum AaveTypeGetUserReserveIndexes {
  currentATokenBalance = 0,
  currentStableDebt,
  currentVariableDebt,
  principalStableDebt,
  scaledVariableDebt,
  stableBorrowRate,
  liquidityRate,
  stableRateLastUpdated,
  usageAsCollateralEnabled,
}

interface AAVEPoolV3UserReserveResponse {
  chainId: number
  tokensData: {
    [tokenSymbol: string]: {
      // token amounts
      deposits: number
      debt: number
      debtStable: number
      // usd amounts
      depositsUSD: number
      debtUSD: number
      debtStableUSD: number
      collateralActive: boolean
    }
  }
  userEMode: number
  account: string
}

export interface AAVEPoolV3UserReservesQueryParams {
  chainId: number
  account: string
  prices: { [asset: string]: number }
}

export const fetchAaveV3UserData: AsyncThunk<
  AAVEPoolV3UserReserveResponse,
  AAVEPoolV3UserReservesQueryParams,
  any
> = createAsyncThunk<AAVEPoolV3UserReserveResponse, AAVEPoolV3UserReservesQueryParams>(
  'aave-v3/fetchAaveV3UserData',

  async ({ chainId, account, prices }) => {

    const assetsToQuery = getLenderAssets(chainId, Lender.AAVE_V3)

    const providerContract = getAavePoolDataProviderContract(chainId)
    const poolContract = getAavePoolContract(chainId)
    const aaveAddresses = getAaveV3TokenAddresses(chainId)
    const assets = assetsToQuery.map((a) => aaveAddresses[a])
    const names = assetsToQuery
    const calls: Call[] = assets.map((tk) => {
      return {
        address: providerContract.address,
        name: 'getUserReserveData',
        params: [tk, account],
      }

    })

    const callDataEmode = {
      address: poolContract.address,
      name: 'getUserEMode',
      params: [account],
    }

    const multicallResult: any[] = await multicallViem(chainId, AAVE_POOL_AND_DATA_PROVIDER_ABI,
      [...calls, callDataEmode],
      1 // provider Id is 1
    )
    const length = multicallResult.length
    const result: any = Object.assign(
      {},
      ...multicallResult.slice(0, length - 1).map((entry: any, index) => {
        const asset = names[index]
        const decimals = TOKEN_META[asset]?.decimals ?? 18
        const deposits = parseRawAmount(entry?.[AaveTypeGetUserReserveIndexes.currentATokenBalance].toString(), decimals)
        const debtStable = parseRawAmount(entry?.[AaveTypeGetUserReserveIndexes.currentStableDebt].toString(), decimals)
        const debt = parseRawAmount(entry?.[AaveTypeGetUserReserveIndexes.currentVariableDebt].toString(), decimals)
        const price = prices[asset]
        return {
          [asset]: {
            deposits,
            debtStable,
            debt,
            depositsUSD: deposits * price,
            debtStableUSD: debtStable * price,
            debtUSD: debt * price,
            collateralActive: Boolean(entry?.[AaveTypeGetUserReserveIndexes.usageAsCollateralEnabled]),
          },
        }
      })
    )
    const eMode = multicallResult[length - 1]

    return {
      chainId,
      tokensData: result,
      userEMode: Number(eMode),
      account
    }
  }
)