import { SupportedChainId } from "constants/chains";
import { RPC_URLS } from "constants/networks";
import { useEffect, useState } from "react";

export function getBlocksPerFetchForChainId(chainId: number | undefined): number {
  switch (chainId) {
    case SupportedChainId.ARBITRUM_ONE:
    case SupportedChainId.OPTIMISM:
    case SupportedChainId.BLAST:
      return 15
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
    case SupportedChainId.BASE:
      return 5
    case SupportedChainId.MANTLE:
    case SupportedChainId.LINEA:
    case SupportedChainId.TAIKO:
      return 30
    default:
      return 7
  }
}

export const useRandomInteger = (chainId: number) => {
  const [randomInteger, setRandomInteger] = useState(Math.floor(Math.random() * (RPC_URLS[chainId].length - 1)));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRandomInteger(Math.floor(Math.random() * (RPC_URLS[chainId].length - 1)));
    }, 10000); // updates every 10 seconds

    return () => {
      clearInterval(intervalId); // clean up interval on component unmount
    };
  }, [chainId]); // empty dependency array means this effect runs once on mount and clean up on unmount

  return randomInteger;
};
