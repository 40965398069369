import { createMultiStyleConfigHelpers, defineStyle, extendTheme, theme } from '@chakra-ui/react'
import { useThemeColors, validateColor } from "./colors/index";
import { switchAnatomy, avatarAnatomy, radioAnatomy, alertAnatomy, selectAnatomy } from '@chakra-ui/anatomy'
import { getTypography } from './typographies';
import { colorPathsLight } from './colors/colorPathsLight';
import { colorPathsDark } from './colors/colorPathsDark';

// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
export enum Z_INDEX {
  zero = 0,
  content = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

export enum ColorMode {
  DARK = 0,
  LIGHT = 1,
}

const {
  definePartsStyle: definePartsStyleSwitch,
  defineMultiStyleConfig: defineMultiStyleConfigSwitch
} =
  createMultiStyleConfigHelpers(switchAnatomy.keys)

const {
  definePartsStyle: definePartsStyleAvatar,
  defineMultiStyleConfig: defineMultiStyleConfigAvatar
} = createMultiStyleConfigHelpers(avatarAnatomy.keys)

const {
  definePartsStyle: definePartsStyleRadio,
  defineMultiStyleConfig: defineMultiStyleConfigRadio
} = createMultiStyleConfigHelpers(radioAnatomy.keys)

const {
  defineMultiStyleConfig: defineMultiStyleConfigAlert
} = createMultiStyleConfigHelpers(alertAnatomy.keys)

const {
  definePartsStyle: definePartsStyleSelect,
  defineMultiStyleConfig: defineMultiStyleConfigSelect
} = createMultiStyleConfigHelpers(selectAnatomy.keys)

export const radioContainerStyle = {
  borderRadius: "0.5rem",
  border: "1px solid",
  borderColor: validateColor("Borders/Border-secondary"),
  _hover: {
    bg: validateColor("Surface/Surface-secondary"),
  },
  _checked: {
    bg: validateColor("Surface/Surface-secondary"),
    border: "1px solid",
    borderColor: validateColor("Borders/Border-tertiary"),
  },
  transition: "all 0.2s ease-in-out",
  flexDir: "row-reverse",
  justifyContent: "space-between",
  padding: "0.5rem 0.5rem 0.5rem 0.75rem",
  width: "100%",
  ...getTypography("Typography/Body-Labels/Normal/Body"),
  color: validateColor("Text/Lables/Label-text-default"),
}

const useChakraThemes: () => Record<ColorMode, any> = () => {

  // Define the base style for the Switch component

  const baseStyleSwitch = definePartsStyleSwitch({
    thumb: {
      bg: validateColor("Text/Lables/Label-text-default"),
      _checked: {
        bg: validateColor("Button/Button Primary/Button-primary-default"),
      },
    },
    track: {
      bg: 'transparent',
      border: "1px solid",
      borderColor: validateColor("Borders/Border-tertiary"),
      _focusVisible: {
        boxShadow: "none",
        outline: "none",
      },
    },
  })

  const switchTheme = defineMultiStyleConfigSwitch({ baseStyle: baseStyleSwitch })

  // Define the base style for the Avatar component

  const tokenLogo = defineStyle({
    width: "29.333px",
    height: "29.333px",
    fontSize: "10px",
    backgroundColor: validateColor("Surface/Surface-secondary"),
    color: validateColor("Text/Lables/Label-text-default"),
  })

  const sizes = {
    tokenLogo: definePartsStyleAvatar({ container: tokenLogo }),
  }

  const avatarTheme = defineMultiStyleConfigAvatar({ sizes })

  const [bg] = useThemeColors(["Surface/Surface-primary"])

  const baseStyleRadio = definePartsStyleRadio({
    control: {
      borderColor: validateColor("Borders/Border-tertiary") + " !important",
      bg: "transparent",
      borderWidth: "2px",
      color: "transparent",
      _hover: {
        bg: "transparent",
      },
      _checked: {
        borderColor: validateColor("Button/Button Primary/Button-primary-default") + " !important",
        bg: validateColor("Button/Button Primary/Button-primary-default"),
        color: "transparent",
        _hover: {
          bg: validateColor("Button/Button Primary/Button-primary-default"),
        },
        boxShadow: "inset 0px 0px 0px 3px " + bg,
      },
      _focusVisible: {
        boxShadow: "none",
      },
      padding: "0.5rem",
    },
    container: radioContainerStyle,
    label: {
      ...getTypography("Typography/Body-Labels/Normal/Body"),
      marginInlineStart: 0,
    },
  })

  const radioTheme = defineMultiStyleConfigRadio({
    baseStyle: baseStyleRadio,
    variants: {
      withoutBorder: {
        ...baseStyleRadio,
        container: {
          ...baseStyleRadio.container,
          border: "none",
        },
      }
    }
  })

  // Define the base style for the Alert component

  const alertTheme = defineMultiStyleConfigAlert({
    variants: {
      solid: (props) => {
        if (props.colorScheme === "red") {
          return {
            container: {
              bg: validateColor("Text/Lables/Lable-error"),
            }
          }
        } else if (props.colorScheme === "green") {
          return {
            container: {
              bg: validateColor("Button/Button Primary/Button-primary-default"),
              color: validateColor("Button/Button Primary/Button-primary-text-default")
            },
            icon: {
              color: validateColor("Button/Button Primary/Button-primary-text-default")
            }
          }
        } else {
          return theme.components.Alert.variants?.solid(props) || {}
        }
      }
    }
  })

  const baseStyleSelect = definePartsStyleSelect({
    field: {
      padding: "0.5rem",
    },
  })

  const selectTheme = defineMultiStyleConfigSelect({ baseStyle: baseStyleSelect })

  const baseTheme = extendTheme({
    styles: {
      global: () => ({
        body: {
          bg: "",
          color: "",
        },
      }),
    },
    components: {
      Switch: switchTheme,
      Avatar: avatarTheme,
      Radio: radioTheme,
      Alert: alertTheme,
      Select: selectTheme,
    },
    shadows: {
      dark: "0px 5px 24px 1px rgb(0, 0, 0, 0.64)"
    }
  });

  const lightTheme = extendTheme({
    colors: colorPathsLight,
    shadows: {
      dark: "0px 5px 24px 1px rgb(0, 0, 0, 0.34)"
    }
  }, baseTheme)

  const darkTheme = extendTheme({ colors: colorPathsDark }, baseTheme)

  return {
    [ColorMode.LIGHT]: lightTheme,
    [ColorMode.DARK]: darkTheme,
  }
}

export default useChakraThemes