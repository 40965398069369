import { TEN } from "constants/1delta";
import { SupportedChainId } from "constants/chains";
import { ethers } from "ethers";
import { formatEther, parseUnits } from "ethers/lib/utils";
import _ from "lodash";

/**
 * Parses a price string to number
 * Aave has 8 decimal prices whereas Lendle uses 18 decimals
 * @param price price as serialized BigNumber
 * @param chainId chainId 
 * @returns price as number
 */
export const formatAavePrice = (price: string, chainId = SupportedChainId.POLYGON): number => {
  return Number(formatEther(ethers.BigNumber.from(price ?? '0').mul(
    // 10^(18 - decimals)
    TEN.pow(chainId === SupportedChainId.MANTLE ? 0 : 10)
  )))
}

export const parseRawAmount = (answer?: string, decimals?: number): number => {
  if (!answer) return 0
  return Number(formatEther(ethers.BigNumber.from(answer ?? '0').mul(TEN.pow(18 - (decimals ?? 18)))))
}

export const parseNumberToRaw = (n: number | string, decs = 18) => {
  return parseUnits(String(n), decs)
}

export const skimToBigNumber = (num: string | number, decs = 18) => {
  return parseUnits(String(_.round(Number(num), decs)?.toFixed(decs)), decs).toString()
}

export const exoticAnswerToPrice = (answer?: string, decimals?: number, answer2?: string, decimals2?: number): number => {
  return Number(formatEther(ethers.BigNumber.from(answer ?? '0').mul(TEN.pow(18 - (decimals ?? 18))))) *
    Number(formatEther(ethers.BigNumber.from(answer2 ?? '0').mul(TEN.pow(18 - (decimals2 ?? 18)))))
}

