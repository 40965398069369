import { Menu, MenuList } from '@chakra-ui/menu'
import { validateColor } from 'theme/colors'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { TertiarySmallButton } from 'components/Button/Tertiary'
import { getTypography } from 'theme/typographies'
import { useState } from 'react'
import React from 'react'
import { UpdatesCard } from './UpdatesCard'
import { MenuIconButton } from 'components/Button/Action/MenuIconButton'
import { Transactions } from './Transactions'
import { useTransactionHistoryV2 } from './hooks/useTransactionHistory'
import { useTransactionCount } from 'state/transactions/hooks'
import { ColorMode } from 'theme'
import { notificationBell } from 'constants/1delta'

const DEFAULT_UPDATES = [
  {
    title: 'Announcement',
    subTitle: '1delta dApp v2 Launch coming soon!',
    content:
      'New 1delta dApp  V2 launching soon with a complete user interface overhaul and more refined user experience',
  },
]

interface NotificationTabProps {
  text: string
  count: number
  isSelected: boolean
  onClick: () => void
}

const NotificationTab: React.FC<NotificationTabProps> = ({ text, count, isSelected, onClick }) => {
  return (
    <HStack
      onClick={onClick}
      cursor={isSelected ? 'default' : 'pointer'}
      borderBottom={'2px solid'}
      borderColor={isSelected ? validateColor('Button/Button Primary/Button-primary-default') : 'transparent'}
      padding={'0.5rem'}
      gap={'0.25rem'}
      alignItems={'center'}
      _hover={
        !isSelected
          ? {
              borderColor: validateColor('Button/Button Primary/Button-primary-default'),
            }
          : {}
      }
      transition={'all 0.2s ease-in-out'}
    >
      <Text
        color={
          isSelected
            ? validateColor('Navigation/Navigation-text-active')
            : validateColor('Navigation/Navigation-text-inactive')
        }
        style={getTypography('Typography/Body-Labels/Normal/Body')}
      >
        {text}
      </Text>
      <Flex
        p={'0.0625rem 0.375rem'}
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={'0.25rem'}
        bg={validateColor('Surface/Surface-tertiary')}
        w="24px"
        h="24px"
      >
        <Text
          style={getTypography('Typography/Small/Bold/Small 2')}
          color={validateColor('Text/Lables/Label-text-default')}
        >
          {count}
        </Text>
      </Flex>
    </HStack>
  )
}

enum NotificationTabs {
  TRANSACTIONS = 'transactions',
  ALERTS = 'alerts',
}

interface NotificationsProps {
  theme: ColorMode
}

export const Notifications: React.FC<NotificationsProps> = ({ theme }) => {
  const [selectedTab, setSelectedTab] = useState<NotificationTabs>(NotificationTabs.TRANSACTIONS)

  const { pending, confirmedTransactions, clearAllTransactionsCallback } = useTransactionHistoryV2()
  const txCount = useTransactionCount()
  // const notificationsCount = txCount + DEFAULT_UPDATES.length
  const notificationsCount = txCount
  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuIconButton
            iconSrc={notificationBell[theme]}
            isOpen={isOpen}
            withBadge={true}
            badgeValue={notificationsCount > 0 ? notificationsCount : undefined}
          />
          <MenuList
            minWidth="375px"
            padding="1rem"
            border="none"
            borderRadius={'0.5rem'}
            bg={validateColor('Surface/Surface-primary')}
            boxShadow="dark"
            display={'flex'}
            flexDirection={'column'}
            gap={'1.5rem'}
            alignItems={'flex-start'}
            overflow="hidden"
          >
            <HStack justifyContent={'space-between'} w="100%">
              <Text
                color={validateColor('Text/Headings & Titles/Title-text')}
                style={getTypography('Typography/Captions/Normal/Caption 2')}
              >
                Notifications
              </Text>
              <TertiarySmallButton onClick={clearAllTransactionsCallback}>Clear all</TertiarySmallButton>
            </HStack>

            <VStack w="100%" alignItems={'flex-start'} gap="1.5rem">
              <HStack w="100%" gap="1.5rem">
                <NotificationTab
                  text={'Transactions'}
                  count={txCount}
                  isSelected={selectedTab === NotificationTabs.TRANSACTIONS}
                  onClick={() => setSelectedTab(NotificationTabs.TRANSACTIONS)}
                />
                {/* <NotificationTab
                  text={"Alerts"}
                  count={DEAULT_UPDATES.length}
                  isSelected={selectedTab === NotificationTabs.ALERTS}
                  onClick={() => setSelectedTab(NotificationTabs.ALERTS)}
                /> */}
              </HStack>

              {selectedTab === NotificationTabs.TRANSACTIONS ? (
                <Transactions pending={pending} confirmedTransactions={confirmedTransactions} />
              ) : (
                <>
                  <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
                    <Text
                      style={getTypography('Typography/Body-Labels/Normal/Body')}
                      color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
                    >
                      Updates
                    </Text>
                    <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
                      {DEFAULT_UPDATES.map((upd) => {
                        return <UpdatesCard {...upd} />
                      })}
                    </VStack>
                  </VStack>
                </>
              )}
            </VStack>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
