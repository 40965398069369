import { createReducer } from "@reduxjs/toolkit";
import { PositionType } from "constants/1delta";
import { SupportedAssets } from "types/1delta";
import { Lender } from "types/lenderData/base";
import { addLoadingPositionInState, removeAllLoadingPositions, replaceLoadingState } from "./actions";

export interface LoadingPosition {
  asset: SupportedAssets,
  lender: Lender,
  chainId: number,
  type: PositionType,
}

export interface LoadingState {
  loadingPositions: LoadingPosition[]
}

const initialState: LoadingState = {
  loadingPositions: []
}

export default createReducer<LoadingState>(initialState, (builder) => builder
  .addCase(
    replaceLoadingState,
    (_, { payload: { loadingPositions } }) => {
      return {
        loadingPositions
      }
    }
  )
  .addCase(
    addLoadingPositionInState,
    (state, { payload: { loadingPosition } }) => {
      return {
        loadingPositions: [...state.loadingPositions, loadingPosition]
      }
    }
  )
  .addCase(
    removeAllLoadingPositions,
    (state, { }) => {
      return {
        loadingPositions: []
      }
    }
  )
)