import { Tr, Text, HStack, Spinner } from '@chakra-ui/react'
import { CustomTable, TableHeaderCell, TableHeaderCellProps } from 'components/Table'
import { useFetchAndSortWalletData } from 'hooks/asset/useWalletAssetData'
import { useIsMobile } from 'hooks/useIsMobile'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { WalletMenuAssetsRow } from './WalletMenuAssetsRow'
import { NATIVE_ASSET } from 'types/1delta'
import { usePrices } from 'state/oracles/hooks'
import { getAvailableChainIds } from 'constants/chains'

const HeaderCell: React.FC<TableHeaderCellProps> = (props) => {
  return (
    <TableHeaderCell
      style={getTypography('Typography/Small/Normal/Small 2')}
      bg={validateColor('Surface/Surface-primary')}
      lineHeight={'1 !important'}
      w="33.33%"
      height="35px !important"
      {...props}
    />
  )
}

interface WalletTableMenuProps {
  account: string
  chainId: number
}

export const WalletTableMenu: React.FC<WalletTableMenuProps> = ({ account, chainId }) => {
  const isMobile = useIsMobile()

  const { sortedData, requestSort, dataIsLoading, dexscreenerPairs } = useFetchAndSortWalletData({
    chainId,
    account,
  })

  const [nativePrice] = usePrices([NATIVE_ASSET[chainId]])
  const isSupported = getAvailableChainIds().includes(chainId)
  return !isSupported ? (
    /////////////////////////////////////
    // network not supported
    /////////////////////////////////////
    <HStack p="1rem">
      <Text
        style={getTypography('Typography/Body-Labels/Bold/Body-Label')}
        color={validateColor('Text/Headings & Titles/Title-text')}
      >
        Network not yet supported
      </Text>
    </HStack>
  ) : sortedData.length === 0 && dataIsLoading ? (
    /////////////////////////////////////
    // Data is loading
    /////////////////////////////////////
    <HStack p="1rem">
      <Text
        style={getTypography('Typography/Body-Labels/Bold/Body-Label')}
        color={validateColor('Text/Headings & Titles/Title-text')}
      >
        Loading
      </Text>
      <Spinner size={'sm'} color={validateColor('Text/Lables/Lable-success')} thickness="3px" />
    </HStack>
  ) : sortedData.length === 0 && !dataIsLoading ? (
    /////////////////////////////////////
    // Not loadig anymore but asset length
    // is zero
    /////////////////////////////////////
    <HStack p="1rem">
      <Text
        style={getTypography('Typography/Body-Labels/Bold/Body-Label')}
        color={validateColor('Text/Headings & Titles/Title-text')}
      >
        No assets found
      </Text>
    </HStack>
  ) : (
    /////////////////////////////////////
    // Show content
    /////////////////////////////////////
    <>
      {sortedData.map((assetData) => (
        <WalletMenuAssetsRow
          key={assetData.assetId}
          assetData={assetData}
          isMobile={isMobile}
          price={
            (assetData.assetId === NATIVE_ASSET[chainId]
              ? nativePrice
              : dexscreenerPairs?.[assetData.assetId]?.priceUsd) ??
            assetData.price ??
            0
          }
        />
      ))}
    </>
  )
}
