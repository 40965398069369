import { CSSProperties } from "react"
import { typographyPaths } from "./typographyPaths"
import { typographyPathsKeys } from "./typographyPathsKeys"

const defaultTypography: CSSProperties = {
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "1.375rem"
}

/**
 * 
 * @param path The typography Path directly taken from Figma (e.g., "Typography/Headings/Normal/Heading 1")
 * @returns The selected typography css code or the default typography if the path is incorrect
 */
export const getTypography = (path: keyof typographyPathsKeys): CSSProperties => {
  try {
    return typographyPaths[path]
  } catch (error) {
    console.log("typography error on path:", path)
    return defaultTypography
  }
}