import { getAvailableChainIds } from 'constants/chains'
import useInterval from 'lib/hooks/useInterval'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'
import { useAllLists } from 'state/lists/hooks'

import { useFetchListCallback } from '../../hooks/useFetchListCallback'
import { useChainId } from 'state/globalNetwork/hooks'
import { getProviderByIndex } from 'constants/providers'
import useIsWindowVisible from 'hooks/useIsWindowVisible'

export default function Updater(): null {

  const chainId = useChainId()
  const provider = getProviderByIndex(chainId, 2)

  const dispatch = useAppDispatch()
  const isWindowVisible = useIsWindowVisible()

  // get all loaded lists, and the active urls
  const lists = useAllLists()
  const fetchList = useFetchListCallback()
  const fetchAllListsCallback = useCallback(() => {
    if (!isWindowVisible) return
    getAvailableChainIds().forEach((chain) =>
      fetchList(chain).catch((error) => console.debug('interval list fetching error', error))
    )
  }, [fetchList, isWindowVisible, lists])

  // fetch all lists every 10 minutes, but only after we initialize provider
  useInterval(fetchAllListsCallback, provider ? 1000 * 60 * 10 : null)

  // whenever a list is not loaded and not loading, try again to load it
  useEffect(() => {
    getAvailableChainIds().forEach((chain) => {
      const list = lists?.[chain]
      if (!list || (!list.currentList && !list.loadingRequestId && !list.error)) {
        fetchList(chain).catch((error) => console.debug('list added fetching error', error))
      }
    })
  }, [dispatch, fetchList, lists])

  return null
}
