import { ChainTokenMap } from 'lib/hooks/useTokenList/utils'
import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { AppState } from '../index'
import { Token } from '@1delta/base-sdk'
import { TypedTokenList } from './reducer'

export type TokenAddressMap = ChainTokenMap

export function useAllLists(): AppState['lists']['byChain'] {
  return useAppSelector((state) => state.lists.byChain)
}

export function useDeltaTokenList(chainId: number): TypedTokenList {
  const rawList = useAppSelector((state) => state.lists?.byChain?.[chainId]?.currentList)
  const addressesToSeek = rawList?.mainTokens ?? []
  return useMemo(() => {
    if (!rawList || !rawList?.list) return {}
    let list: TypedTokenList = {}
    addressesToSeek.forEach(addr => {
      const lowAddr = addr.toLowerCase()
      const entr = rawList.list[lowAddr]
      if (entr && entr.chainId === chainId) {
        list[lowAddr] = new Token(chainId, entr.address as any, Number(entr.decimals ?? 0), entr.symbol, entr.name)
      }
    });
    return list
  }, [rawList])
}