import { createReducer } from '@reduxjs/toolkit'
import { DEFAULT_CHAINID, STATE_CHAINIDS } from 'constants/chains'
import { setChainId, setAccount, setIsSupported, setBlockNumber, setIsLoading, setImpersonatedAccount, setUseImpersonatedAccount, setSubscribeConfig } from './actions'
import { fetchBlockDataAndBalances, GasData, SubscribedData } from './fetchGeneralData'
import { getAllLenderTokenAddresses } from 'hooks/lenders/lenderAddressGetter'

export interface SubscribeConfig {
  approvalTargets?: string[]
  tokens?: string[]
}

export interface GlobalNetworkState {
  readonly chainId: number
  readonly account: string | undefined
  readonly impersontedAccount: string | undefined
  readonly useImpersonatedAccount: boolean
  readonly connectionIsSupported: boolean
  readonly loading: boolean
  networkData: {
    [chainId: number]: {
      readonly lastTimestamp: string
      readonly blockNumber: number
      readonly nativeBalance: string | undefined
      readonly balances: { [supportedAssetSymbol: string]: string }
      readonly subscribedData: { [assetAddress: string]: SubscribedData }
      subscribeConfig: SubscribeConfig
      gasData: GasData
    }
  }
}

const initialChainId = Number(DEFAULT_CHAINID)

const initialData = {
  lastTimestamp: String(Math.round(Date.now() / 1000)),
  blockNumber: 0,
  nativeBalance: undefined
}

const initialState: GlobalNetworkState = {
  chainId: initialChainId,
  account: undefined,
  impersontedAccount: undefined,
  useImpersonatedAccount: false,
  connectionIsSupported: true,
  loading: false,
  networkData: Object.assign({}, ...STATE_CHAINIDS.map(chainId => {
    return {
      [chainId]: {
        ...initialData,
        balances: Object.assign({},
          ...Object.keys(getAllLenderTokenAddresses(chainId)).map((x) => {
            return { [x]: '0' }
          })),
        subscribedData: {},
        subscribeConfig: {},
        gasData: {}
      }
    }
  }
  )
  )
}

export default createReducer<GlobalNetworkState>(initialState, (builder) =>
  builder
    .addCase(setChainId, (state, { payload: { chainId } }) => {
      state.chainId = chainId
    })
    .addCase(setImpersonatedAccount, (state, { payload: { account } }) => {
      state.impersontedAccount = account
    })
    .addCase(setUseImpersonatedAccount, (state, { payload: { isUsed } }) => {
      state.useImpersonatedAccount = isUsed
    })
    .addCase(setIsLoading, (state, { payload: { loading } }) => {
      state.loading = loading
    })
    .addCase(setSubscribeConfig, (state, { payload: { chainId, tokens, approvalTargets } }) => {
      // add entry if it does not exist
      if (!state.networkData[chainId]) state.networkData[chainId] = {
        subscribeConfig: {},
        subscribedData: {},
        balances: {},
        gasData: {},
        ...initialData
      }

      state.networkData[chainId].subscribeConfig = {
        ...state.networkData[chainId]?.subscribeConfig,
        tokens,
        approvalTargets
      }
    })
    .addCase(setAccount, (state, { payload: { account } }) => {
      state.account = account
    })
    .addCase(setBlockNumber, (state, { payload: { blockNumber, chainId } }) => {
      state.networkData[chainId].blockNumber = blockNumber
    })
    .addCase(setIsSupported, (state, { payload: { isSupported } }) => {
      state.connectionIsSupported = isSupported
    })
    .addCase(fetchBlockDataAndBalances.fulfilled, (state, action) => {
      const { chainId } = action.payload
      state.networkData[chainId].lastTimestamp = action.payload.timestamp
      state.networkData[chainId].blockNumber = action.payload.blockNumber
      state.networkData[chainId].nativeBalance = action.payload.nativeBalance
      state.networkData[chainId].gasData = action.payload.gasData


      const assetKeys = Object.keys(action.payload.balances)
      for (let i = 0; i < assetKeys.length; i++)
        state.networkData[chainId].balances[assetKeys[i]] = action.payload.balances[assetKeys[i]]
      const subscribedKeys = Object.keys(action.payload.subscribedData)
      for (let i = 0; i < subscribedKeys.length; i++)
        state.networkData[chainId].subscribedData[subscribedKeys[i]] = action.payload.subscribedData[subscribedKeys[i]]
    })
)
