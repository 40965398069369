import { createAction } from '@reduxjs/toolkit'
import { PnLData } from './utils'
import { Lender } from 'types/lenderData/base'

export const resetState = createAction<void>('oracles/resetState')

export const setOraclesToLoading = createAction('oracles/setOraclesToLoading')

export const setHistPrices = createAction<{ prices: { [k: string]: number } }>('oracles/setHistPrices')

export const setPnL = createAction<{ pnl: PnLData, refBalance: number, lender: Lender, chainId: number, account: string }>('oracles/setPnL')

export const resetPnLs = createAction('oracles/resetPnLs')