import { useInterfaceMulticallInfo } from 'hooks/useContract'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { useMemo } from 'react'
import { combineReducers, createStore } from 'redux'
import { useChainId } from 'state/globalNetwork/hooks'
import { getBlocksPerFetchForChainId } from './utils'
import { createMulticall, ListenerOptions } from '@1delta/redux-multicall-viem'

const multicall = createMulticall()
const reducer = combineReducers({ [multicall.reducerPath]: multicall.reducer })
export const store = createStore(reducer)

export default multicall

export function MulticallUpdater() {
  const chainId = useChainId()
  const latestBlockNumber = useBlockNumber()
  const multicallInfo = useInterfaceMulticallInfo(4, chainId)

  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId]
  )

  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      multicallInfo={multicallInfo}
      listenerOptions={listenerOptions}
    />
  )
}
