import { ButtonProps } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { LargeButton, MediumButton, SmallButton } from '../common'
import { getTypography } from 'theme/typographies'

const TertiaryButtonProps: ButtonProps = {
  backgroundColor: 'transparent',
  padding: '0.5rem',
  color: validateColor('Button/Button Tertiary/Button-tertiary-text-default'),
  _hover: {
    backgroundColor: 'transparent',
    color: validateColor('Button/Button Tertiary/Button-tertiary-text-hover'),
  },
  _active: {
    backgroundColor: 'transparent',
    color: validateColor('Button/Button Tertiary/Button-tertiary-text-active'),
  },
}

export const TertiaryLargeButton: React.FC<ButtonProps> = (props) => {
  return (
    <LargeButton
      {...TertiaryButtonProps}
      {...props}
      style={getTypography('Typography/Small/Underline/Small 1 Underline')}
    />
  )
}

export const TertiaryMediumButton: React.FC<ButtonProps> = (props) => {
  return (
    <MediumButton
      {...TertiaryButtonProps}
      {...props}
      style={getTypography('Typography/Small/Underline/Small 2 Underline')}
    />
  )
}

export const TertiarySmallButton: React.FC<ButtonProps> = (props) => {
  return (
    <SmallButton
      {...TertiaryButtonProps}
      {...props}
      style={getTypography('Typography/Small/Underline/Small 3 Underline')}
    />
  )
}
